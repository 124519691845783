/** @jsxImportSource theme-ui */
import {useEffect} from 'react';

import {Icon} from '@iconify/react';
import {
  Organization,
  Project,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {PolarSignalsFull} from '@polarsignals/icons';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useLocalStorageState from 'use-local-storage-state';

import {FreeTrialStatus} from 'components/FreeTrialStatus';
import useActiveProject from 'hooks/data/useActiveProject';
import useCurrentUser, {isAdminUser} from 'hooks/data/useCurrentUser';
import useProjects from 'hooks/data/useProjects';
import useViews from 'hooks/data/useViews';
import {hasNoProjects} from 'utils/projects';

import {ICONS} from '../../constants';
import useOrganizations from '../../hooks/data/useOrganizations';

import ColorModeToggle from './ColorModeToggle';
import HeadwayWidget from './HeadwayWidget';
import HelpCenter from './HelpCenter';
import NavbarActions from './NavbarActions';
import OrganizationSubMenu from './OrganizationSubMenu';
import ProfileInitials from './ProfileInitials';
import ProjectSubMenu from './ProjectSubMenu';
import Selector from './Selector';

const isValidProject = (projectId: string, organizations: Organization[] | undefined = []) => {
  const project = organizations.find(org => org.projects.find(p => p.id === projectId));
  return !!project;
};

const findValidProject = (organizations: Organization[] = []) => {
  for (const org of organizations) {
    for (const project of org.projects) {
      if (project.id != null) {
        return project;
      }
    }
  }
};

const findOrganizationWithProject = (organizations: Organization[] = [], projectId: string) => {
  for (const org of organizations) {
    for (const project of org.projects) {
      if (project.id === projectId) {
        return org;
      }
    }
  }
};

export const findOrganizationWithOrganizationId = (
  organization: Organization[] = [],
  organizationId: string
) => {
  for (const org of organization) {
    if (org.id === organizationId) {
      return org;
    }
  }
};

const Navbar = () => {
  const [lastUsedProjectID, setLastUsedprojectID] = useLocalStorageState('lastUsedProjectId', {
    defaultValue: '',
  });
  const router = useRouter();
  const projectIDFromUrl = router.query['project-id'];
  const projectIDFromViewURL = router.query.projectId;
  const organizationIDFromUrl = router.query.organizationId;
  const isViewsPage = router.pathname === '/projects/[project-id]/views/[view-slug]';
  const {
    data: {organizations},
  } = useProjects();

  const {
    data: {activeProject},
    mutations: {setActiveProject},
  } = useActiveProject();

  const {
    data: {views, activeView},
  } = useViews();

  const {
    data: {user},
  } = useCurrentUser();

  const isAdmin = isAdminUser(user);

  const selectProject = (
    project: Project,
    organization: Organization,
    initiatedByEffect?: boolean
  ) => {
    setActiveProject(organization.id, project.id);
    setLastUsedprojectID(project.id);

    if (!initiatedByEffect) {
      router.push(`/projects/${project.id}`);
    }
  };

  const userIsOnOrgPage =
    router.pathname === '/organizations/[organizationId]' ||
    router.pathname === '/organizations/[organizationId]/settings';

  useEffect(() => {
    if (userIsOnOrgPage) {
      return;
    }

    if (projectIDFromUrl && isValidProject(projectIDFromUrl as string, organizations)) {
      setActiveProject(
        findOrganizationWithProject(organizations, projectIDFromUrl as string)?.id ?? '',
        projectIDFromUrl as string
      );
      return;
    }

    if (activeProject?.id != null && isValidProject(activeProject.id, organizations)) {
      return;
    }

    let projectIDToSelect: string = lastUsedProjectID;

    if (projectIDToSelect == null || !isValidProject(projectIDToSelect, organizations)) {
      projectIDToSelect = findValidProject(organizations)?.id ?? '';
    }

    if (!projectIDToSelect) {
      return;
    }

    const currentOrg = findOrganizationWithProject(organizations, projectIDToSelect);
    const projectToSelect = currentOrg?.projects.find(p => p.id === projectIDToSelect);

    if (!projectToSelect) {
      return;
    }

    selectProject(projectToSelect, currentOrg as Organization, true);
  }, [
    activeProject,
    organizations,
    selectProject,
    lastUsedProjectID,
    organizationIDFromUrl,
    projectIDFromUrl,
    projectIDFromViewURL,
    isViewsPage,
    userIsOnOrgPage,
  ]);

  const currentOrg =
    findOrganizationWithProject(organizations, activeProject?.id ?? '') ??
    findOrganizationWithOrganizationId(organizations, organizationIDFromUrl as string);

  const {data: organization} = useOrganizations(currentOrg?.id);
  const enableLogs = organization.organization?.featureFlags.includes('logs') ?? false;

  return (
    <div sx={{borderBottom: '1px solid', borderColor: 'border'}}>
      <div className="grid-cols-navbar grid items-center gap-4 p-4">
        <div className="flex justify-center">
          <Link href="/">
            <PolarSignalsFull width={200} />
          </Link>
        </div>

        <div className="navbar-dropdown-container flex items-center gap-2">
          {hasNoProjects(organizations) ? null : (
            <div className="flex w-auto items-center gap-2">
              <Selector
                organizations={organizations}
                currentOrg={currentOrg}
                activeProject={activeProject}
                selectProject={selectProject}
                views={views}
                activeView={activeView}
              />
            </div>
          )}
          <FreeTrialStatus organizationId={activeProject?.organizationId ?? ''} isNavbar={true} />
        </div>

        <div className="flex items-center justify-end gap-2">
          <HelpCenter
            productToursCompleted={user?.productTours}
            activeProjectId={activeProject?.id as string}
          />
          <ColorModeToggle />
          {isAdmin && (
            <Link href="/admin" className="p-1">
              <Icon icon={ICONS.admin} width={24} height={24} />
            </Link>
          )}
          <NavbarActions />
          <HeadwayWidget />
          <ProfileInitials />
        </div>
      </div>
      {router.pathname.startsWith('/organizations') && <OrganizationSubMenu />}
      {router.pathname.startsWith('/project') && (
        <ProjectSubMenu projectId={activeProject?.id as string} enableLogs={enableLogs} />
      )}
    </div>
  );
};

export default Navbar;
