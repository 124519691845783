import {useContext} from 'react';

import {User} from '@polarsignals/client-grpc-web/polarsignals/user/v1alpha1/user';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';

import GrpcContext from 'contexts/GrpcContext';

interface UserUpdateDetails {
  name: string;
  email: string;
  company: string;
  timezone: string;
}

export const isAdminUser = (user?: User | null): boolean => {
  if (user == null) {
    return false;
  }
  return user.featureFlags.includes('feature-flag');
};

export const isAIEnabledUser = (user?: User | null): boolean => {
  if (user == null) {
    return false;
  }
  return user.featureFlags.includes('ai-features');
};

const useCurrentUser = () => {
  const {userServiceClient} = useContext(GrpcContext);

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<User | null>({
    key: ['getSelf'],
    queryFn: async () => {
      try {
        const {response} = await userServiceClient.getSelf({});

        let user = response.user ?? null;

        if (user?.timezone === '') {
          // @ts-expect-error
          user.timezone = undefined;
        }

        return user;
      } catch (err: any) {
        if (err.code === 'UNAUTHENTICATED') {
          // Error code 16 (UNAUTHENTICATED) means no active user in session, so this is not an error.
          return null;
        }
        throw new Error(`${err.message}, code: ${err.code}`);
      }
    },
  });

  const updateUser = ({name, email, company, timezone}: UserUpdateDetails) => {
    const call = userServiceClient.updateSelf({
      user: {
        id: user?.id ?? '',
        company: company,
        email: email,
        name: name,
        timezone: timezone,
        featureFlags: [], // Doesn't have any effect as only name and company are updated.
        authProvider: '', // Doesn't have any effect as only name and company are updated.
        productTours: [], // Doesn't have any effect as only name and company are updated.
        roles: [], // Doesn't have any effect as only name and company are updated.
      },
    });
    call.response
      .then(() => {
        Toast('success', 'User details updated successfully');
        refetch();
      })
      .catch(err => {
        console.error(err);
      });
  };

  const updateUserProductTours = (tourName: string) => {
    const call = userServiceClient.updateUserProductTours({
      userId: user?.id ?? '',
      tourName,
    });
    call.response
      .then(() => {
        refetch();
      })
      .catch(err => {
        console.error(err);
      });
  };

  return {
    data: {user},
    loading: isLoading,
    error,
    refetch,
    mutations: {updateUser, updateUserProductTours},
  };
};

export default useCurrentUser;
