// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "glacier/query/v1alpha1/query.proto" (package "glacier.query.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../../../google/protobuf/duration";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message glacier.query.v1alpha1.QueryRangeRequest
 */
export interface QueryRangeRequest {
    /**
     * query is the query string to match profiles against
     *
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * start is the start of the query time window
     *
     * @generated from protobuf field: google.protobuf.Timestamp start = 2;
     */
    start?: Timestamp;
    /**
     * end is the end of the query time window
     *
     * @generated from protobuf field: google.protobuf.Timestamp end = 3;
     */
    end?: Timestamp;
    /**
     * filter specifies the filter to apply to the query
     * TODO: This needs to be added to the query string for more flexibility.
     *
     * @generated from protobuf field: repeated glacier.query.v1alpha1.Filter filters = 4;
     */
    filters: Filter[];
    /**
     * step is the step size to use for the query
     *
     * @generated from protobuf field: google.protobuf.Duration step = 5;
     */
    step?: Duration;
}
/**
 * @generated from protobuf message glacier.query.v1alpha1.Filter
 */
export interface Filter {
    /**
     * operator is the operator to use for the filter
     *
     * @generated from protobuf field: string operator = 1;
     */
    operator: string;
    /**
     * value is the value to filter against
     *
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message glacier.query.v1alpha1.QueryRangeResponse
 */
export interface QueryRangeResponse {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "metrics";
        /**
         * TODO: Potentially move these types to be arrow records.
         * TODO: These need to be repeated!
         *
         * @generated from protobuf field: glacier.query.v1alpha1.MetricsSeries metrics = 1;
         */
        metrics: MetricsSeries;
    } | {
        oneofKind: "stream";
        /**
         * @generated from protobuf field: glacier.query.v1alpha1.Stream stream = 2;
         */
        stream: Stream;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message glacier.query.v1alpha1.MetricsSeries
 */
export interface MetricsSeries {
    /**
     * labelset is the set of key value pairs
     *
     * @generated from protobuf field: glacier.query.v1alpha1.LabelSet labelset = 1;
     */
    labelset?: LabelSet;
    /**
     * samples is the set of top-level cumulative values of the corresponding profiles
     *
     * @generated from protobuf field: repeated glacier.query.v1alpha1.MetricsSample samples = 2;
     */
    samples: MetricsSample[];
}
/**
 * @generated from protobuf message glacier.query.v1alpha1.Stream
 */
export interface Stream {
    /**
     * record
     *
     * @generated from protobuf field: bytes record = 1;
     */
    record: Uint8Array;
}
/**
 * Label is a key value pair of identifiers
 *
 * @generated from protobuf message glacier.query.v1alpha1.Label
 */
export interface Label {
    /**
     * name is the label name
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * value is the value for the label name
     *
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * LabelSet is a group of labels
 *
 * @generated from protobuf message glacier.query.v1alpha1.LabelSet
 */
export interface LabelSet {
    /**
     * labels are the grouping of labels
     *
     * @generated from protobuf field: repeated glacier.query.v1alpha1.Label labels = 1;
     */
    labels: Label[];
}
/**
 * @generated from protobuf message glacier.query.v1alpha1.MetricsSample
 */
export interface MetricsSample {
    /**
     * timestamp is the time the metric was provided.
     *
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * value is the metrics value at the time of the sample.
     *
     * @generated from protobuf field: double value = 2;
     */
    value: number;
}
/**
 * LabelsRequest are the request values for labels
 *
 * @generated from protobuf message glacier.query.v1alpha1.LabelsRequest
 */
export interface LabelsRequest {
    /**
     * match are the set of matching strings
     *
     * @generated from protobuf field: repeated string match = 1;
     */
    match: string[];
    /**
     * start is the start of the time window to perform the query
     *
     * @generated from protobuf field: google.protobuf.Timestamp start = 2;
     */
    start?: Timestamp;
    /**
     * end is the end of the time window to perform the query
     *
     * @generated from protobuf field: google.protobuf.Timestamp end = 3;
     */
    end?: Timestamp;
}
/**
 * LabelsResponse is the set of matching label names
 *
 * @generated from protobuf message glacier.query.v1alpha1.LabelsResponse
 */
export interface LabelsResponse {
    /**
     * / label_names are the set of matching label names
     *
     * @generated from protobuf field: repeated string label_names = 1;
     */
    labelNames: string[];
    /**
     * warnings is unimplemented
     *
     * @generated from protobuf field: repeated string warnings = 2;
     */
    warnings: string[];
}
/**
 * ValuesRequest are the request values for a values request
 *
 * @generated from protobuf message glacier.query.v1alpha1.ValuesRequest
 */
export interface ValuesRequest {
    /**
     * label_name is the label name to match values against
     *
     * @generated from protobuf field: string label_name = 1;
     */
    labelName: string;
    /**
     * match are the set of matching strings to match values against
     *
     * @generated from protobuf field: repeated string match = 2;
     */
    match: string[];
    /**
     * start is the start of the time window to perform the query
     *
     * @generated from protobuf field: google.protobuf.Timestamp start = 3;
     */
    start?: Timestamp;
    /**
     * end is the end of the time window to perform the query
     *
     * @generated from protobuf field: google.protobuf.Timestamp end = 4;
     */
    end?: Timestamp;
}
/**
 * ValuesResponse are the set of matching values
 *
 * @generated from protobuf message glacier.query.v1alpha1.ValuesResponse
 */
export interface ValuesResponse {
    /**
     * label_values are the set of matching label values
     *
     * @generated from protobuf field: repeated string label_values = 1;
     */
    labelValues: string[];
    /**
     * warnings is unimplemented
     *
     * @generated from protobuf field: repeated string warnings = 2;
     */
    warnings: string[];
}
/**
 * SeriesRequest are requests to get all labelsets for a given query
 *
 * @generated from protobuf message glacier.query.v1alpha1.SeriesRequest
 */
export interface SeriesRequest {
    /**
     * query is the query string to match profiles against
     *
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * start is the start of the query time window
     *
     * @generated from protobuf field: google.protobuf.Timestamp start = 2;
     */
    start?: Timestamp;
    /**
     * end is the end of the query time window
     *
     * @generated from protobuf field: google.protobuf.Timestamp end = 3;
     */
    end?: Timestamp;
}
/**
 * SeriesResponse are the set of labelsets that match the query
 *
 * @generated from protobuf message glacier.query.v1alpha1.SeriesResponse
 */
export interface SeriesResponse {
    /**
     * labelsets are the set of labelsets that match the query
     *
     * @generated from protobuf field: repeated glacier.query.v1alpha1.LabelSet labelset = 1;
     */
    labelset: LabelSet[];
}
// @generated message type with reflection information, may provide speed optimized methods
class QueryRangeRequest$Type extends MessageType<QueryRangeRequest> {
    constructor() {
        super("glacier.query.v1alpha1.QueryRangeRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp },
            { no: 4, name: "filters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Filter },
            { no: 5, name: "step", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<QueryRangeRequest>): QueryRangeRequest {
        const message = { query: "", filters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryRangeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryRangeRequest): QueryRangeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* repeated glacier.query.v1alpha1.Filter filters */ 4:
                    message.filters.push(Filter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Duration step */ 5:
                    message.step = Duration.internalBinaryRead(reader, reader.uint32(), options, message.step);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryRangeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated glacier.query.v1alpha1.Filter filters = 4; */
        for (let i = 0; i < message.filters.length; i++)
            Filter.internalBinaryWrite(message.filters[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration step = 5; */
        if (message.step)
            Duration.internalBinaryWrite(message.step, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.QueryRangeRequest
 */
export const QueryRangeRequest = new QueryRangeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("glacier.query.v1alpha1.Filter", [
            { no: 1, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = { operator: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string operator */ 1:
                    message.operator = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string operator = 1; */
        if (message.operator !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.operator);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryRangeResponse$Type extends MessageType<QueryRangeResponse> {
    constructor() {
        super("glacier.query.v1alpha1.QueryRangeResponse", [
            { no: 1, name: "metrics", kind: "message", oneof: "type", T: () => MetricsSeries },
            { no: 2, name: "stream", kind: "message", oneof: "type", T: () => Stream }
        ]);
    }
    create(value?: PartialMessage<QueryRangeResponse>): QueryRangeResponse {
        const message = { type: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QueryRangeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QueryRangeResponse): QueryRangeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* glacier.query.v1alpha1.MetricsSeries metrics */ 1:
                    message.type = {
                        oneofKind: "metrics",
                        metrics: MetricsSeries.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).metrics)
                    };
                    break;
                case /* glacier.query.v1alpha1.Stream stream */ 2:
                    message.type = {
                        oneofKind: "stream",
                        stream: Stream.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).stream)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QueryRangeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* glacier.query.v1alpha1.MetricsSeries metrics = 1; */
        if (message.type.oneofKind === "metrics")
            MetricsSeries.internalBinaryWrite(message.type.metrics, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* glacier.query.v1alpha1.Stream stream = 2; */
        if (message.type.oneofKind === "stream")
            Stream.internalBinaryWrite(message.type.stream, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.QueryRangeResponse
 */
export const QueryRangeResponse = new QueryRangeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricsSeries$Type extends MessageType<MetricsSeries> {
    constructor() {
        super("glacier.query.v1alpha1.MetricsSeries", [
            { no: 1, name: "labelset", kind: "message", T: () => LabelSet },
            { no: 2, name: "samples", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MetricsSample }
        ]);
    }
    create(value?: PartialMessage<MetricsSeries>): MetricsSeries {
        const message = { samples: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MetricsSeries>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MetricsSeries): MetricsSeries {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* glacier.query.v1alpha1.LabelSet labelset */ 1:
                    message.labelset = LabelSet.internalBinaryRead(reader, reader.uint32(), options, message.labelset);
                    break;
                case /* repeated glacier.query.v1alpha1.MetricsSample samples */ 2:
                    message.samples.push(MetricsSample.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MetricsSeries, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* glacier.query.v1alpha1.LabelSet labelset = 1; */
        if (message.labelset)
            LabelSet.internalBinaryWrite(message.labelset, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated glacier.query.v1alpha1.MetricsSample samples = 2; */
        for (let i = 0; i < message.samples.length; i++)
            MetricsSample.internalBinaryWrite(message.samples[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.MetricsSeries
 */
export const MetricsSeries = new MetricsSeries$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Stream$Type extends MessageType<Stream> {
    constructor() {
        super("glacier.query.v1alpha1.Stream", [
            { no: 1, name: "record", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Stream>): Stream {
        const message = { record: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Stream>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Stream): Stream {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes record */ 1:
                    message.record = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Stream, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes record = 1; */
        if (message.record.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.record);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.Stream
 */
export const Stream = new Stream$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Label$Type extends MessageType<Label> {
    constructor() {
        super("glacier.query.v1alpha1.Label", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Label>): Label {
        const message = { name: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Label>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Label): Label {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Label, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.Label
 */
export const Label = new Label$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelSet$Type extends MessageType<LabelSet> {
    constructor() {
        super("glacier.query.v1alpha1.LabelSet", [
            { no: 1, name: "labels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Label }
        ]);
    }
    create(value?: PartialMessage<LabelSet>): LabelSet {
        const message = { labels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LabelSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LabelSet): LabelSet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated glacier.query.v1alpha1.Label labels */ 1:
                    message.labels.push(Label.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LabelSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated glacier.query.v1alpha1.Label labels = 1; */
        for (let i = 0; i < message.labels.length; i++)
            Label.internalBinaryWrite(message.labels[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.LabelSet
 */
export const LabelSet = new LabelSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricsSample$Type extends MessageType<MetricsSample> {
    constructor() {
        super("glacier.query.v1alpha1.MetricsSample", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<MetricsSample>): MetricsSample {
        const message = { value: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MetricsSample>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MetricsSample): MetricsSample {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MetricsSample, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.MetricsSample
 */
export const MetricsSample = new MetricsSample$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelsRequest$Type extends MessageType<LabelsRequest> {
    constructor() {
        super("glacier.query.v1alpha1.LabelsRequest", [
            { no: 1, name: "match", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<LabelsRequest>): LabelsRequest {
        const message = { match: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LabelsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LabelsRequest): LabelsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string match */ 1:
                    message.match.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LabelsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string match = 1; */
        for (let i = 0; i < message.match.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.match[i]);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.LabelsRequest
 */
export const LabelsRequest = new LabelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelsResponse$Type extends MessageType<LabelsResponse> {
    constructor() {
        super("glacier.query.v1alpha1.LabelsResponse", [
            { no: 1, name: "label_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "warnings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LabelsResponse>): LabelsResponse {
        const message = { labelNames: [], warnings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LabelsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LabelsResponse): LabelsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string label_names */ 1:
                    message.labelNames.push(reader.string());
                    break;
                case /* repeated string warnings */ 2:
                    message.warnings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LabelsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string label_names = 1; */
        for (let i = 0; i < message.labelNames.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.labelNames[i]);
        /* repeated string warnings = 2; */
        for (let i = 0; i < message.warnings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.warnings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.LabelsResponse
 */
export const LabelsResponse = new LabelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValuesRequest$Type extends MessageType<ValuesRequest> {
    constructor() {
        super("glacier.query.v1alpha1.ValuesRequest", [
            { no: 1, name: "label_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "match", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "start", kind: "message", T: () => Timestamp },
            { no: 4, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ValuesRequest>): ValuesRequest {
        const message = { labelName: "", match: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValuesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValuesRequest): ValuesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label_name */ 1:
                    message.labelName = reader.string();
                    break;
                case /* repeated string match */ 2:
                    message.match.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp start */ 3:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 4:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValuesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label_name = 1; */
        if (message.labelName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.labelName);
        /* repeated string match = 2; */
        for (let i = 0; i < message.match.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.match[i]);
        /* google.protobuf.Timestamp start = 3; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 4; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.ValuesRequest
 */
export const ValuesRequest = new ValuesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValuesResponse$Type extends MessageType<ValuesResponse> {
    constructor() {
        super("glacier.query.v1alpha1.ValuesResponse", [
            { no: 1, name: "label_values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "warnings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValuesResponse>): ValuesResponse {
        const message = { labelValues: [], warnings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValuesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValuesResponse): ValuesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string label_values */ 1:
                    message.labelValues.push(reader.string());
                    break;
                case /* repeated string warnings */ 2:
                    message.warnings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValuesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string label_values = 1; */
        for (let i = 0; i < message.labelValues.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.labelValues[i]);
        /* repeated string warnings = 2; */
        for (let i = 0; i < message.warnings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.warnings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.ValuesResponse
 */
export const ValuesResponse = new ValuesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeriesRequest$Type extends MessageType<SeriesRequest> {
    constructor() {
        super("glacier.query.v1alpha1.SeriesRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SeriesRequest>): SeriesRequest {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SeriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeriesRequest): SeriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.SeriesRequest
 */
export const SeriesRequest = new SeriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeriesResponse$Type extends MessageType<SeriesResponse> {
    constructor() {
        super("glacier.query.v1alpha1.SeriesResponse", [
            { no: 1, name: "labelset", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LabelSet }
        ]);
    }
    create(value?: PartialMessage<SeriesResponse>): SeriesResponse {
        const message = { labelset: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SeriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeriesResponse): SeriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated glacier.query.v1alpha1.LabelSet labelset */ 1:
                    message.labelset.push(LabelSet.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated glacier.query.v1alpha1.LabelSet labelset = 1; */
        for (let i = 0; i < message.labelset.length; i++)
            LabelSet.internalBinaryWrite(message.labelset[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message glacier.query.v1alpha1.SeriesResponse
 */
export const SeriesResponse = new SeriesResponse$Type();
/**
 * @generated ServiceType for protobuf service glacier.query.v1alpha1.QueryService
 */
export const QueryService = new ServiceType("glacier.query.v1alpha1.QueryService", [
    { name: "QueryRange", options: {}, I: QueryRangeRequest, O: QueryRangeResponse },
    { name: "Labels", options: {}, I: LabelsRequest, O: LabelsResponse },
    { name: "Values", options: {}, I: ValuesRequest, O: ValuesResponse },
    { name: "Series", options: {}, I: SeriesRequest, O: SeriesResponse }
]);
