import {MutableRefObject} from 'react';

import {Icon} from '@iconify/react';
import {Project} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {View} from '@polarsignals/client-grpc-web/polarsignals/view/v1alpha1/view';
import cx from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {Tooltip} from 'react-tooltip';
import {useColorMode} from 'theme-ui';

import useViews from 'hooks/data/useViews';
import {useCurrentUserProjectAccess} from 'hooks/data/useCurrentUser/access';

interface Props {
  views: View[];
  selectedView: View;
  isViewSelectorClicked: boolean;
  activeView: View | undefined;
  close: (
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined
  ) => void;
  activeProject: Project | null;
}

const ViewSelector = ({views, isViewSelectorClicked, activeView, close, activeProject}: Props) => {
  const [colorMode] = useColorMode();
  const router = useRouter();
  const isViewsPage = router.pathname === '/projects/[project-id]/views/[view-slug]';

  const {
    mutations: {setActiveView},
  } = useViews();

  const selectView = (viewSlug: string, projectId: string) => {
    setActiveView(viewSlug);
    router.push(`/projects/${projectId}/views/${viewSlug}`);
  };

  const {data: accessData} = useCurrentUserProjectAccess(activeProject?.id as string);

  return (
    <div
      className={cx(
        isViewSelectorClicked ? 'bg-white dark:bg-gray-700' : 'bg-gray-50 dark:bg-gray-800',
        'max-w-64 flex w-64 flex-col border-l-[1px] border-gray-200 dark:border-gray-700'
      )}
    >
      <div className="flex flex-col gap-2 py-4 px-2">
        <p className="flex px-2 text-xs text-gray-500">
          <span>Views</span>
        </p>
        <div className="flex flex-col gap-1 text-gray-800 dark:text-gray-100">
          {views?.length > 0 && (
            <>
              <Link
                href={`/projects/${activeProject?.id}`}
                className="flex cursor-pointer items-center py-2 px-2.5 text-sm hover:bg-indigo-500 hover:text-white"
              >
                <span>All</span>
                <Icon
                  icon="ph:info"
                  width={16}
                  height={16}
                  className="ml-1"
                  data-tooltip-id="allViewHelpTextExplanationTooltip"
                  data-tooltip-html="The “All” view is a view which is essentially all of your project’s profiling data. To limit your your profiling data using pre-defined filters, use the New View button to create a new View."
                />
                <Tooltip
                  id="allViewHelpTextExplanationTooltip"
                  style={{
                    backgroundColor: colorMode === 'dark' ? '#374151' : '#F9FAFB',
                    color: colorMode === 'dark' ? '#fff' : '#4B5563',
                    borderRadius: '8px',
                    padding: '12px',
                    textAlign: 'left',
                    zIndex: 99,
                    width: '304px',
                  }}
                  place="right"
                />
              </Link>
              {views?.map((view, index) => (
                <div
                  key={index}
                  onClick={() => {
                    selectView(view.slug, view.projectId);
                    close();
                  }}
                  className={cx(
                    isViewsPage && view.slug === activeView?.slug
                      ? 'bg-indigo-600 text-white'
                      : 'hover:bg-indigo-500 hover:text-white',
                    'flex cursor-pointer items-center justify-between rounded-sm py-2 px-2.5 text-sm '
                  )}
                >
                  <span>{view.name}</span>
                  {isViewsPage && view.slug === activeView?.slug && (
                    <Icon icon="carbon:checkmark" className="" width={12} height={12} />
                  )}
                </div>
              ))}
            </>
          )}

          <div
            className="flex cursor-pointer items-center gap-2 rounded-sm px-2.5 py-2 text-sm hover:bg-indigo-500 hover:text-white"
            onClick={() => {
              if (accessData?.hasWriteAccess !== true) {
                return window.alert('You do not have access to create/edit views.');
              }
              if (isViewsPage) {
                router.push(`/projects/${activeProject?.id}/?showViewDrawer=true`);
                return;
              }
              router.push({
                query: {
                  ...router.query,
                  showViewDrawer: true,
                },
              });
            }}
          >
            <Icon icon="ei:plus" width={18} height={18} />
            <span>New View</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSelector;
