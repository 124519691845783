/** @jsxImportSource theme-ui */
import React, {useState, useEffect} from 'react';

import {useColorMode} from 'theme-ui';

const ColorModeProvider = ({children}: {children: React.ReactNode}) => {
  const [colorMode] = useColorMode();
  const [localColorMode, setLocalColorMode] = useState('');

  useEffect(() => setLocalColorMode(colorMode), [colorMode]);

  useEffect(() => {
    if (localColorMode === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [localColorMode]);

  // this is currently a hack, we are basically using ThemeUI to set the color mode but
  // providing the class name so that our existing TailwindCSS dark:XYZ classes will be able to be used
  return <div>{children}</div>;
};

export default ColorModeProvider;
