/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';
import cx from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';

import {useAppSelector} from 'store/hooks';
import {selectSidebarCollapsed} from 'store/slices/uiSlice';

import CollapseSidebarIcon from './CollapseSidebarIcon';

export interface ISidebarItem {
  icon: string;
  label: string;
  link: string;
  pattern: string;
  isActive?: boolean;
  anchorProps?: React.HTMLProps<HTMLAnchorElement>;
}

const SidebarItem = ({icon, label, link, isActive, anchorProps = {}}: ISidebarItem) => {
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  return (
    <li>
      <Link href={link} passHref legacyBehavior>
        {isActive ? (
          <a
            className={cx(
              'group flex h-10 flex-1 items-center gap-x-3 rounded-md bg-gray-100 p-2 text-sm font-semibold leading-6 text-blue-700 dark:bg-gray-800 dark:text-blue-300',
              isSidebarCollapsed && 'justify-center'
            )}
            title={label}
            {...anchorProps}
          >
            <Icon
              icon={icon}
              className={cx(!isSidebarCollapsed && 'h-6 w-6', isSidebarCollapsed && 'h-6 w-6')}
            />
            {!isSidebarCollapsed && label}
          </a>
        ) : (
          <a
            className={cx(
              'group flex h-10 items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-100 hover:text-blue-700 dark:text-gray-300 dark:hover:bg-gray-800',
              isSidebarCollapsed && 'justify-center'
            )}
            title={label}
            {...anchorProps}
          >
            <Icon
              icon={icon}
              className={cx(!isSidebarCollapsed && 'h-6 w-6', isSidebarCollapsed && 'h-6 w-6')}
            />
            {!isSidebarCollapsed && label}
          </a>
        )}
      </Link>
    </li>
  );
};

interface SidebarProps {
  items: ISidebarItem[];
}

const Sidebar = ({items}: SidebarProps) => {
  const router = useRouter();

  return (
    <div className="relative flex h-full flex-col">
      <nav className="flex flex-1 flex-col py-4">
        <ul className="mx-2 space-y-1">
          {items.map(item => {
            return (
              <SidebarItem key={item.link} {...item} isActive={router.pathname === item.pattern} />
            );
          })}
        </ul>
      </nav>

      <CollapseSidebarIcon />
    </div>
  );
};

export default Sidebar;
