/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';

import Status from './Status';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer sx={{background: 'background'}} className="dark:bg-gray-900">
      <div sx={{background: 'soft'}} className="mr-5 ml-5 flex rounded-2xl p-8">
        <div className="flex w-full flex-col items-center gap-8 lg:flex-row-reverse lg:justify-between">
          <div className="flex flex-col gap-2 lg:flex-row lg:gap-6">
            <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-6">
              <a href="mailto:support@polarsignals.com" target="_blank" rel="noopener noreferrer">
                Support
              </a>
              <Status />
              <a href="https://polarsignals.com/pricing" target="_blank" rel="noopener noreferrer">
                Pricing
              </a>
              <a
                href="https://polarsignals.com/schedule-a-call"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Sales
              </a>
            </div>

            <div className="flex items-center justify-center gap-4 lg:gap-6">
              <a href="https://discord.gg/knw3u5X9bs" target="_blank" rel="noreferrer">
                <Icon icon="ic:baseline-discord" width={24} height={24} />
              </a>
              <a href="https://twitter.com/PolarSignalsIO" target="_blank" rel="noreferrer">
                <Icon icon="bi:twitter" width={24} height={24} />
              </a>
              <a href="https://github.com/polarsignals" target="_blank" rel="noreferrer">
                <Icon icon="uiw:github" width={24} height={24} />
              </a>
            </div>
          </div>

          <div className="text-center lg:text-justify">
            {' '}
            © {year} Polar Signals. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
