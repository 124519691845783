// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/ai/v1alpha1/ai.proto" (package "polarsignals.ai.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AIService } from "./ai";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSourceCodeOptimizationsResponse } from "./ai";
import type { GetSourceCodeOptimizationsRequest } from "./ai";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * AIService is the service that provides AI related functionality.
 *
 * @generated from protobuf service polarsignals.ai.v1alpha1.AIService
 */
export interface IAIServiceClient {
    /**
     * GetSourceCodeOptimizations returns the optimization for the given code snippet.
     *
     * @generated from protobuf rpc: GetSourceCodeOptimizations(polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsRequest) returns (polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsResponse);
     */
    getSourceCodeOptimizations(input: GetSourceCodeOptimizationsRequest, options?: RpcOptions): UnaryCall<GetSourceCodeOptimizationsRequest, GetSourceCodeOptimizationsResponse>;
}
/**
 * AIService is the service that provides AI related functionality.
 *
 * @generated from protobuf service polarsignals.ai.v1alpha1.AIService
 */
export class AIServiceClient implements IAIServiceClient, ServiceInfo {
    typeName = AIService.typeName;
    methods = AIService.methods;
    options = AIService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetSourceCodeOptimizations returns the optimization for the given code snippet.
     *
     * @generated from protobuf rpc: GetSourceCodeOptimizations(polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsRequest) returns (polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsResponse);
     */
    getSourceCodeOptimizations(input: GetSourceCodeOptimizationsRequest, options?: RpcOptions): UnaryCall<GetSourceCodeOptimizationsRequest, GetSourceCodeOptimizationsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSourceCodeOptimizationsRequest, GetSourceCodeOptimizationsResponse>("unary", this._transport, method, opt, input);
    }
}
