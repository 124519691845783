import {useEffect, useMemo} from 'react';

import {Icon} from '@iconify/react';
import {ConditionalWrapper} from '@polarsignals/components';
import {Elements} from '@stripe/react-stripe-js';
import cx from 'classnames';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import Link from 'next/link';
import {useRouter} from 'next/router';

import useActiveProject from 'hooks/data/useActiveProject';
import useBilling, {useBillingConfig} from 'hooks/data/useBilling';
import useOrganizations from 'hooks/data/useOrganizations';
import getStripe from 'utils/getStripe';

TimeAgo.addDefaultLocale(en);

interface Props {
  organizationId: string;
  isNavbar?: boolean;
}

const FreeTrialStatusUI = ({organizationId, isNavbar = false}: Props) => {
  const {
    data: {organization},
    loading,
    error,
  } = useOrganizations(organizationId);
  const {subscription, subscriptionError, subscriptionLoading} = useBilling(organizationId);

  const userHasSubscription =
    !subscriptionLoading &&
    subscription != null &&
    (subscription.status === 'active' || subscription.status === 'past_due');

  const router = useRouter();

  const nonBillingPage = useMemo(() => {
    return router.pathname !== '/organizations/[organizationId]/settings';
  }, [router.pathname]);

  useEffect(() => {
    if (error != null) {
      console.error('Error showing trial status', error);
    }

    if (subscriptionError != null) {
      console.error('Error getting subscription status', subscriptionError);
    }
  }, [error, subscriptionError]);

  const {text, color, icon, additionalClassname} = useMemo(() => {
    if (
      loading ||
      organization?.trialEndsAt == null ||
      subscriptionLoading ||
      userHasSubscription
    ) {
      if (subscription?.status === 'past_due') {
        return {
          text: 'We had difficulty charging your card. Please update your payment method.',
          color: 'text-red-500',
          icon: 'maki:caution',
          additionalClassname: '!normal-case',
        };
      } else {
        return {};
      }
    }

    const trialEndsAtDate = new Date(Number(organization.trialEndsAt.seconds) * 1000);
    const now = new Date();
    const isExpired = trialEndsAtDate < now;

    if (nonBillingPage && !isExpired) {
      return {};
    }

    if (isExpired) {
      return {
        text: 'free trial expired',
        color: 'text-red-500',
      };
    }

    if (trialEndsAtDate < new Date(now.getTime() + 1000 * 60 * 60 * 24 * 2)) {
      return {
        text: `free trial expires ${new TimeAgo('en-US').format(trialEndsAtDate)}`,
        color: 'text-amber-500',
      };
    }

    return {
      text: 'free trial active',
      color: 'text-green-500',
    };
  }, [organization, nonBillingPage, loading, subscriptionLoading, userHasSubscription]);

  if (text == null || (isNavbar && !nonBillingPage)) {
    return null;
  }

  return (
    <ConditionalWrapper
      condition={nonBillingPage}
      wrapper={({children}) => (
        <Link href={`/organizations/${organizationId}/settings`}>{children}</Link>
      )}
    >
      <span
        className={cx(
          'flex items-center gap-1 whitespace-nowrap text-xs font-medium uppercase',
          color,
          additionalClassname
        )}
      >
        <Icon icon={icon ?? 'material-symbols:alarm-on-outline-rounded'} fontSize={18} /> {text}
      </span>
    </ConditionalWrapper>
  );
};

export const FreeTrialStatus = ({organizationId, isNavbar = false}: Props) => {
  const {
    loading: activeProjectLoading,
    data: {activeProject},
  } = useActiveProject();

  const {
    config,
    loading: configLoading,
    error: configError,
  } = useBillingConfig(activeProject?.id as string);

  if (configLoading || configError || activeProjectLoading) {
    return null;
  }

  return (
    <Elements stripe={getStripe(config?.publishableKey as string)}>
      <FreeTrialStatusUI organizationId={organizationId} isNavbar={isNavbar} />
    </Elements>
  );
};
