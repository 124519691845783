// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/organization/v1alpha1/organization.proto" (package "polarsignals.organization.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "../../user/v1alpha1/user";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * Organization represents an organization.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.Organization
 */
export interface Organization {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * the feature flags enabled for the organization.
     *
     * @generated from protobuf field: repeated string feature_flags = 3;
     */
    featureFlags: string[];
    /**
     * the price id override for the organization.
     *
     * @generated from protobuf field: string price_id = 4;
     */
    priceId: string;
    /**
     * trial expiration date.
     *
     * @generated from protobuf field: google.protobuf.Timestamp trial_ends_at = 5;
     */
    trialEndsAt?: Timestamp;
    /**
     * is the organization using PO billing
     *
     * @generated from protobuf field: bool is_po_billing = 6;
     */
    isPoBilling: boolean;
}
/**
 * InviteToken represents an invite token.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.InviteToken
 */
export interface InviteToken {
    /**
     * The ID of the invite token.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string organization_id = 2;
     */
    organizationId: string;
    /**
     * The role of the user.
     *
     * @generated from protobuf field: string role = 3;
     */
    role: string;
}
/**
 * GetOrganizationsRequest is the request for the GetOrganizations method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationsRequest
 */
export interface GetOrganizationsRequest {
}
/**
 * GetOrganizationsResponse is the response for the GetOrganizations method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationsResponse
 */
export interface GetOrganizationsResponse {
    /**
     * The organizations.
     *
     * @generated from protobuf field: repeated polarsignals.organization.v1alpha1.Organization organizations = 1;
     */
    organizations: Organization[];
}
/**
 * CreateOrganizationRequest is the request for the CreateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * CreateOrganizationResponse is the response for the CreateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.CreateOrganizationResponse
 */
export interface CreateOrganizationResponse {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetOrganizationRequest is the request for the GetOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationRequest
 */
export interface GetOrganizationRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetOrganizationResponse is the response for the GetOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationResponse
 */
export interface GetOrganizationResponse {
    /**
     * The organization.
     *
     * @generated from protobuf field: polarsignals.organization.v1alpha1.Organization organization = 1;
     */
    organization?: Organization;
}
/**
 * UpdateOrganizationRequest is the request for the UpdateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * UpdateOrganizationResponse is the response for the UpdateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationResponse
 */
export interface UpdateOrganizationResponse {
}
/**
 * DeleteOrganizationRequest is the request for the DeleteOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationRequest
 */
export interface DeleteOrganizationRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * DeleteOrganizationResponse is the response for the DeleteOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationResponse
 */
export interface DeleteOrganizationResponse {
}
/**
 * GetOrganizationUsersRequest is the request for the GetOrganizationUsers method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersRequest
 */
export interface GetOrganizationUsersRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetOrganizationUsersResponse is the response for the GetOrganizationUsers method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersResponse
 */
export interface GetOrganizationUsersResponse {
    /**
     * The users.
     *
     * @generated from protobuf field: repeated polarsignals.user.v1alpha1.User users = 1;
     */
    users: User[];
}
/**
 * AddOrganizationUserRequest is the request for the AddOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserRequest
 */
export interface AddOrganizationUserRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the user.
     *
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * AddOrganizationUserResponse is the response for the AddOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserResponse
 */
export interface AddOrganizationUserResponse {
}
/**
 * RemoveOrganizationUserRequest is the request for the RemoveOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest
 */
export interface RemoveOrganizationUserRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the user.
     *
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
}
/**
 * RemoveOrganizationUserResponse is the response for the RemoveOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse
 */
export interface RemoveOrganizationUserResponse {
}
/**
 * IsInviteTokenValidRequest is the request for the IsInviteTokenValid method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidRequest
 */
export interface IsInviteTokenValidRequest {
    /**
     * The invite token.
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * IsInviteTokenValidResponse is the response for the IsInviteTokenValid method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidResponse
 */
export interface IsInviteTokenValidResponse {
    /**
     * The organization name.
     *
     * @generated from protobuf field: string organization_name = 1;
     */
    organizationName: string;
}
/**
 * AcceptInviteTokenRequest is the request for the AcceptInviteToken method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenRequest
 */
export interface AcceptInviteTokenRequest {
    /**
     * The invite token.
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * AcceptInviteTokenResponse is the response for the AcceptInviteToken method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenResponse
 */
export interface AcceptInviteTokenResponse {
}
/**
 * GetInviteTokensRequest is the request for the GetInviteTokens method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetInviteTokensRequest
 */
export interface GetInviteTokensRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetInviteTokensResponse is the response for the GetInviteTokens method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetInviteTokensResponse
 */
export interface GetInviteTokensResponse {
    /**
     * The invite tokens.
     *
     * @generated from protobuf field: repeated polarsignals.organization.v1alpha1.InviteToken invite_tokens = 1;
     */
    inviteTokens: InviteToken[];
}
/**
 * ActivateFreeTrialRequest is the request for the ActivateFreeTrial method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.ActivateFreeTrialRequest
 */
export interface ActivateFreeTrialRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * ActivateFreeTrialResponse is the response for the ActivateFreeTrial method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.ActivateFreeTrialResponse
 */
export interface ActivateFreeTrialResponse {
}
/**
 * InviteUsersViaEmailRequest is the request for the InviteUsersViaEmail method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.InviteUsersViaEmailRequest
 */
export interface InviteUsersViaEmailRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The email addresses of the users.
     *
     * @generated from protobuf field: repeated string emails = 2;
     */
    emails: string[];
}
/**
 * InviteUsersViaEmailResponse is the response for the InviteUsersViaEmail method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.InviteUsersViaEmailResponse
 */
export interface InviteUsersViaEmailResponse {
}
/**
 * EnableSSORequest is the request for the EnableSSO method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.EnableSSORequest
 */
export interface EnableSSORequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The SSO settings.
     *
     * @generated from protobuf field: polarsignals.organization.v1alpha1.SSOSettings sso_settings = 2;
     */
    ssoSettings?: SSOSettings;
}
/**
 * EnableSSOResponse is the response for the EnableSSO method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.EnableSSOResponse
 */
export interface EnableSSOResponse {
}
/**
 * DisableSSORequest is the request for the DisableSSO method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.DisableSSORequest
 */
export interface DisableSSORequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * DisableSSOResponse is the response for the DisableSSO method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.DisableSSOResponse
 */
export interface DisableSSOResponse {
}
/**
 * GetSSOSettingsRequest is the request for the GetSSOSettings method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetSSOSettingsRequest
 */
export interface GetSSOSettingsRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetSSOSettingsResponse is the response for the GetSSOSettings method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetSSOSettingsResponse
 */
export interface GetSSOSettingsResponse {
    /**
     * The SSO settings.
     *
     * @generated from protobuf field: polarsignals.organization.v1alpha1.SSOSettings sso_settings = 1;
     */
    ssoSettings?: SSOSettings;
}
/**
 * SSOSettings represents the SSO settings.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.SSOSettings
 */
export interface SSOSettings {
    /**
     * Organization identifier
     *
     * @generated from protobuf field: string organization_identifier = 1;
     */
    organizationIdentifier: string;
    /**
     * Type
     *
     * @generated from protobuf field: string sso_type = 2;
     */
    ssoType: string;
    /**
     * @generated from protobuf oneof: sso_config
     */
    ssoConfig: {
        oneofKind: "oidcConfig";
        /**
         * OIDC configuration
         *
         * @generated from protobuf field: polarsignals.organization.v1alpha1.OIDCConfig oidc_config = 3;
         */
        oidcConfig: OIDCConfig;
    } | {
        oneofKind: undefined;
    };
}
/**
 * OIDCConfig represents the OIDC configuration.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.OIDCConfig
 */
export interface OIDCConfig {
    /**
     * The issuer URL.
     *
     * @generated from protobuf field: string issuer_url = 1;
     */
    issuerUrl: string;
    /**
     * The client ID.
     *
     * @generated from protobuf field: string client_id = 2;
     */
    clientId: string;
    /**
     * The client secret.
     *
     * @generated from protobuf field: string client_secret = 3;
     */
    clientSecret: string;
}
/**
 * GetSSOConnectorIdRequest is the request for the GetSSOConnectorId method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetSSOConnectorIdRequest
 */
export interface GetSSOConnectorIdRequest {
    /**
     * The organization identifier.
     *
     * @generated from protobuf field: string organization_identifier = 1;
     */
    organizationIdentifier: string;
}
/**
 * GetSSOConnectorIdResponse is the response for the GetSSOConnectorId method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetSSOConnectorIdResponse
 */
export interface GetSSOConnectorIdResponse {
    /**
     * The Connector Id.
     *
     * @generated from protobuf field: string connector_id = 1;
     */
    connectorId: string;
}
/**
 * UpdateUserRoleRequest is the request for the UpdateUserRole method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.UpdateUserRoleRequest
 */
export interface UpdateUserRoleRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
    /**
     * The ID of the user.
     *
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * The role of the user.
     *
     * @generated from protobuf field: string role = 3;
     */
    role: string;
}
/**
 * UpdateUserRoleResponse is the response for the UpdateUserRole method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.UpdateUserRoleResponse
 */
export interface UpdateUserRoleResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class Organization$Type extends MessageType<Organization> {
    constructor() {
        super("polarsignals.organization.v1alpha1.Organization", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "feature_flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "price_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "trial_ends_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "is_po_billing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Organization>): Organization {
        const message = { id: "", name: "", featureFlags: [], priceId: "", isPoBilling: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Organization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Organization): Organization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string feature_flags */ 3:
                    message.featureFlags.push(reader.string());
                    break;
                case /* string price_id */ 4:
                    message.priceId = reader.string();
                    break;
                case /* google.protobuf.Timestamp trial_ends_at */ 5:
                    message.trialEndsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.trialEndsAt);
                    break;
                case /* bool is_po_billing */ 6:
                    message.isPoBilling = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Organization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string feature_flags = 3; */
        for (let i = 0; i < message.featureFlags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.featureFlags[i]);
        /* string price_id = 4; */
        if (message.priceId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.priceId);
        /* google.protobuf.Timestamp trial_ends_at = 5; */
        if (message.trialEndsAt)
            Timestamp.internalBinaryWrite(message.trialEndsAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool is_po_billing = 6; */
        if (message.isPoBilling !== false)
            writer.tag(6, WireType.Varint).bool(message.isPoBilling);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.Organization
 */
export const Organization = new Organization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteToken$Type extends MessageType<InviteToken> {
    constructor() {
        super("polarsignals.organization.v1alpha1.InviteToken", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InviteToken>): InviteToken {
        const message = { id: "", organizationId: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InviteToken>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InviteToken): InviteToken {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                case /* string role */ 3:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InviteToken, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        /* string role = 3; */
        if (message.role !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.InviteToken
 */
export const InviteToken = new InviteToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationsRequest$Type extends MessageType<GetOrganizationsRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationsRequest", []);
    }
    create(value?: PartialMessage<GetOrganizationsRequest>): GetOrganizationsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationsRequest): GetOrganizationsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetOrganizationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationsRequest
 */
export const GetOrganizationsRequest = new GetOrganizationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationsResponse$Type extends MessageType<GetOrganizationsResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationsResponse", [
            { no: 1, name: "organizations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Organization }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationsResponse>): GetOrganizationsResponse {
        const message = { organizations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationsResponse): GetOrganizationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.organization.v1alpha1.Organization organizations */ 1:
                    message.organizations.push(Organization.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.organization.v1alpha1.Organization organizations = 1; */
        for (let i = 0; i < message.organizations.length; i++)
            Organization.internalBinaryWrite(message.organizations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationsResponse
 */
export const GetOrganizationsResponse = new GetOrganizationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOrganizationRequest$Type extends MessageType<CreateOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.CreateOrganizationRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateOrganizationRequest>): CreateOrganizationRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOrganizationRequest): CreateOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.CreateOrganizationRequest
 */
export const CreateOrganizationRequest = new CreateOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOrganizationResponse$Type extends MessageType<CreateOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.CreateOrganizationResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateOrganizationResponse>): CreateOrganizationResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOrganizationResponse): CreateOrganizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.CreateOrganizationResponse
 */
export const CreateOrganizationResponse = new CreateOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationRequest$Type extends MessageType<GetOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationRequest>): GetOrganizationRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationRequest): GetOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationRequest
 */
export const GetOrganizationRequest = new GetOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationResponse$Type extends MessageType<GetOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationResponse", [
            { no: 1, name: "organization", kind: "message", T: () => Organization }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationResponse>): GetOrganizationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationResponse): GetOrganizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.organization.v1alpha1.Organization organization */ 1:
                    message.organization = Organization.internalBinaryRead(reader, reader.uint32(), options, message.organization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.organization.v1alpha1.Organization organization = 1; */
        if (message.organization)
            Organization.internalBinaryWrite(message.organization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationResponse
 */
export const GetOrganizationResponse = new GetOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationRequest$Type extends MessageType<UpdateOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.UpdateOrganizationRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateOrganizationRequest>): UpdateOrganizationRequest {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationRequest): UpdateOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationRequest
 */
export const UpdateOrganizationRequest = new UpdateOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationResponse$Type extends MessageType<UpdateOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.UpdateOrganizationResponse", []);
    }
    create(value?: PartialMessage<UpdateOrganizationResponse>): UpdateOrganizationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationResponse): UpdateOrganizationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationResponse
 */
export const UpdateOrganizationResponse = new UpdateOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOrganizationRequest$Type extends MessageType<DeleteOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.DeleteOrganizationRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteOrganizationRequest>): DeleteOrganizationRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOrganizationRequest): DeleteOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationRequest
 */
export const DeleteOrganizationRequest = new DeleteOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOrganizationResponse$Type extends MessageType<DeleteOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.DeleteOrganizationResponse", []);
    }
    create(value?: PartialMessage<DeleteOrganizationResponse>): DeleteOrganizationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOrganizationResponse): DeleteOrganizationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationResponse
 */
export const DeleteOrganizationResponse = new DeleteOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationUsersRequest$Type extends MessageType<GetOrganizationUsersRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationUsersRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationUsersRequest>): GetOrganizationUsersRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationUsersRequest): GetOrganizationUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersRequest
 */
export const GetOrganizationUsersRequest = new GetOrganizationUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationUsersResponse$Type extends MessageType<GetOrganizationUsersResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationUsersResponse", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationUsersResponse>): GetOrganizationUsersResponse {
        const message = { users: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationUsersResponse): GetOrganizationUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.user.v1alpha1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.user.v1alpha1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersResponse
 */
export const GetOrganizationUsersResponse = new GetOrganizationUsersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOrganizationUserRequest$Type extends MessageType<AddOrganizationUserRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AddOrganizationUserRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddOrganizationUserRequest>): AddOrganizationUserRequest {
        const message = { id: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddOrganizationUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOrganizationUserRequest): AddOrganizationUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOrganizationUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserRequest
 */
export const AddOrganizationUserRequest = new AddOrganizationUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOrganizationUserResponse$Type extends MessageType<AddOrganizationUserResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AddOrganizationUserResponse", []);
    }
    create(value?: PartialMessage<AddOrganizationUserResponse>): AddOrganizationUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddOrganizationUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOrganizationUserResponse): AddOrganizationUserResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddOrganizationUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserResponse
 */
export const AddOrganizationUserResponse = new AddOrganizationUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveOrganizationUserRequest$Type extends MessageType<RemoveOrganizationUserRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveOrganizationUserRequest>): RemoveOrganizationUserRequest {
        const message = { id: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveOrganizationUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveOrganizationUserRequest): RemoveOrganizationUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveOrganizationUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest
 */
export const RemoveOrganizationUserRequest = new RemoveOrganizationUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveOrganizationUserResponse$Type extends MessageType<RemoveOrganizationUserResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse", []);
    }
    create(value?: PartialMessage<RemoveOrganizationUserResponse>): RemoveOrganizationUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveOrganizationUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveOrganizationUserResponse): RemoveOrganizationUserResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveOrganizationUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse
 */
export const RemoveOrganizationUserResponse = new RemoveOrganizationUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsInviteTokenValidRequest$Type extends MessageType<IsInviteTokenValidRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.IsInviteTokenValidRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IsInviteTokenValidRequest>): IsInviteTokenValidRequest {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IsInviteTokenValidRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsInviteTokenValidRequest): IsInviteTokenValidRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsInviteTokenValidRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidRequest
 */
export const IsInviteTokenValidRequest = new IsInviteTokenValidRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsInviteTokenValidResponse$Type extends MessageType<IsInviteTokenValidResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.IsInviteTokenValidResponse", [
            { no: 1, name: "organization_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IsInviteTokenValidResponse>): IsInviteTokenValidResponse {
        const message = { organizationName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IsInviteTokenValidResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsInviteTokenValidResponse): IsInviteTokenValidResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_name */ 1:
                    message.organizationName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsInviteTokenValidResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_name = 1; */
        if (message.organizationName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidResponse
 */
export const IsInviteTokenValidResponse = new IsInviteTokenValidResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptInviteTokenRequest$Type extends MessageType<AcceptInviteTokenRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AcceptInviteTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptInviteTokenRequest>): AcceptInviteTokenRequest {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcceptInviteTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptInviteTokenRequest): AcceptInviteTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptInviteTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenRequest
 */
export const AcceptInviteTokenRequest = new AcceptInviteTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptInviteTokenResponse$Type extends MessageType<AcceptInviteTokenResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AcceptInviteTokenResponse", []);
    }
    create(value?: PartialMessage<AcceptInviteTokenResponse>): AcceptInviteTokenResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcceptInviteTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptInviteTokenResponse): AcceptInviteTokenResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptInviteTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenResponse
 */
export const AcceptInviteTokenResponse = new AcceptInviteTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInviteTokensRequest$Type extends MessageType<GetInviteTokensRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetInviteTokensRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInviteTokensRequest>): GetInviteTokensRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInviteTokensRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInviteTokensRequest): GetInviteTokensRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInviteTokensRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetInviteTokensRequest
 */
export const GetInviteTokensRequest = new GetInviteTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInviteTokensResponse$Type extends MessageType<GetInviteTokensResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetInviteTokensResponse", [
            { no: 1, name: "invite_tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InviteToken }
        ]);
    }
    create(value?: PartialMessage<GetInviteTokensResponse>): GetInviteTokensResponse {
        const message = { inviteTokens: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInviteTokensResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInviteTokensResponse): GetInviteTokensResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.organization.v1alpha1.InviteToken invite_tokens */ 1:
                    message.inviteTokens.push(InviteToken.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInviteTokensResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.organization.v1alpha1.InviteToken invite_tokens = 1; */
        for (let i = 0; i < message.inviteTokens.length; i++)
            InviteToken.internalBinaryWrite(message.inviteTokens[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetInviteTokensResponse
 */
export const GetInviteTokensResponse = new GetInviteTokensResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateFreeTrialRequest$Type extends MessageType<ActivateFreeTrialRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.ActivateFreeTrialRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateFreeTrialRequest>): ActivateFreeTrialRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActivateFreeTrialRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateFreeTrialRequest): ActivateFreeTrialRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateFreeTrialRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.ActivateFreeTrialRequest
 */
export const ActivateFreeTrialRequest = new ActivateFreeTrialRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateFreeTrialResponse$Type extends MessageType<ActivateFreeTrialResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.ActivateFreeTrialResponse", []);
    }
    create(value?: PartialMessage<ActivateFreeTrialResponse>): ActivateFreeTrialResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ActivateFreeTrialResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateFreeTrialResponse): ActivateFreeTrialResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ActivateFreeTrialResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.ActivateFreeTrialResponse
 */
export const ActivateFreeTrialResponse = new ActivateFreeTrialResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteUsersViaEmailRequest$Type extends MessageType<InviteUsersViaEmailRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.InviteUsersViaEmailRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "emails", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InviteUsersViaEmailRequest>): InviteUsersViaEmailRequest {
        const message = { id: "", emails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InviteUsersViaEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InviteUsersViaEmailRequest): InviteUsersViaEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated string emails */ 2:
                    message.emails.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InviteUsersViaEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated string emails = 2; */
        for (let i = 0; i < message.emails.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.emails[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.InviteUsersViaEmailRequest
 */
export const InviteUsersViaEmailRequest = new InviteUsersViaEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteUsersViaEmailResponse$Type extends MessageType<InviteUsersViaEmailResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.InviteUsersViaEmailResponse", []);
    }
    create(value?: PartialMessage<InviteUsersViaEmailResponse>): InviteUsersViaEmailResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InviteUsersViaEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InviteUsersViaEmailResponse): InviteUsersViaEmailResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InviteUsersViaEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.InviteUsersViaEmailResponse
 */
export const InviteUsersViaEmailResponse = new InviteUsersViaEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnableSSORequest$Type extends MessageType<EnableSSORequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.EnableSSORequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sso_settings", kind: "message", T: () => SSOSettings }
        ]);
    }
    create(value?: PartialMessage<EnableSSORequest>): EnableSSORequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnableSSORequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnableSSORequest): EnableSSORequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* polarsignals.organization.v1alpha1.SSOSettings sso_settings */ 2:
                    message.ssoSettings = SSOSettings.internalBinaryRead(reader, reader.uint32(), options, message.ssoSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnableSSORequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* polarsignals.organization.v1alpha1.SSOSettings sso_settings = 2; */
        if (message.ssoSettings)
            SSOSettings.internalBinaryWrite(message.ssoSettings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.EnableSSORequest
 */
export const EnableSSORequest = new EnableSSORequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnableSSOResponse$Type extends MessageType<EnableSSOResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.EnableSSOResponse", []);
    }
    create(value?: PartialMessage<EnableSSOResponse>): EnableSSOResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnableSSOResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnableSSOResponse): EnableSSOResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EnableSSOResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.EnableSSOResponse
 */
export const EnableSSOResponse = new EnableSSOResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableSSORequest$Type extends MessageType<DisableSSORequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.DisableSSORequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisableSSORequest>): DisableSSORequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisableSSORequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableSSORequest): DisableSSORequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableSSORequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.DisableSSORequest
 */
export const DisableSSORequest = new DisableSSORequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableSSOResponse$Type extends MessageType<DisableSSOResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.DisableSSOResponse", []);
    }
    create(value?: PartialMessage<DisableSSOResponse>): DisableSSOResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisableSSOResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableSSOResponse): DisableSSOResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DisableSSOResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.DisableSSOResponse
 */
export const DisableSSOResponse = new DisableSSOResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSSOSettingsRequest$Type extends MessageType<GetSSOSettingsRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetSSOSettingsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSSOSettingsRequest>): GetSSOSettingsRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSSOSettingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSSOSettingsRequest): GetSSOSettingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSSOSettingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetSSOSettingsRequest
 */
export const GetSSOSettingsRequest = new GetSSOSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSSOSettingsResponse$Type extends MessageType<GetSSOSettingsResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetSSOSettingsResponse", [
            { no: 1, name: "sso_settings", kind: "message", T: () => SSOSettings }
        ]);
    }
    create(value?: PartialMessage<GetSSOSettingsResponse>): GetSSOSettingsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSSOSettingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSSOSettingsResponse): GetSSOSettingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.organization.v1alpha1.SSOSettings sso_settings */ 1:
                    message.ssoSettings = SSOSettings.internalBinaryRead(reader, reader.uint32(), options, message.ssoSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSSOSettingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.organization.v1alpha1.SSOSettings sso_settings = 1; */
        if (message.ssoSettings)
            SSOSettings.internalBinaryWrite(message.ssoSettings, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetSSOSettingsResponse
 */
export const GetSSOSettingsResponse = new GetSSOSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SSOSettings$Type extends MessageType<SSOSettings> {
    constructor() {
        super("polarsignals.organization.v1alpha1.SSOSettings", [
            { no: 1, name: "organization_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sso_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "oidc_config", kind: "message", oneof: "ssoConfig", T: () => OIDCConfig }
        ]);
    }
    create(value?: PartialMessage<SSOSettings>): SSOSettings {
        const message = { organizationIdentifier: "", ssoType: "", ssoConfig: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SSOSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SSOSettings): SSOSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_identifier */ 1:
                    message.organizationIdentifier = reader.string();
                    break;
                case /* string sso_type */ 2:
                    message.ssoType = reader.string();
                    break;
                case /* polarsignals.organization.v1alpha1.OIDCConfig oidc_config */ 3:
                    message.ssoConfig = {
                        oneofKind: "oidcConfig",
                        oidcConfig: OIDCConfig.internalBinaryRead(reader, reader.uint32(), options, (message.ssoConfig as any).oidcConfig)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SSOSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_identifier = 1; */
        if (message.organizationIdentifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationIdentifier);
        /* string sso_type = 2; */
        if (message.ssoType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ssoType);
        /* polarsignals.organization.v1alpha1.OIDCConfig oidc_config = 3; */
        if (message.ssoConfig.oneofKind === "oidcConfig")
            OIDCConfig.internalBinaryWrite(message.ssoConfig.oidcConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.SSOSettings
 */
export const SSOSettings = new SSOSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OIDCConfig$Type extends MessageType<OIDCConfig> {
    constructor() {
        super("polarsignals.organization.v1alpha1.OIDCConfig", [
            { no: 1, name: "issuer_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "client_secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OIDCConfig>): OIDCConfig {
        const message = { issuerUrl: "", clientId: "", clientSecret: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OIDCConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OIDCConfig): OIDCConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string issuer_url */ 1:
                    message.issuerUrl = reader.string();
                    break;
                case /* string client_id */ 2:
                    message.clientId = reader.string();
                    break;
                case /* string client_secret */ 3:
                    message.clientSecret = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OIDCConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string issuer_url = 1; */
        if (message.issuerUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.issuerUrl);
        /* string client_id = 2; */
        if (message.clientId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.clientId);
        /* string client_secret = 3; */
        if (message.clientSecret !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.clientSecret);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.OIDCConfig
 */
export const OIDCConfig = new OIDCConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSSOConnectorIdRequest$Type extends MessageType<GetSSOConnectorIdRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetSSOConnectorIdRequest", [
            { no: 1, name: "organization_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSSOConnectorIdRequest>): GetSSOConnectorIdRequest {
        const message = { organizationIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSSOConnectorIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSSOConnectorIdRequest): GetSSOConnectorIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_identifier */ 1:
                    message.organizationIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSSOConnectorIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_identifier = 1; */
        if (message.organizationIdentifier !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetSSOConnectorIdRequest
 */
export const GetSSOConnectorIdRequest = new GetSSOConnectorIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSSOConnectorIdResponse$Type extends MessageType<GetSSOConnectorIdResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetSSOConnectorIdResponse", [
            { no: 1, name: "connector_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSSOConnectorIdResponse>): GetSSOConnectorIdResponse {
        const message = { connectorId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSSOConnectorIdResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSSOConnectorIdResponse): GetSSOConnectorIdResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string connector_id */ 1:
                    message.connectorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSSOConnectorIdResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string connector_id = 1; */
        if (message.connectorId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.connectorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetSSOConnectorIdResponse
 */
export const GetSSOConnectorIdResponse = new GetSSOConnectorIdResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserRoleRequest$Type extends MessageType<UpdateUserRoleRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.UpdateUserRoleRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateUserRoleRequest>): UpdateUserRoleRequest {
        const message = { organizationId: "", userId: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUserRoleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserRoleRequest): UpdateUserRoleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* string role */ 3:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserRoleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string role = 3; */
        if (message.role !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.UpdateUserRoleRequest
 */
export const UpdateUserRoleRequest = new UpdateUserRoleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserRoleResponse$Type extends MessageType<UpdateUserRoleResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.UpdateUserRoleResponse", []);
    }
    create(value?: PartialMessage<UpdateUserRoleResponse>): UpdateUserRoleResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateUserRoleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserRoleResponse): UpdateUserRoleResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUserRoleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.UpdateUserRoleResponse
 */
export const UpdateUserRoleResponse = new UpdateUserRoleResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.organization.v1alpha1.OrganizationService
 */
export const OrganizationService = new ServiceType("polarsignals.organization.v1alpha1.OrganizationService", [
    { name: "GetOrganizations", options: { "google.api.http": { get: "/v1" } }, I: GetOrganizationsRequest, O: GetOrganizationsResponse },
    { name: "CreateOrganization", options: { "google.api.http": { post: "/v1", body: "*" } }, I: CreateOrganizationRequest, O: CreateOrganizationResponse },
    { name: "GetOrganization", options: { "google.api.http": { get: "/v1/{id}" } }, I: GetOrganizationRequest, O: GetOrganizationResponse },
    { name: "UpdateOrganization", options: { "google.api.http": { patch: "/v1/{id}", body: "*" } }, I: UpdateOrganizationRequest, O: UpdateOrganizationResponse },
    { name: "DeleteOrganization", options: { "google.api.http": { delete: "/v1/{id}" } }, I: DeleteOrganizationRequest, O: DeleteOrganizationResponse },
    { name: "GetOrganizationUsers", options: { "google.api.http": { get: "/v1/{id}/users" } }, I: GetOrganizationUsersRequest, O: GetOrganizationUsersResponse },
    { name: "AddOrganizationUser", options: { "google.api.http": { post: "/v1/{id}/users", body: "*" } }, I: AddOrganizationUserRequest, O: AddOrganizationUserResponse },
    { name: "RemoveOrganizationUser", options: { "google.api.http": { delete: "/v1/{id}/users" } }, I: RemoveOrganizationUserRequest, O: RemoveOrganizationUserResponse },
    { name: "IsInviteTokenValid", options: { "google.api.http": { post: "/v1/invite-token-validity/{token}" } }, I: IsInviteTokenValidRequest, O: IsInviteTokenValidResponse },
    { name: "AcceptInviteToken", options: { "google.api.http": { post: "/v1/accept-invite-token/{token}", body: "*" } }, I: AcceptInviteTokenRequest, O: AcceptInviteTokenResponse },
    { name: "GetInviteTokens", options: { "google.api.http": { get: "/v1/{id}/invite-tokens" } }, I: GetInviteTokensRequest, O: GetInviteTokensResponse },
    { name: "ActivateFreeTrial", options: { "google.api.http": { post: "/v1/{id}/activate-free-trial", body: "*" } }, I: ActivateFreeTrialRequest, O: ActivateFreeTrialResponse },
    { name: "InviteUsersViaEmail", options: { "google.api.http": { post: "/v1/{id}/invite-users-via-email", body: "*" } }, I: InviteUsersViaEmailRequest, O: InviteUsersViaEmailResponse },
    { name: "EnableSSO", options: { "google.api.http": { post: "/v1/{id}/enable-sso", body: "*" } }, I: EnableSSORequest, O: EnableSSOResponse },
    { name: "DisableSSO", options: { "google.api.http": { post: "/v1/{id}/disable-sso", body: "*" } }, I: DisableSSORequest, O: DisableSSOResponse },
    { name: "GetSSOSettings", options: { "google.api.http": { get: "/v1/{id}/sso-settings" } }, I: GetSSOSettingsRequest, O: GetSSOSettingsResponse },
    { name: "GetSSOConnectorId", options: { "google.api.http": { get: "/v1/sso-login-url/{organization_identifier}" } }, I: GetSSOConnectorIdRequest, O: GetSSOConnectorIdResponse },
    { name: "UpdateUserRole", options: { "google.api.http": { post: "/v1/{organization_id}/users/{user_id}/role", body: "*" } }, I: UpdateUserRoleRequest, O: UpdateUserRoleResponse }
]);
