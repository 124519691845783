import {Icon} from '@iconify/react';

interface Props {
  text: string;
  initiateTour: () => void;
  closeBanner: () => void;
}

const ProductTourBanner: React.FC<Props> = ({text, initiateTour, closeBanner}) => {
  return (
    <div className="bg-brand-dark-blue animate-push-down relative flex h-10 items-center justify-center px-5">
      <div
        className="flex flex-1 cursor-pointer items-center justify-center gap-1 text-white"
        onClick={() => initiateTour()}
      >
        <p>{text}</p>

        <Icon
          className="animate-horizontal-pulse text-white"
          icon="flowbite:arrow-right-outline"
          height={21}
        />
      </div>

      <Icon
        onClick={closeBanner}
        className="cursor-pointer text-white"
        icon="ic:twotone-close"
        height={24}
      />
    </div>
  );
};

export default ProductTourBanner;
