// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/billing/v1alpha1/billing.proto" (package "polarsignals.billing.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { BillingService } from "./billing";
import type { GetCurrentUsageResponse } from "./billing";
import type { GetCurrentUsageRequest } from "./billing";
import type { UpdateCustomerResponse } from "./billing";
import type { UpdateCustomerRequest } from "./billing";
import type { GetCustomerResponse } from "./billing";
import type { GetCustomerRequest } from "./billing";
import type { CancelSubscriptionResponse } from "./billing";
import type { CancelSubscriptionRequest } from "./billing";
import type { GetInvoicesResponse } from "./billing";
import type { GetInvoicesRequest } from "./billing";
import type { GetInvoicesUpcomingResponse } from "./billing";
import type { GetInvoicesUpcomingRequest } from "./billing";
import type { CreateSessionResponse } from "./billing";
import type { CreateSessionRequest } from "./billing";
import type { GetSubscriptionResponse } from "./billing";
import type { GetSubscriptionRequest } from "./billing";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetConfigResponse } from "./billing";
import type { GetConfigRequest } from "./billing";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * BillingService service provides the ability to manage billing accounts.
 *
 * @generated from protobuf service polarsignals.billing.v1alpha1.BillingService
 */
export interface IBillingServiceClient {
    /**
     * GetConfig returns the billing configuration.
     *
     * @generated from protobuf rpc: GetConfig(polarsignals.billing.v1alpha1.GetConfigRequest) returns (polarsignals.billing.v1alpha1.GetConfigResponse);
     */
    getConfig(input: GetConfigRequest, options?: RpcOptions): UnaryCall<GetConfigRequest, GetConfigResponse>;
    /**
     * @generated from protobuf rpc: GetSubscription(polarsignals.billing.v1alpha1.GetSubscriptionRequest) returns (polarsignals.billing.v1alpha1.GetSubscriptionResponse);
     */
    getSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, GetSubscriptionResponse>;
    /**
     * CreateSession creates a new billing session.
     *
     * @generated from protobuf rpc: CreateSession(polarsignals.billing.v1alpha1.CreateSessionRequest) returns (polarsignals.billing.v1alpha1.CreateSessionResponse);
     */
    createSession(input: CreateSessionRequest, options?: RpcOptions): UnaryCall<CreateSessionRequest, CreateSessionResponse>;
    /**
     * GetInvoicesUpcoming returns the upcoming invoices.
     *
     * @generated from protobuf rpc: GetInvoicesUpcoming(polarsignals.billing.v1alpha1.GetInvoicesUpcomingRequest) returns (polarsignals.billing.v1alpha1.GetInvoicesUpcomingResponse);
     */
    getInvoicesUpcoming(input: GetInvoicesUpcomingRequest, options?: RpcOptions): UnaryCall<GetInvoicesUpcomingRequest, GetInvoicesUpcomingResponse>;
    /**
     * GetInvoices returns the invoices.
     *
     * @generated from protobuf rpc: GetInvoices(polarsignals.billing.v1alpha1.GetInvoicesRequest) returns (polarsignals.billing.v1alpha1.GetInvoicesResponse);
     */
    getInvoices(input: GetInvoicesRequest, options?: RpcOptions): UnaryCall<GetInvoicesRequest, GetInvoicesResponse>;
    /**
     * CancelSubscription cancels the subscription.
     *
     * @generated from protobuf rpc: CancelSubscription(polarsignals.billing.v1alpha1.CancelSubscriptionRequest) returns (polarsignals.billing.v1alpha1.CancelSubscriptionResponse);
     */
    cancelSubscription(input: CancelSubscriptionRequest, options?: RpcOptions): UnaryCall<CancelSubscriptionRequest, CancelSubscriptionResponse>;
    /**
     * @generated from protobuf rpc: GetCustomer(polarsignals.billing.v1alpha1.GetCustomerRequest) returns (polarsignals.billing.v1alpha1.GetCustomerResponse);
     */
    getCustomer(input: GetCustomerRequest, options?: RpcOptions): UnaryCall<GetCustomerRequest, GetCustomerResponse>;
    /**
     * @generated from protobuf rpc: UpdateCustomer(polarsignals.billing.v1alpha1.UpdateCustomerRequest) returns (polarsignals.billing.v1alpha1.UpdateCustomerResponse);
     */
    updateCustomer(input: UpdateCustomerRequest, options?: RpcOptions): UnaryCall<UpdateCustomerRequest, UpdateCustomerResponse>;
    /**
     * GetCurrentUsage returns the current billing period's data usage metrics.
     *
     * @generated from protobuf rpc: GetCurrentUsage(polarsignals.billing.v1alpha1.GetCurrentUsageRequest) returns (polarsignals.billing.v1alpha1.GetCurrentUsageResponse);
     */
    getCurrentUsage(input: GetCurrentUsageRequest, options?: RpcOptions): UnaryCall<GetCurrentUsageRequest, GetCurrentUsageResponse>;
}
/**
 * BillingService service provides the ability to manage billing accounts.
 *
 * @generated from protobuf service polarsignals.billing.v1alpha1.BillingService
 */
export class BillingServiceClient implements IBillingServiceClient, ServiceInfo {
    typeName = BillingService.typeName;
    methods = BillingService.methods;
    options = BillingService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetConfig returns the billing configuration.
     *
     * @generated from protobuf rpc: GetConfig(polarsignals.billing.v1alpha1.GetConfigRequest) returns (polarsignals.billing.v1alpha1.GetConfigResponse);
     */
    getConfig(input: GetConfigRequest, options?: RpcOptions): UnaryCall<GetConfigRequest, GetConfigResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetConfigRequest, GetConfigResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSubscription(polarsignals.billing.v1alpha1.GetSubscriptionRequest) returns (polarsignals.billing.v1alpha1.GetSubscriptionResponse);
     */
    getSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, GetSubscriptionResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionRequest, GetSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateSession creates a new billing session.
     *
     * @generated from protobuf rpc: CreateSession(polarsignals.billing.v1alpha1.CreateSessionRequest) returns (polarsignals.billing.v1alpha1.CreateSessionResponse);
     */
    createSession(input: CreateSessionRequest, options?: RpcOptions): UnaryCall<CreateSessionRequest, CreateSessionResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateSessionRequest, CreateSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetInvoicesUpcoming returns the upcoming invoices.
     *
     * @generated from protobuf rpc: GetInvoicesUpcoming(polarsignals.billing.v1alpha1.GetInvoicesUpcomingRequest) returns (polarsignals.billing.v1alpha1.GetInvoicesUpcomingResponse);
     */
    getInvoicesUpcoming(input: GetInvoicesUpcomingRequest, options?: RpcOptions): UnaryCall<GetInvoicesUpcomingRequest, GetInvoicesUpcomingResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInvoicesUpcomingRequest, GetInvoicesUpcomingResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetInvoices returns the invoices.
     *
     * @generated from protobuf rpc: GetInvoices(polarsignals.billing.v1alpha1.GetInvoicesRequest) returns (polarsignals.billing.v1alpha1.GetInvoicesResponse);
     */
    getInvoices(input: GetInvoicesRequest, options?: RpcOptions): UnaryCall<GetInvoicesRequest, GetInvoicesResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInvoicesRequest, GetInvoicesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CancelSubscription cancels the subscription.
     *
     * @generated from protobuf rpc: CancelSubscription(polarsignals.billing.v1alpha1.CancelSubscriptionRequest) returns (polarsignals.billing.v1alpha1.CancelSubscriptionResponse);
     */
    cancelSubscription(input: CancelSubscriptionRequest, options?: RpcOptions): UnaryCall<CancelSubscriptionRequest, CancelSubscriptionResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelSubscriptionRequest, CancelSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomer(polarsignals.billing.v1alpha1.GetCustomerRequest) returns (polarsignals.billing.v1alpha1.GetCustomerResponse);
     */
    getCustomer(input: GetCustomerRequest, options?: RpcOptions): UnaryCall<GetCustomerRequest, GetCustomerResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerRequest, GetCustomerResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCustomer(polarsignals.billing.v1alpha1.UpdateCustomerRequest) returns (polarsignals.billing.v1alpha1.UpdateCustomerResponse);
     */
    updateCustomer(input: UpdateCustomerRequest, options?: RpcOptions): UnaryCall<UpdateCustomerRequest, UpdateCustomerResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCustomerRequest, UpdateCustomerResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetCurrentUsage returns the current billing period's data usage metrics.
     *
     * @generated from protobuf rpc: GetCurrentUsage(polarsignals.billing.v1alpha1.GetCurrentUsageRequest) returns (polarsignals.billing.v1alpha1.GetCurrentUsageResponse);
     */
    getCurrentUsage(input: GetCurrentUsageRequest, options?: RpcOptions): UnaryCall<GetCurrentUsageRequest, GetCurrentUsageResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCurrentUsageRequest, GetCurrentUsageResponse>("unary", this._transport, method, opt, input);
    }
}
