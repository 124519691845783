import {useCallback, useContext} from 'react';

import {Organization} from '@polarsignals/client-grpc-web/polarsignals/organization/v1alpha1/organization';
import {User} from '@polarsignals/client-grpc-web/polarsignals/user/v1alpha1/user';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';

import GrpcContext from 'contexts/GrpcContext';

import useProjects from '../useProjects';

export const useOrganizationUsers = (id: string) => {
  const {organizationServiceClient} = useContext(GrpcContext);

  const {
    data: organizationUsers,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<User[]>({
    key: ['getOrganizationUsers', id],
    queryFn: async () => {
      const {response} = await organizationServiceClient.getOrganizationUsers({id: id});
      return response.users ?? [];
    },
  });

  const addOrganizationUser = async (id: string, email: string) => {
    try {
      await organizationServiceClient.addOrganizationUser({id: id, email: email});
      Toast('success', `${email} was added successfully`);
      refetch();
    } catch (err) {
      console.error(err);
      Toast('error', `Error adding the new user, please try again: ${err.message}`);
    }
  };

  const deleteOrganizationUser = (id: string, userId: string) => {
    const call = organizationServiceClient.removeOrganizationUser({id: id, userId: userId});
    call.response
      .then(() => {
        Toast('success', 'User was removed successfully');
        refetch();
      })
      .catch(err => {
        console.error(err);
        Toast('error', `Error removing the user, please try again: ${err.message}`);
      });
  };

  const updateUserRole = async (userId: string, role: string) => {
    try {
      await organizationServiceClient.updateUserRole({
        organizationId: id,
        userId: userId,
        role: role,
      });
      Toast('success', 'User role was updated successfully');
      refetch();
    } catch (err) {
      console.error(err);
      Toast('error', `Error updating the user's role, please try again: ${err.message}`);
    }
  };

  return {
    data: {organizationUsers},
    loading: isLoading,
    error,
    mutations: {
      addOrganizationUser,
      deleteOrganizationUser,
      updateUserRole,
    },
  };
};

const useOrganizations = (id?: string) => {
  const {organizationServiceClient} = useContext(GrpcContext);
  const projects = useProjects();

  const {
    data: organization,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<Organization | undefined>({
    key: ['getOrganization', id],
    queryFn: async () => {
      if (id == null) {
        return undefined;
      }
      const {response} = await organizationServiceClient.getOrganization({id: id ?? ''});
      return response.organization;
    },
    options: {
      enabled: id != null && id !== '',
    },
  });

  const addOrganization = async (name: string) => {
    try {
      const {response} = await organizationServiceClient.createOrganization({name: name});
      Toast('success', `${name} was created successfully`);
      refetch();
      projects.refetch();
      return response.id;
    } catch (err: any) {
      console.error(err);
      Toast('error', `Error creating the organization, please try again: ${err.message}`);
    }
  };

  const updateOrganization = (id: string, name: string) => {
    const call = organizationServiceClient.updateOrganization({id: id, name: name});
    call.response
      .then(() => {
        Toast('success', 'Organization name was updated successfully');
        refetch();
        projects.refetch();
      })
      .catch(err => {
        console.error(err);
        Toast('error', `Error updating the organization's name, please try again: ${err.message}`);
      });
  };

  const activateFreeTrial = async (id: string) => {
    try {
      await organizationServiceClient.activateFreeTrial({id: id});
    } catch (err) {
      console.error(err);
    }
  };

  const deleteOrganization = async (id: string) => {
    try {
      await organizationServiceClient.deleteOrganization({id: id});
      await Promise.all([refetch(), projects.refetch()]);
    } catch (err) {
      console.error(err);
    }
  };

  const getInviteTokens = useCallback(async () => {
    if (!id) {
      return [];
    }
    const {response} = await organizationServiceClient.getInviteTokens({id});
    return response.inviteTokens ?? [];
  }, [organizationServiceClient, id]);

  const inviteUsersViaEmail = useCallback(
    async (id: string, emails: string[]) => {
      if (!id) {
        return;
      }
      await organizationServiceClient.inviteUsersViaEmail({id, emails});
    },
    [organizationServiceClient]
  );

  return {
    data: {organization},
    loading: isLoading,
    error,
    mutations: {
      updateOrganization,
      deleteOrganization,
      addOrganization,
      getInviteTokens,
      activateFreeTrial,
      inviteUsersViaEmail,
    },
  };
};

export default useOrganizations;
