import {useCallback, useContext, useEffect, useMemo} from 'react';

import {View} from '@polarsignals/client-grpc-web/polarsignals/view/v1alpha1/view';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';

import GrpcContext from 'contexts/GrpcContext';
import useActiveProject from 'hooks/data/useActiveProject';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {setActiveViewSlug, selectActiveViewSlug} from 'store/slices/viewSlice';

const slugify = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
};

const useViews = () => {
  const {viewServiceClient} = useContext(GrpcContext);
  const dispatch = useAppDispatch();
  const activeViewSlug = useAppSelector(selectActiveViewSlug);
  const {data} = useActiveProject();

  const activeProjectId = data.activeProject?.id;

  const {
    data: views,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<View[] | undefined>({
    key: ['getViews'],
    queryFn: async () => {
      try {
        if (!activeProjectId) {
          return [];
        }
        const {response} = await viewServiceClient.getViews({
          projectId: activeProjectId,
        });
        return response?.views || [];
      } catch (e) {
        console.error('Error getting the views', e);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [activeProjectId, refetch]);

  const addView = async (view: {name: string; query: string; description: string}) => {
    try {
      const {response} = await viewServiceClient.createView({
        projectId: activeProjectId as string,
        name: view.name,
        query: view.query,
        slug: slugify(view.name),
        description: view.description,
      });
      Toast('success', `View was created successfully`);
      await refetch();
      setActiveView(response?.slug);
      return {slug: response?.slug, projectId: activeProjectId as string};
    } catch (e) {
      Toast('error', 'Error creating the new view, please try again.');
      console.error('Error creating the new view', e);
    }
  };

  const deleteView = async (slug: string) => {
    try {
      await viewServiceClient.deleteView({slug, projectId: activeProjectId as string});
      Toast('success', `View deleted successfully`);
      await refetch();
      return {};
    } catch (e) {
      Toast('error', 'Error deleting the view, please try again.');
      console.error('Error deleting the view', e);
    }
  };

  const updateView = async (view: {name: string; query: string; description: string}) => {
    try {
      const {response} = await viewServiceClient.updateView({
        id: views?.find(view => view.slug === activeViewSlug)?.id as string,
        name: view.name,
        query: view.query,
        slug: slugify(view.name),
        description: view.description,
        projectId: activeProjectId as string,
      });
      await refetch();
      Toast('success', `View updated successfully`);
      return response.view;
    } catch (e) {
      Toast('error', 'Error updating the view, please try again.');
      console.error('Error updating the view', e);
    }
  };

  const activeView = useMemo(() => {
    if (!activeViewSlug) {
      return;
    }

    return views?.find(view => view.slug === activeViewSlug);
  }, [activeViewSlug, views]);

  const setActiveView = useCallback(
    async (viewSlug: string | undefined) => {
      if (!viewSlug) {
        dispatch(setActiveViewSlug(undefined));
        return;
      }
      dispatch(setActiveViewSlug(viewSlug));
    },
    [dispatch]
  );

  const getViewsForProject = async (projectId: string) => {
    try {
      const {response} = await viewServiceClient.getViews({projectId: projectId});
      return response?.views || [];
    } catch (e) {
      console.error('Error getting the views', e);
    }
  };

  return {
    data: {views, activeView},
    loading: isLoading,
    error,
    refetch,
    mutations: {
      addView,
      deleteView,
      updateView,
      setActiveView,
      getViewsForProject,
    },
  };
};

export const useGetIndividualView = (slug: string, projectId: string, viewUpdated?: boolean) => {
  const {viewServiceClient} = useContext(GrpcContext);

  const {
    data: view,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<View | undefined>({
    key: ['getView, slug, projectId'],
    queryFn: async () => {
      try {
        if (!projectId && !slug) {
          return;
        }
        const {response} = await viewServiceClient.getView({
          slug,
          projectId: projectId,
        });

        return response?.view;
      } catch (e) {
        console.error('Error getting the views', e);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [slug, projectId, refetch, viewUpdated]);

  return {
    data: view,
    loading: isLoading,
    error,
    refetch,
  };
};

export default useViews;
