import {useEffect, useState} from 'react';

import {Button} from '@parca/components';
import cx from 'classnames';

const loadTime = new Date().getTime();
let lastCheckTs = loadTime;

export const StaleDeploymentPrompt = () => {
  const [canCheck, setCanCheck] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handle = setInterval(() => {
      const now = new Date().getTime();
      // Check every 2 minutes after 10 minutes of load time
      if (!canCheck || now - lastCheckTs < 2 * 60 * 1000 || now - loadTime < 10 * 60 * 1000) {
        return;
      }
      lastCheckTs = now;
      fetch(`/api/current-deployment/?ts=${lastCheckTs}`)
        .then(async res => await res.json())
        .then(data => {
          if (data.sha != null && data.sha !== process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA) {
            setShow(true);
          }
        });
    }, 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  return (
    <div
      className={cx(
        'fixed bottom-10 right-10 z-[2000] flex w-96 flex-col gap-6 rounded border border-gray-200 bg-white p-7 dark:border-gray-700 dark:bg-gray-900',
        {hidden: !show}
      )}
    >
      <p>
        There is a new version of Polar Signals Cloud available. Please reload the page to get the
        latest version.
      </p>
      <div className="flex justify-between">
        <Button
          onClick={() => {
            setShow(false);
            setCanCheck(false);
          }}
          variant="neutral"
        >
          Ignore
        </Button>
        <Button onClick={() => window.location.reload()}>Reload</Button>{' '}
      </div>
    </div>
  );
};
