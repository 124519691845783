import {Fragment, useState} from 'react';

import {Menu, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';

import {ICONS} from '../../../constants';

import AddOrganizationModal from './Modals/AddOrganizationModal';
import AddProjectModal from './Modals/AddProjectModal';

const NavbarActions = () => {
  const [isAddOrganizationModalOpen, setIsAddOrganizationModalOpen] = useState<boolean>(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState<boolean>(false);

  return (
    <>
      <Menu as="div" className="relative flex text-left">
        <Menu.Button className="flex items-center justify-center gap-x-[3px]">
          <Icon icon={ICONS.plus} width={24} height={24} />
          <Icon icon="tabler:caret-down-filled" width={16} height={16} />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-10 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700">
            <div className="px-1 py-1">
              <Menu.Item>
                {({active}) => (
                  <button
                    className={`${
                      active
                        ? 'bg-indigo-500 text-white dark:text-white'
                        : 'text-gray-900 dark:text-gray-300'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                    onClick={() => {
                      setIsAddOrganizationModalOpen(true);
                    }}
                  >
                    New Organization
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({active}) => (
                  <button
                    className={`${
                      active
                        ? 'bg-indigo-500 text-white dark:text-white'
                        : 'text-gray-900 dark:text-gray-300'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                    onClick={() => {
                      setIsAddProjectModalOpen(true);
                    }}
                  >
                    New Project
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <AddOrganizationModal
        isModalOpen={isAddOrganizationModalOpen}
        closeModal={() => {
          setIsAddOrganizationModalOpen(false);
        }}
      />

      <AddProjectModal
        isModalOpen={isAddProjectModalOpen}
        closeModal={() => {
          setIsAddProjectModalOpen(false);
        }}
      />
    </>
  );
};

export default NavbarActions;
