import {useEffect} from 'react';

import {Icon} from '@iconify/react';

declare global {
  interface Window {
    Headway: any;
  }
}

const HeadwayWidget = () => {
  const scriptId = 'headway_widget';

  const loadHeadwayWidget = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    script.id = scriptId;
    document.body.appendChild(script);

    const config = {
      selector: '#headway-badge',
      account: 'JRqzL7',
    };

    script.onload = function () {
      window.Headway.init(config);
    };
  };

  useEffect(() => {
    loadHeadwayWidget();
  }, []);

  return (
    <div
      className="headway-badge relative flex h-8 w-8 items-center justify-center rounded-full border-[1px] border-gray-200 dark:border-gray-700"
      id="headway-badge"
    >
      <Icon icon="ic:round-notifications-none" width={16} height={16} />
    </div>
  );
};

export default HeadwayWidget;
