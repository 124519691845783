import Modal from 'components/Modal';

import AddProject from '../AddProject';

const AddProjectModal = ({
  isModalOpen,
  closeModal,
  preferredOrg,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  preferredOrg?: string;
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      closeModal={() => closeModal()}
      title="Add a New Project"
      className="w-[480px]"
    >
      <AddProject organizationIdFromQuery={preferredOrg} closeModal={closeModal} />
    </Modal>
  );
};

export default AddProjectModal;
