// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/admin/v1alpha1/admin.proto" (package "polarsignals.admin.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AdminService } from "./admin";
import type { UpdateOrganizationPOBillingResponse } from "./admin";
import type { UpdateOrganizationPOBillingRequest } from "./admin";
import type { GetAllOrganizationUsageDataResponse } from "./admin";
import type { GetAllOrganizationUsageDataRequest } from "./admin";
import type { UpdateOrganizationTrialEndResponse } from "./admin";
import type { UpdateOrganizationTrialEndRequest } from "./admin";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { UpdateOrganizationPriceIDResponse } from "./admin";
import type { UpdateOrganizationPriceIDRequest } from "./admin";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * AdminService is the service that provides admin functionality.
 *
 * @generated from protobuf service polarsignals.admin.v1alpha1.AdminService
 */
export interface IAdminServiceClient {
    /**
     * UpdateOrganizationPriceID updates the price id for an organization.
     *
     * @generated from protobuf rpc: UpdateOrganizationPriceID(polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDRequest) returns (polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDResponse);
     */
    updateOrganizationPriceID(input: UpdateOrganizationPriceIDRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationPriceIDRequest, UpdateOrganizationPriceIDResponse>;
    /**
     * UpdateOrganizationTrialEnd updates the trial end for an organization.
     *
     * @generated from protobuf rpc: UpdateOrganizationTrialEnd(polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndRequest) returns (polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndResponse);
     */
    updateOrganizationTrialEnd(input: UpdateOrganizationTrialEndRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationTrialEndRequest, UpdateOrganizationTrialEndResponse>;
    /**
     * GetAllOrganizationUsageData gets the usage data for all organizations.
     *
     * @generated from protobuf rpc: GetAllOrganizationUsageData(polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataRequest) returns (polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataResponse);
     */
    getAllOrganizationUsageData(input: GetAllOrganizationUsageDataRequest, options?: RpcOptions): UnaryCall<GetAllOrganizationUsageDataRequest, GetAllOrganizationUsageDataResponse>;
    /**
     * UpdateOrganizationPOBilling updates the PO Billing flag for an organization.
     *
     * @generated from protobuf rpc: UpdateOrganizationPOBilling(polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingRequest) returns (polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingResponse);
     */
    updateOrganizationPOBilling(input: UpdateOrganizationPOBillingRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationPOBillingRequest, UpdateOrganizationPOBillingResponse>;
}
/**
 * AdminService is the service that provides admin functionality.
 *
 * @generated from protobuf service polarsignals.admin.v1alpha1.AdminService
 */
export class AdminServiceClient implements IAdminServiceClient, ServiceInfo {
    typeName = AdminService.typeName;
    methods = AdminService.methods;
    options = AdminService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * UpdateOrganizationPriceID updates the price id for an organization.
     *
     * @generated from protobuf rpc: UpdateOrganizationPriceID(polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDRequest) returns (polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDResponse);
     */
    updateOrganizationPriceID(input: UpdateOrganizationPriceIDRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationPriceIDRequest, UpdateOrganizationPriceIDResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateOrganizationPriceIDRequest, UpdateOrganizationPriceIDResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateOrganizationTrialEnd updates the trial end for an organization.
     *
     * @generated from protobuf rpc: UpdateOrganizationTrialEnd(polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndRequest) returns (polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndResponse);
     */
    updateOrganizationTrialEnd(input: UpdateOrganizationTrialEndRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationTrialEndRequest, UpdateOrganizationTrialEndResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateOrganizationTrialEndRequest, UpdateOrganizationTrialEndResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetAllOrganizationUsageData gets the usage data for all organizations.
     *
     * @generated from protobuf rpc: GetAllOrganizationUsageData(polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataRequest) returns (polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataResponse);
     */
    getAllOrganizationUsageData(input: GetAllOrganizationUsageDataRequest, options?: RpcOptions): UnaryCall<GetAllOrganizationUsageDataRequest, GetAllOrganizationUsageDataResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllOrganizationUsageDataRequest, GetAllOrganizationUsageDataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateOrganizationPOBilling updates the PO Billing flag for an organization.
     *
     * @generated from protobuf rpc: UpdateOrganizationPOBilling(polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingRequest) returns (polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingResponse);
     */
    updateOrganizationPOBilling(input: UpdateOrganizationPOBillingRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationPOBillingRequest, UpdateOrganizationPOBillingResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateOrganizationPOBillingRequest, UpdateOrganizationPOBillingResponse>("unary", this._transport, method, opt, input);
    }
}
