import {addIcon} from '@iconify/react';

export const initIconify = () => {
  addIcon('ps:hint-bulb', {
    body: `<g fill="none">
          <path d="M27.2188 33.75V31.6407C27.2188 29.0918 29.9909 26.681 31.7891 24.961C34.3239 22.5378 35.6563 19.2824 35.6563 15.4688C35.6563 8.43755 30.055 2.81255 23 2.81255C21.3367 2.80791 19.6888 3.13211 18.1512 3.7665C16.6136 4.40089 15.2165 5.33297 14.0404 6.50913C12.8642 7.6853 11.9321 9.08236 11.2977 10.62C10.6634 12.1576 10.3392 13.8055 10.3438 15.4688C10.3438 19.1461 11.7333 22.6222 14.211 24.961C15.9996 26.6494 18.7813 29.0655 18.7813 31.6407V33.75M20.1875 42.1875H25.8125M18.7813 37.9688H27.2188M23 33.75V22.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M26.3398 21.0938C26.3398 21.0938 24.4493 22.5 23 22.5C21.5507 22.5 19.6602 21.0938 19.6602 21.0938" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>`,
    width: 46,
    height: 45,
  });
};
