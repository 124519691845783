import {useContext} from 'react';

import {
  GetSubscriptionResponse,
  GetConfigResponse,
  GetCustomerResponse,
  GetInvoicesUpcomingResponse,
  GetCurrentUsageResponse,
  GetInvoicesResponse,
} from '@polarsignals/client-grpc-web/polarsignals/billing/v1alpha1/billing';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';
import {useStripe} from '@stripe/react-stripe-js';

import GrpcContext from 'contexts/GrpcContext';

export type RedirectTo = 'organizations' | 'onboarding';

const useBilling = (organizationId: string) => {
  const {billingServiceClient} = useContext(GrpcContext);
  const stripe = useStripe();

  const {
    data: subscription,
    error: subscriptionError,
    isLoading: subscriptionLoading,
    refetch,
  } = useGrpcQuery<GetSubscriptionResponse | null>({
    key: ['getSubscription', organizationId],
    queryFn: async () => {
      try {
        const {response} = await billingServiceClient.getSubscription({
          organizationId: organizationId,
        });
        return response;
      } catch (err) {
        if (err.code === 'NOT_FOUND') {
          return null;
        }
        console.error('Error fetching subscription', err);
        throw err;
      }
    },
    options: {
      enabled: organizationId != null && organizationId !== '',
      retry: false,
    },
  });

  const {
    data: customer,
    error: customerError,
    isLoading: customerLoading,
  } = useGrpcQuery<GetCustomerResponse>({
    key: ['getCustomer', organizationId],
    queryFn: async () => {
      const {response} = await billingServiceClient.getCustomer({
        organizationId: organizationId,
      });

      return response;
    },
    options: {
      enabled: organizationId != null && organizationId !== '',
    },
  });

  const addPaymentMethod = async (organizationId: string, redirectTo: RedirectTo) => {
    if (stripe == null) {
      return;
    }

    try {
      const {response} = await billingServiceClient.createSession({
        organizationId: organizationId,
        priceId: '', // ignored for now
        redirectTo,
      });

      if (response.sessionId === undefined) {
        return;
      }

      await stripe
        .redirectToCheckout({
          sessionId: response.sessionId,
        })
        .catch(err => console.log('failed to create billing session', err));
    } catch (err) {
      Toast('error', `Error adding payment method, please try again: ${err.message}`);
    }
  };

  const cancelSubscription = async (organizationId: string) => {
    try {
      await billingServiceClient.cancelSubscription({
        organizationId: organizationId,
      });
      await refetch();
      Toast('success', 'Subscription cancelled successfully');
    } catch (err) {
      Toast('error', `Error cancelling subscription, please try again: ${err.message}`);
    }
  };

  const updateCustomer = async (organizationID: string, email: string, name: string) => {
    try {
      await billingServiceClient.updateCustomer({
        organizationId: organizationID,
        customerEmail: email,
        customerName: name,
      });
      await refetch();
      Toast('success', 'Customer updated successfully');
    } catch (err) {
      console.error(err);
      Toast('error', `Error updating the customer, please try again: ${err.message}`);
    }
  };

  return {
    subscription,
    customer,
    subscriptionLoading,
    customerLoading,
    subscriptionError,
    customerError,
    mutations: {
      addPaymentMethod,
      cancelSubscription,
      updateCustomer,
    },
  };
};

export const useBillingConfig = (organizationId: string) => {
  const {billingServiceClient} = useContext(GrpcContext);

  const {
    data: config,
    error,
    isLoading: loading,
  } = useGrpcQuery<GetConfigResponse | undefined>({
    key: ['getBillingConfig', organizationId],
    queryFn: async () => {
      const {response} = await billingServiceClient.getConfig({
        organizationId: organizationId,
      });

      return response;
    },
  });

  return {
    config,
    loading,
    error,
    mutations: {},
  };
};

export const useBillingInvoices = (organizationId: string) => {
  const {billingServiceClient} = useContext(GrpcContext);

  const {
    data,
    error,
    isLoading: loading,
  } = useGrpcQuery<GetInvoicesResponse | undefined>({
    key: ['getInvoices', organizationId],
    queryFn: async () => {
      const {response} = await billingServiceClient.getInvoices({
        organizationId: organizationId,
      });

      return response;
    },
  });

  return {
    invoices: data?.invoices,
    loading,
    error,
  };
};

export const useBillingUpcomingInvoice = (organizationId: string) => {
  const {billingServiceClient} = useContext(GrpcContext);

  const {
    data: upcomingInvoice,
    error,
    isLoading: loading,
  } = useGrpcQuery<GetInvoicesUpcomingResponse | undefined>({
    key: ['getUpcomingInvoice', organizationId],
    queryFn: async () => {
      const {response} = await billingServiceClient.getInvoicesUpcoming({
        organizationId: organizationId,
      });

      return response;
    },
  });

  return {
    upcomingInvoice,
    loading,
    error,
    mutations: {},
  };
};

export const useBillingCurrentUsage = (organizationId: string) => {
  const {billingServiceClient} = useContext(GrpcContext);

  const {
    data,
    error,
    isLoading: loading,
  } = useGrpcQuery<GetCurrentUsageResponse | undefined>({
    key: ['getCurrentUsage', organizationId],
    queryFn: async () => {
      const {response} = await billingServiceClient.getCurrentUsage({
        organizationId: organizationId,
      });

      return response;
    },
  });

  return {
    currentUsage: data?.currentUsage,
    loading,
    error,
    mutations: {},
  };
};

export default useBilling;
