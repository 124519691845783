/* eslint-disable @typescript-eslint/no-misused-promises */
import {Stripe, loadStripe} from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

const getStripe = async (stripeKey: string) => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKey);
  }
  return await stripePromise;
};

export default getStripe;
