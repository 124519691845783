/** @jsxImportSource theme-ui */

import {Icon} from '@iconify/react';
import {UserPreferences} from '@parca/components';
import {PolarSignalsFull} from '@polarsignals/icons';
import Link from 'next/link';

import useCurrentUser, {isAdminUser} from 'hooks/data/useCurrentUser';

import {ICONS} from '../../constants';

import ColorModeToggle from './ColorModeToggle';
import NavbarActions from './NavbarActions';
import ProfileInitials from './ProfileInitials';

const NavbarAdmin = () => {
  const {
    data: {user},
  } = useCurrentUser();

  const isAdmin = isAdminUser(user);

  return (
    <div
      sx={{borderBottom: '1px solid', borderColor: 'border'}}
      className="grid-cols-navbar grid items-center gap-4 p-4"
    >
      <div className="flex justify-center">
        <Link href="/">
          <PolarSignalsFull width={200} />
        </Link>
      </div>
      <div className="ml-[-14px] flex h-full items-end">
        <p className="text-xl">
          <Icon icon="material-symbols:admin-panel-settings-outline-rounded" />
        </p>
      </div>

      <div className="flex items-center justify-end gap-2">
        <ColorModeToggle />
        <UserPreferences />
        {isAdmin && (
          <Link href="/admin" className="p-1">
            <Icon icon={ICONS.admin} width={24} height={24} />
          </Link>
        )}
        <NavbarActions />
        <ProfileInitials />
      </div>
    </div>
  );
};

export default NavbarAdmin;
