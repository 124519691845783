/** @jsxImportSource theme-ui */
import {ParsedUrlQuery} from 'querystring';

import {Button} from '@parca/components';
import cx from 'classnames';
import {Grid} from 'theme-ui';

const apiEndpoint: string = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';
const loginEndpoint = `${apiEndpoint}/api/login`;

export const login = (connectorId: string, routerQuery: ParsedUrlQuery) => {
  if (routerQuery.redirectTo === undefined) {
    window.location.replace(
      `${loginEndpoint}/?connector_id=${connectorId}&referer=${encodeURIComponent(
        window.location.href
      )}`
    );
  }

  if (routerQuery.redirectTo !== undefined) {
    const baseURL = routerQuery.redirectTo;

    const queryParams = Object.keys(routerQuery)
      .filter(key => key !== 'redirectTo')
      .map(key => `${key}=${routerQuery[key]}`)
      .join('&');

    const redirectURL = `${baseURL}&${queryParams}`;

    window.location.replace(
      `${loginEndpoint}/?connector_id=${connectorId}&referer=${redirectURL || window.location.href}`
    );
  }
};

export const LoginButton = ({
  onClick,
  icon,
  text,
  background,
  textColor,
  iconBackground,
  title,
  disabled = false,
}: {
  onClick: () => void;
  icon: React.ReactNode;
  text: string | React.ReactNode;
  background: string;
  textColor: string;
  iconBackground?: string;
  title?: string;
  disabled?: boolean;
}) => {
  return (
    <Button
      className={cx('flex h-12 items-center gap-4 rounded-md px-12', background, textColor)}
      onClick={onClick}
      title={title}
      variant="neutral"
      disabled={disabled}
    >
      <Grid
        sx={{
          background: iconBackground,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="aspect-square rounded-tl-md rounded-bl-md"
      >
        {icon}
      </Grid>
      <p sx={{flex: '1', color: textColor}}>{text}</p>
    </Button>
  );
};
