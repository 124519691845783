import {useMemo} from 'react';

import {Button} from '@parca/components';
import {useRouter} from 'next/router';
import {TooltipRenderProps} from 'react-joyride';

import useCurrentUser from 'hooks/data/useCurrentUser';

import ComparingTour from './ComparingTour';
import IcicleGraphTour from './IcicleGraphTour';
import InitialTour from './InitialTour';

export function Tooltip({
  backProps,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  size,
}: TooltipRenderProps) {
  return (
    <div {...tooltipProps} className="rounded-lg bg-white dark:bg-gray-700 dark:text-white">
      <div className="p-4">
        {step.title && <div>{step.title}</div>}
        {step.content && <div>{step.content}</div>}
      </div>
      <div className="flex items-center justify-between rounded-b-lg bg-gray-50 px-6 py-3 dark:bg-gray-800 dark:text-white">
        {!isLastStep && (
          <Button variant="neutral" {...skipProps}>
            Skip
          </Button>
        )}
        <div className="text-sm text-gray-500">
          {index + 1} of {size}
        </div>
        <div className="flex items-center justify-center gap-3">
          {index > 0 && (
            <div className="text-indigo-600" {...backProps}>
              Back
            </div>
          )}
          <Button {...primaryProps}>{isLastStep ? 'Finish' : 'Next'} </Button>
        </div>
      </div>
    </div>
  );
}

const ProductTour = () => {
  const {data: user, mutations} = useCurrentUser();
  const router = useRouter();

  const icicleGraphElementIsPresent = useMemo(() => {
    if (!document) return false;

    return !!document.getElementById('h-icicle-graph');
  }, [document.getElementById('h-icicle-graph')]);

  const userIsOnComparingProfilesPage = !!(router.query.compare_a && router.query.compare_b);

  const userHasSentDataAndOnProfilerPage =
    !!(router.pathname === '/projects/[project-id]') && icicleGraphElementIsPresent;

  const updateUserProductTour = (tourName: string) => {
    mutations.updateUserProductTours(tourName);
  };

  return (
    <>
      <InitialTour
        hasUserSentData={userHasSentDataAndOnProfilerPage}
        productTour={user.user?.productTours}
        updateUserProductTour={updateUserProductTour}
        userIsOnComparingProfilesPage={userIsOnComparingProfilesPage}
      />
      <IcicleGraphTour
        hasUserSentData={userHasSentDataAndOnProfilerPage}
        productTour={user.user?.productTours}
        updateUserProductTour={updateUserProductTour}
        userIsOnComparingProfilesPage={userIsOnComparingProfilesPage}
      />
      <ComparingTour
        hasUserSentData={userHasSentDataAndOnProfilerPage}
        productTour={user.user?.productTours}
        updateUserProductTour={updateUserProductTour}
        userIsOnComparingProfilesPage={userIsOnComparingProfilesPage}
      />
    </>
  );
};

export default ProductTour;
