import cx from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';

const ProjectSubMenu = ({projectId, enableLogs}: {projectId: string; enableLogs: boolean}) => {
  const router = useRouter();

  const isProjectProfilerPage =
    !router.pathname.includes('settings') && !router.pathname.includes('logs');
  const isProjectLogsPage = router.pathname.includes('logs');
  const isProjectSettingsPage = router.pathname.includes('settings');

  return (
    <div className="flex gap-4 px-4 text-sm">
      <Link
        href="/"
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isProjectProfilerPage && 'border-b-2 border-blue-600'
        )}
      >
        Profiler
      </Link>
      {enableLogs && (
        <Link
          href={`/projects/${projectId}/logs`}
          className={cx(
            'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
            isProjectLogsPage && 'border-b-2 border-blue-600'
          )}
        >
          Logs
        </Link>
      )}
      <Link
        href={`/projects/${projectId}/settings`}
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isProjectSettingsPage && 'border-b-2 border-blue-600'
        )}
      >
        Settings
      </Link>
    </div>
  );
};

export default ProjectSubMenu;
