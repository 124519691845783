/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';

import {useAppSelector, useAppDispatch} from 'store/hooks';
import {setSidebarCollapsed, selectSidebarCollapsed} from 'store/slices/uiSlice';

import {ICONS} from '../../../constants';

const CollapseSidebarIcon = () => {
  const dispatch = useAppDispatch();
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  const collapseSidebar = () => {
    dispatch(setSidebarCollapsed(!isSidebarCollapsed));
  };

  return (
    <div className="fixed bottom-4 mt-auto p-4">
      <div
        className="w-fit cursor-pointer rounded bg-gray-100 p-1 dark:bg-gray-800"
        onClick={collapseSidebar}
        title={isSidebarCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
      >
        {isSidebarCollapsed ? (
          <Icon icon={ICONS.sidebarMenuClosed} className="h-6 w-6" />
        ) : (
          <Icon icon={ICONS.sidebarMenuOpen} className="h-6 w-6" />
        )}
      </div>
    </div>
  );
};

export default CollapseSidebarIcon;
