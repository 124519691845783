import {Spinner} from '@polarsignals/components';

import {useCurrentUserProjectAccess} from 'hooks/data/useCurrentUser/access';

interface Props {
  projectId: string;
  accessLevel: 'read' | 'write';
  children: React.ReactNode;
}

export const ProjectAccessControl = ({projectId, accessLevel, children}: Props) => {
  const {data, loading} = useCurrentUserProjectAccess(projectId);

  if (loading) {
    return <Spinner />;
  }

  if (accessLevel === 'read' && data?.hasReadAccess) {
    return <>{children}</>;
  }

  if (accessLevel === 'write' && data?.hasWriteAccess) {
    return <>{children}</>;
  }

  return null;
};
