import tailwind, {colors} from '@theme-ui/preset-tailwind';
import {dark} from '@theme-ui/presets';

const {gray, blue, indigo, red} = colors;

export default {
  ...tailwind,
  colors: {
    ...tailwind.colors,
    background: gray[1],
    backgroundSolid: '#fff',
    text: gray[8],
    textSoft: gray[6],
    textSofter: gray[6],
    primary: indigo[7],
    soft: gray[2],
    primaryHover: indigo[8],
    border: gray[3],
    googleButton: '#fff',
    link: blue[6],
    modes: {
      dark: {
        ...dark.colors,
        text: gray[2],
        textSoft: gray[5],
        textSofter: gray[5],
        background: gray[9],
        backgroundSolid: '#000',
        primary: indigo[3],
        soft: gray[8],
        primaryHover: indigo[2],
        border: gray[8],
        googleButton: '#4285F4',
      },
    },
  },
  links: {
    basic: {
      textDecoration: 'none',
      ':hover': {
        color: 'textHover',
        fontWeight: 'bold',
      },
    },
    active: {
      fontWeight: 'bold',
      textDecoration: 'none',
      color: 'primary',
      ':hover': {
        color: 'primaryHover',
        fontWeight: 'bold',
      },
    },
  },
  text: {
    large: {
      fontSize: 32,
      fontWeight: 300,
    },
  },
  forms: {
    switch: {
      background: 'soft',
      width: '2.5rem',
    },
  },
  layout: {
    container: {
      default: {
        color: 'text',
        background: 'soft',
        borderRadius: '0.5rem',
        borderWidth: 2,
        borderColor: 'background',
        marginBottom: 4,
      },
      solid: {
        color: 'text',
        background: 'backgroundSolid',
        borderRadius: '0.5rem',
        borderWidth: 2,
        borderColor: 'backgroundSolid',
        marginBottom: 4,
      },
      transparent: {
        color: 'text',
        background: 'background',
        marginBottom: 1,
      },
      danger: {
        marginBottom: 4,
        borderRadius: '0.5rem',
        borderWidth: 2,
        borderColor: red[2],
        color: red[9],
        background: red[1],
      },
    },
  },
  styles: {
    ...tailwind.styles,
  },
};
