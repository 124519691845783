import {useContext} from 'react';

import {Organization} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';

import GrpcContext from 'contexts/GrpcContext';

const useProjects = () => {
  const {projectServiceClient} = useContext(GrpcContext);

  const {
    data: organizations,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<Organization[]>({
    key: ['getProjects'],
    queryFn: async () => {
      const {response} = await projectServiceClient.getProjects({});
      return response?.organizations || [];
    },
  });

  const addProject = async (organizationId: string, name: string) => {
    try {
      const {response} = await projectServiceClient.createProject({organizationId, name});
      Toast('success', `${name} was created successfully`);
      await refetch();
      return response?.id;
    } catch (e) {
      Toast('error', 'Error creating the new project, please try again.');
      console.error('Error creating the new project', e);
    }
  };

  return {
    data: {organizations},
    loading: isLoading,
    error,
    refetch,
    mutations: {addProject},
  };
};

export default useProjects;
