export const apiEndpoint: string = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';

export const loginEndpoint = `${apiEndpoint}/api/login`;

export const logoutEndpoint = `${apiEndpoint}/api/logout`;

export const ICONS = {
  settings: 'heroicons-outline:cog',
  profiler: 'mdi:tune-vertical',
  help: 'ion:ios-help-buoy',
  documentation: 'carbon:document',
  admin: 'fa-solid:user-lock',
  error: 'ci:error-outline',
  plus: 'ic:baseline-plus',
  sidebarMenuOpen: 'ant-design:menu-fold-outlined',
  sidebarMenuClosed: 'ant-design:menu-unfold-outlined',
  userFlags: 'fa6-solid:user-shield',
  orgFlags: 'octicon:organization-16',
  barChart: 'material-symbols-light:bar-chart-4-bars-rounded',
  onboardingCaretDown: 'ph:caret-circle-down',
  onboardingCheckmark: 'carbon:checkmark-filled',
  onboardingCreateProject: 'octicon:organization-16',
  onboardingPaymentMethod: 'octicon:credit-card-16',
  onboardingInviteUsers: 'tabler:users-plus',
};
