import {useEffect, useContext, useCallback, useMemo} from 'react';

import {
  Organization,
  Token,
  Project,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';
import router from 'next/router';

import GrpcContext from 'contexts/GrpcContext';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {globalProjects, setActiveOrgId, setActiveProjectId} from 'store/slices/projectsSlice';
import {hasNoProjects} from 'utils/projects';

import useProjects from '../useProjects';

interface additionalData {
  organizationId?: string;
}

const findProject = (organizations: Organization[], projectId: string) => {
  for (const organization of organizations) {
    for (const project of organization.projects) {
      if (project.id === projectId) {
        return {...project, organizationId: organization.id};
      }
    }
  }
  return null;
};

type IProjectData = Project & additionalData;

const useActiveProject = () => {
  const {projectServiceClient} = useContext(GrpcContext);
  const {activeProjectId} = useAppSelector(globalProjects);
  const dispatch = useAppDispatch();

  const projects = useProjects();

  const activeProject: IProjectData | null = useMemo(() => {
    if (!activeProjectId) {
      return null;
    }
    const {
      data: {organizations},
    } = projects;

    const activeProject = findProject(organizations ?? [], activeProjectId) ?? {
      name: '',
      id: '',
      organizationId: '',
    };
    return activeProject;
  }, [activeProjectId, projects]);

  const setActiveProject = useCallback(
    async (orgId: string | undefined, projectId: string | undefined) => {
      dispatch(setActiveOrgId(orgId));
      dispatch(setActiveProjectId(projectId));
    },
    [dispatch]
  );

  const deleteProject = useCallback(() => {
    if (!activeProjectId) {
      return;
    }

    const call = projectServiceClient.deleteProject({id: activeProjectId});
    call.response
      .then(() => {
        Toast('success', 'Project deleted successfully');
        projects.refetch();
        router.push('/organizations');
      })
      .catch(err => {
        Toast('error', `Error deleting project, please try again: ${err.message}`);
      });
  }, [activeProjectId, projectServiceClient, projects]);

  const updateProjectName = useCallback(
    name => {
      if (!activeProjectId) {
        return;
      }
      const call = projectServiceClient.updateProject({
        id: activeProjectId,
        name: name,
      });

      call.response
        .then(() => {
          Toast('success', 'Project details updated successfully');
          projects.refetch();
        })
        .catch(err => {
          Toast('error', `Error updating project details, please try again: ${err.message}`);
        });
    },
    [activeProjectId, projects, projectServiceClient]
  );

  return {
    data: {activeProject},
    loading: projects.loading || (!activeProject && !hasNoProjects(projects.data?.organizations)),
    error: projects.error,
    mutations: {setActiveProject, deleteProject, updateProjectName},
  };
};

export default useActiveProject;
