import {isOSPrefersColorSchemeDark} from '@polarsignals/functions';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../index';

// Define a type for the slice state
interface UiState {
  darkMode: boolean;
  sidebarCollapsed: boolean;
}

// Define the initial state using that type
const initialState: UiState = {
  darkMode: isOSPrefersColorSchemeDark(),
  sidebarCollapsed: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload;
    },
  },
});

export const {setDarkMode, setSidebarCollapsed} = uiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectDarkMode = (state: RootState) => state.ui.darkMode;
export const selectSidebarCollapsed = (state: RootState) => state.ui.sidebarCollapsed;

export default uiSlice.reducer;
