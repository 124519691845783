/** @jsxImportSource theme-ui */
import React from 'react';

import cx from 'classnames';

import Footer from 'components/Footer';
import NavbarAdmin from 'components/Navbar/Admin';
import {AdminSidebar} from 'components/Sidebar/AdminSidebar';
import {useAppSelector} from 'store/hooks';
import {selectSidebarCollapsed} from 'store/slices/uiSlice';

export interface LayoutProps {
  children: React.ReactNode;
}

export const AdminLayout: React.FC<LayoutProps> = ({children}) => {
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  return (
    <main
      className={cx(
        'grid-areas-layoutAdmin grid-cols-layoutAdmin grid-rows-layoutAdmin grid h-screen min-h-full gap-x-2 gap-y-[0.1rem] overflow-auto',
        isSidebarCollapsed ? 'grid-cols-layoutAdminCollapsed' : 'grid-cols-layoutAdmin'
      )}
    >
      <nav className="grid-in-navbar">
        <NavbarAdmin />
      </nav>
      <aside sx={{borderRight: '1px solid', borderColor: 'border'}} className="grid-in-sidebar">
        <AdminSidebar />
      </aside>
      <section className="grid-in-main">{children}</section>
      <footer className="grid-in-footer pb-4 pt-4">
        <Footer />
      </footer>
    </main>
  );
};
