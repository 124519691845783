import {useEffect, useState} from 'react';

import Script from 'next/script';
import {getCookieConsentValue} from 'react-cookie-consent';

const ExternalScripts = () => {
  const [userConsented, setUserConsented] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newValue = getCookieConsentValue('PSCCookieConsent') === 'true';
      if (newValue !== userConsented) {
        setUserConsented(newValue);
      }
    }, 1000); // Check every second for changes

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (!userConsented) {
    return null;
  }

  return (
    <>
      <Script
        id="heap"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load(${process.env.NEXT_PUBLIC_HEAP_APP_ID});`,
        }}
      />
    </>
  );
};

export default ExternalScripts;
