/** @jsxImportSource theme-ui */
import React from 'react';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import ProductTour from 'components/ProductTour/';

export interface LayoutProps {
  children: React.ReactNode;
}

const LayoutLoggedIn: React.FC<LayoutProps> = ({children}) => {
  return (
    <>
      <ProductTour />
      <main className="grid-areas-layoutNoSidebar grid-cols-layoutNoSidebar grid-rows-layoutNoSidebar grid h-screen min-h-full gap-x-2 gap-y-[0.1rem] overflow-auto">
        <nav className="grid-in-navbar">
          <Navbar />
        </nav>
        <section className="grid-in-main">{children}</section>
        <footer className="grid-in-footer pb-4 pt-4">
          <Footer />
        </footer>
      </main>
    </>
  );
};

export default LayoutLoggedIn;
