import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonClasses="rounded-3xl bg-gray-900 dark:bg-white dark:text-gray-800 text-white font-medium px-3 text-sm h-8"
      buttonText="Accept all"
      enableDeclineButton={true}
      declineButtonText="Deny"
      declineButtonClasses="rounded-3xl bg-gray-900 dark:bg-white dark:text-gray-800 text-white font-medium px-3 text-sm h-8"
      cookieName="PSCCookieConsent"
      containerClasses="absolute left-5 !bottom-3 p-4 rounded-md bg-white dark:bg-gray-900 !w-[420px] border border-gray-200 dark:border-gray-800 animate-push-up z-50"
      disableStyles={true}
      buttonWrapperClasses="mt-2 flex gap-2 justify-end"
    >
      <p className="text-sm">
        We use cookies and tracking technologies to enhance the user experience. You may opt in or
        opt out of the use of these technologies.
      </p>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
