import {SSOSettings} from '@polarsignals/client-grpc-web/polarsignals/organization/v1alpha1/organization';
import {useGrpcQuery} from '@polarsignals/hooks';

import {useGrpcContext} from 'contexts/GrpcContext';

interface Props {
  organizationId?: string;
}

interface OrganizationSSOSettingsResponse {
  data: {
    organizationSSOSettings: SSOSettings | null;
  };
  loading: boolean;
  error: Error | undefined;
  mutations: {
    refetch: () => any;
    enableSSO: (sso: SSOSettings, orgID: string) => any;
    disableSSO: (orgID: string) => any;
    getConnectorId: (identifier: string) => Promise<string>;
  };
}
export const useOrganizationSSOSettings = ({
  organizationId,
}: Props): OrganizationSSOSettingsResponse => {
  const {organizationServiceClient} = useGrpcContext();

  const {
    data: organizationSSOSettings,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<SSOSettings | null>({
    key: ['getOrganizationSSOSettings', organizationId],
    queryFn: async () => {
      const {response} = await organizationServiceClient.getSSOSettings({
        id: organizationId ?? '',
      });
      return response.ssoSettings ?? null;
    },
    options: {
      enabled: !!organizationId,
    },
  });

  const enableSSO = async (sso: SSOSettings, orgID: string) => {
    await organizationServiceClient.enableSSO({
      id: orgID,
      ssoSettings: sso,
    });
    await refetch();
  };

  const disableSSO = async (orgID: string) => {
    await organizationServiceClient.disableSSO({
      id: orgID,
    });
    await refetch();
  };

  const getConnectorId = async (identifier: string): Promise<string> => {
    const {response} = await organizationServiceClient.getSSOConnectorId({
      organizationIdentifier: identifier,
    });
    return response.connectorId;
  };

  return {
    data: {organizationSSOSettings: organizationSSOSettings ?? null},
    loading: isLoading,
    error: error as Error | undefined,
    mutations: {
      refetch,
      enableSSO,
      disableSSO,
      getConnectorId,
    },
  };
};
