// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/ai/v1alpha1/ai.proto" (package "polarsignals.ai.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message polarsignals.ai.v1alpha1.ProfileData
 */
export interface ProfileData {
    /**
     * Line is the line number.
     *
     * @generated from protobuf field: int32 line = 1;
     */
    line: number;
    /**
     * Flat is the flat value of the line.
     *
     * @generated from protobuf field: float flat = 2;
     */
    flat: number;
    /**
     * Cumulative is the cumulative value of the line.
     *
     * @generated from protobuf field: float cumulative = 3;
     */
    cumulative: number;
}
/**
 * GetSourceCodeOptimizationsRequest is the request object for GetSourceCodeOptimizations.
 *
 * @generated from protobuf message polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsRequest
 */
export interface GetSourceCodeOptimizationsRequest {
    /**
     * SourceCode is the code snippet to optimize.
     *
     * @generated from protobuf field: string source_code = 1;
     */
    sourceCode: string;
    /**
     * profile_data is the profiling data for the code snippet.
     *
     * @generated from protobuf field: repeated polarsignals.ai.v1alpha1.ProfileData profile_data = 2;
     */
    profileData: ProfileData[];
}
/**
 * GetSourceCodeOptimizationsResponse is the response object for GetSourceCodeOptimizations.
 *
 * @generated from protobuf message polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsResponse
 */
export interface GetSourceCodeOptimizationsResponse {
    /**
     * OptimizedSourceCode is the optimized code snippet.
     *
     * @generated from protobuf field: string optimized_source_code = 1;
     */
    optimizedSourceCode: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ProfileData$Type extends MessageType<ProfileData> {
    constructor() {
        super("polarsignals.ai.v1alpha1.ProfileData", [
            { no: 1, name: "line", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "flat", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "cumulative", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<ProfileData>): ProfileData {
        const message = { line: 0, flat: 0, cumulative: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfileData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfileData): ProfileData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 line */ 1:
                    message.line = reader.int32();
                    break;
                case /* float flat */ 2:
                    message.flat = reader.float();
                    break;
                case /* float cumulative */ 3:
                    message.cumulative = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfileData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 line = 1; */
        if (message.line !== 0)
            writer.tag(1, WireType.Varint).int32(message.line);
        /* float flat = 2; */
        if (message.flat !== 0)
            writer.tag(2, WireType.Bit32).float(message.flat);
        /* float cumulative = 3; */
        if (message.cumulative !== 0)
            writer.tag(3, WireType.Bit32).float(message.cumulative);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.ai.v1alpha1.ProfileData
 */
export const ProfileData = new ProfileData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSourceCodeOptimizationsRequest$Type extends MessageType<GetSourceCodeOptimizationsRequest> {
    constructor() {
        super("polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsRequest", [
            { no: 1, name: "source_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "profile_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProfileData }
        ]);
    }
    create(value?: PartialMessage<GetSourceCodeOptimizationsRequest>): GetSourceCodeOptimizationsRequest {
        const message = { sourceCode: "", profileData: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSourceCodeOptimizationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSourceCodeOptimizationsRequest): GetSourceCodeOptimizationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string source_code */ 1:
                    message.sourceCode = reader.string();
                    break;
                case /* repeated polarsignals.ai.v1alpha1.ProfileData profile_data */ 2:
                    message.profileData.push(ProfileData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSourceCodeOptimizationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string source_code = 1; */
        if (message.sourceCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sourceCode);
        /* repeated polarsignals.ai.v1alpha1.ProfileData profile_data = 2; */
        for (let i = 0; i < message.profileData.length; i++)
            ProfileData.internalBinaryWrite(message.profileData[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsRequest
 */
export const GetSourceCodeOptimizationsRequest = new GetSourceCodeOptimizationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSourceCodeOptimizationsResponse$Type extends MessageType<GetSourceCodeOptimizationsResponse> {
    constructor() {
        super("polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsResponse", [
            { no: 1, name: "optimized_source_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSourceCodeOptimizationsResponse>): GetSourceCodeOptimizationsResponse {
        const message = { optimizedSourceCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSourceCodeOptimizationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSourceCodeOptimizationsResponse): GetSourceCodeOptimizationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string optimized_source_code */ 1:
                    message.optimizedSourceCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSourceCodeOptimizationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string optimized_source_code = 1; */
        if (message.optimizedSourceCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.optimizedSourceCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.ai.v1alpha1.GetSourceCodeOptimizationsResponse
 */
export const GetSourceCodeOptimizationsResponse = new GetSourceCodeOptimizationsResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.ai.v1alpha1.AIService
 */
export const AIService = new ServiceType("polarsignals.ai.v1alpha1.AIService", [
    { name: "GetSourceCodeOptimizations", options: { "google.api.http": { post: "/v1/sourcecode/optimizations" } }, I: GetSourceCodeOptimizationsRequest, O: GetSourceCodeOptimizationsResponse }
]);
