import {PolarSignalsFull} from '@polarsignals/icons';

import ColorModeToggle from 'components/Navbar/ColorModeToggle';

const NavbarLite = () => {
  return (
    <div className="relative flex">
      <div className="absolute top-4 right-4">
        <ColorModeToggle />
      </div>

      <div className="container mx-auto flex py-4 pl-4">
        <PolarSignalsFull width={200} />
      </div>
    </div>
  );
};

export default NavbarLite;
