/** @jsxImportSource theme-ui */
import {Icon} from '@iconify/react';
import {Input as ParcaInput} from '@parca/components';
import {Tooltip} from 'react-tooltip';
import {useColorMode} from 'theme-ui';

interface Props {
  label?: string;
  helpText?: string;
  helpTextExplanation?: string;
}

const Input = ({
  label,
  helpText,
  helpTextExplanation,
  ...props
}: Props & React.HTMLProps<HTMLInputElement>) => {
  const [colorMode] = useColorMode();

  return (
    <div>
      {label ? <p className="mt-3 mb-2 text-sm text-gray-500 dark:text-gray-400">{label}</p> : null}
      <div>
        <ParcaInput
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          {...props}
        />
        {helpText ? (
          <div className="mt-3 mb-2 flex items-center gap-2">
            <p className="text-sm" sx={{color: 'textSofter'}}>
              {helpText}
            </p>

            {helpTextExplanation && (
              <>
                <Icon
                  icon="tabler:info-square-filled"
                  width={14}
                  height={14}
                  className="text-gray-500"
                  data-tooltip-id="helpTextExplanationTooltip"
                  data-tooltip-html={helpTextExplanation}
                />
                <Tooltip
                  id="helpTextExplanationTooltip"
                  style={{
                    backgroundColor: colorMode === 'dark' ? '#374151' : '#fff',
                    color: colorMode === 'dark' ? '#fff' : '#333',
                    borderRadius: '8px',
                    padding: '12px',
                    textAlign: 'center',
                    zIndex: 99,
                  }}
                  place="right"
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
