import {useState} from 'react';

import {Button, Input} from '@parca/components';
import Router from 'next/router';

import useOrganizations from 'hooks/data/useOrganizations';

const AddOrganization = ({closeModal}: {closeModal?: () => void}) => {
  const [newOrganizationName, setNewOrganizationName] = useState<string>('');

  const {
    mutations: {addOrganization},
  } = useOrganizations();

  const addNewOrganization = async (name: string) => {
    const orgId = await addOrganization(name);
    setNewOrganizationName('');
    if (closeModal) closeModal();
    Router.push(`/getting-started?organization=${orgId}`);
  };

  return (
    <>
      <p className="mt-3 mb-2 text-sm text-gray-500 dark:text-gray-400">
        Enter your new organization name to continue.
      </p>

      <div>
        <Input
          type="text"
          value={newOrganizationName}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setNewOrganizationName(e.currentTarget.value);
          }}
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        />
      </div>

      <div className="mt-4">
        <Button
          className="w-fit"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async () => await addNewOrganization(newOrganizationName)}
          disabled={newOrganizationName === ''}
        >
          Create Organization
        </Button>
      </div>
    </>
  );
};

export default AddOrganization;
