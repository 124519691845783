import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../index';

// Define a type for the slice state
interface ProjectsState {
  activeProjectId: string | undefined;
  activeOrgId: string | undefined;
}

// Define the initial state using that type
const initialState: ProjectsState = {
  activeProjectId: undefined,
  activeOrgId: undefined,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setActiveProjectId: (state, action: PayloadAction<string | undefined>) => {
      state.activeProjectId = action.payload;
    },
    setActiveOrgId: (state, action: PayloadAction<string | undefined>) => {
      state.activeOrgId = action.payload;
    },
  },
});

export const {setActiveProjectId, setActiveOrgId} = projectsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const globalProjects = (state: RootState) => state.organizations;

export default projectsSlice.reducer;
