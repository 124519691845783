import {useEffect, useState} from 'react';

const STATUS = {
  UP: 'bg-[#32b17e]',
  HASISSUES: 'bg-[#f4cb16]',
  UNDERMAINTENANCE: 'bg-[#fe4304]',
} as const;

const Status: React.FC = () => {
  const [status, setStatus] = useState('UP');

  useEffect(() => {
    fetch('https://polarsignals.instatus.com/summary.json')
      .then(response => response.json())
      .then(data => setStatus(data.page.status));
  }, []);

  return (
    <a
      className="flex items-center justify-center gap-2"
      href="https://polarsignals.instatus.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>Status</span>
      <span className={`${STATUS[status]} rounded-full w-3 h-3`}></span>
    </a>
  );
};

export default Status;
