import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../index';

// Define a type for the slice state
interface ViewSlice {
  activeViewSlug: string | undefined;
  inputMatchersString: string | undefined;
}

// Define the initial state using that type
const initialState: ViewSlice = {
  activeViewSlug: undefined,
  inputMatchersString: undefined,
};

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setActiveViewSlug: (state, action: PayloadAction<string | undefined>) => {
      state.activeViewSlug = action.payload;
    },
    setInputMatchersString: (state, action: PayloadAction<string | undefined>) => {
      state.inputMatchersString = action.payload;
    },
  },
});

export const {setActiveViewSlug, setInputMatchersString} = viewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectActiveViewSlug = (state: RootState) => state.view.activeViewSlug;
export const selectInputMatchersString = (state: RootState) => state.view.inputMatchersString;

export default viewSlice.reducer;
