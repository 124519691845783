import {Fragment, useState} from 'react';

import {Menu, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {Tooltip} from 'react-tooltip';
import {useColorMode} from 'theme-ui';

import ComparingTour from 'components/ProductTour/ComparingTour';
import IcicleGraphTour from 'components/ProductTour/IcicleGraphTour';
import InitialTour from 'components/ProductTour/InitialTour';

interface Props {
  productToursCompleted?: string[];
  activeProjectId: string;
}

const HelpCenter = ({productToursCompleted, activeProjectId}: Props) => {
  const [triggerInitialTour, setTriggerInitialTour] = useState(false);
  const [triggerIcicleGraphTour, setTriggerIcicleGraphTour] = useState(false);
  const [triggerComparingTour, setTriggerComparingTour] = useState(false);

  const router = useRouter();
  const [colorMode] = useColorMode();

  const userIsOnComparingProfilesPage = !!(router.query.compare_a && router.query.compare_b);
  const userIsOnProfilerPage = !!(router.pathname === '/projects/[project-id]');

  const allToursCompleted =
    productToursCompleted?.includes('initial_tour') &&
    productToursCompleted?.includes('icicle_graph_tour') &&
    productToursCompleted?.includes('comparing_tour');

  return (
    <>
      <Menu as="div" className="relative inline-block p-1 text-left">
        <div>
          <Menu.Button className="flex items-center justify-center gap-x-[3px]">
            Help Center
            <Icon icon="tabler:caret-down-filled" width={16} height={16} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700">
            <div className="flex gap-1">
              <div className="p-4">
                <Menu.Item>
                  {({active}) => (
                    <a
                      className={`${
                        active
                          ? 'bg-indigo-500 text-white dark:text-white'
                          : 'text-gray-900 dark:text-gray-300'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://polarsignals.com/docs"
                    >
                      Documentation
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({active}) => (
                    <Link
                      href={`/projects/${activeProjectId}/getting-started`}
                      className={`${
                        active
                          ? 'bg-indigo-500 text-white dark:text-white'
                          : 'text-gray-900 dark:text-gray-300'
                      } group flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                    >
                      Getting Started Guide
                    </Link>
                  )}
                </Menu.Item>
              </div>
              {allToursCompleted && (
                <div className="flex flex-col rounded-br-md rounded-tr-md bg-slate-100 p-4 dark:bg-gray-800">
                  <p className="mb-2 text-[10px] font-bold uppercase text-gray-500">
                    Take the product tour
                  </p>
                  <Menu.Item>
                    {({active}) => (
                      <>
                        <button
                          data-tooltip-id="profiler-product-tour"
                          data-tooltip-html="To begin this tour, ensure you're on the Profiler page."
                          disabled={!userIsOnProfilerPage}
                          onClick={() => setTriggerInitialTour(true)}
                          className={`${
                            active
                              ? 'bg-indigo-500 text-white dark:text-white'
                              : 'text-gray-900 dark:text-gray-300'
                          } ${
                            userIsOnProfilerPage && !userIsOnComparingProfilesPage
                              ? 'cursor-pointer'
                              : 'disabled cursor-not-allowed opacity-50'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                        >
                          Profiler Product Tour
                        </button>
                        {!userIsOnProfilerPage && (
                          <Tooltip
                            id="profiler-product-tour"
                            style={{
                              backgroundColor: colorMode === 'dark' ? '#374151' : '#F9FAFB',
                              color: colorMode === 'dark' ? '#fff' : '#4B5563',
                              borderRadius: '8px',
                              padding: '12px',
                              textAlign: 'left',
                              zIndex: 99,
                              width: '304px',
                            }}
                            place="bottom"
                          />
                        )}
                      </>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({active}) => (
                      <>
                        <button
                          data-tooltip-id="profiler-product-tour"
                          data-tooltip-html="To begin this tour, ensure you're on the Profiler page."
                          onClick={() => setTriggerIcicleGraphTour(true)}
                          disabled={!userIsOnProfilerPage}
                          className={`${
                            active
                              ? 'bg-indigo-500 text-white dark:text-white'
                              : 'text-gray-900 dark:text-gray-300'
                          } ${
                            userIsOnProfilerPage && !userIsOnComparingProfilesPage
                              ? 'cursor-pointer'
                              : 'disabled cursor-not-allowed opacity-50'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                        >
                          Visualisation Product Tour
                        </button>
                        {!userIsOnProfilerPage && (
                          <Tooltip
                            id="profiler-product-tour"
                            style={{
                              backgroundColor: colorMode === 'dark' ? '#374151' : '#F9FAFB',
                              color: colorMode === 'dark' ? '#fff' : '#4B5563',
                              borderRadius: '8px',
                              padding: '12px',
                              textAlign: 'left',
                              zIndex: 99,
                              width: '304px',
                            }}
                            place="bottom"
                          />
                        )}
                      </>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({active}) => (
                      <>
                        <button
                          data-tooltip-id="comparing-profiles-product-tour"
                          data-tooltip-html="To begin this tour, ensure you're comparing profiles on the Profiler page."
                          onClick={() => setTriggerComparingTour(true)}
                          disabled={!userIsOnComparingProfilesPage}
                          className={`${
                            active
                              ? 'bg-indigo-500 text-white dark:text-white'
                              : 'text-gray-900 dark:text-gray-300'
                          } ${
                            !userIsOnComparingProfilesPage
                              ? 'disabled cursor-not-allowed opacity-50'
                              : 'cursor-pointer'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-indigo-500 hover:text-white`}
                        >
                          Comparing Profiles Product Tour
                        </button>
                        {!userIsOnComparingProfilesPage && (
                          <Tooltip
                            id="comparing-profiles-product-tour"
                            style={{
                              backgroundColor: colorMode === 'dark' ? '#374151' : '#F9FAFB',
                              color: colorMode === 'dark' ? '#fff' : '#4B5563',
                              borderRadius: '8px',
                              padding: '12px',
                              textAlign: 'left',
                              zIndex: 99,
                              width: '304px',
                            }}
                            place="bottom"
                          />
                        )}
                      </>
                    )}
                  </Menu.Item>
                </div>
              )}
            </div>
          </Menu.Items>
        </Transition>
        <InitialTour
          hasUserSentData={userIsOnProfilerPage}
          updateUserProductTour={() => {}}
          userIsOnComparingProfilesPage={userIsOnComparingProfilesPage}
          triggeredFromHelpCenter={triggerInitialTour}
          updateTriggeredFromHelpCenter={() => {
            setTriggerInitialTour(false);
          }}
          fromNavbar={true}
        />
        <IcicleGraphTour
          hasUserSentData={userIsOnProfilerPage}
          updateUserProductTour={() => {}}
          userIsOnComparingProfilesPage={userIsOnComparingProfilesPage}
          triggeredFromHelpCenter={triggerIcicleGraphTour}
          updateTriggeredFromHelpCenter={() => {
            setTriggerIcicleGraphTour(false);
          }}
          fromNavbar={true}
        />
        <ComparingTour
          hasUserSentData={userIsOnProfilerPage}
          updateUserProductTour={() => {}}
          userIsOnComparingProfilesPage={userIsOnComparingProfilesPage}
          triggeredFromHelpCenter={triggerComparingTour}
          updateTriggeredFromHelpCenter={() => {
            setTriggerComparingTour(false);
          }}
          fromNavbar={true}
        />
      </Menu>
    </>
  );
};

export default HelpCenter;
