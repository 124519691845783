// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/project/v1alpha1/project.proto" (package "polarsignals.project.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * Project represents a project.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.Project
 */
export interface Project {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the project.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The ID of the organization this project belongs to.
     *
     * @generated from protobuf field: string organization_id = 3;
     */
    organizationId: string;
}
/**
 * Organization represents an organization.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.Organization
 */
export interface Organization {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The projects of the organization.
     *
     * @generated from protobuf field: repeated polarsignals.project.v1alpha1.Project projects = 3;
     */
    projects: Project[];
}
/**
 * Token represents a token.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.Token
 */
export interface Token {
    /**
     * The ID of the token.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the token.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The time the token was created.
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 3;
     */
    createdAt?: Timestamp;
}
/**
 * GetProjectsRequest is the request for the GetProjects method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectsRequest
 */
export interface GetProjectsRequest {
}
/**
 * GetProjectsResponse is the response for the GetProjects method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectsResponse
 */
export interface GetProjectsResponse {
    /**
     * The organizations.
     *
     * @generated from protobuf field: repeated polarsignals.project.v1alpha1.Organization organizations = 1;
     */
    organizations: Organization[];
}
/**
 * CreateProjectRequest is the request for the CreateProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * The name of the project.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string organization_id = 2;
     */
    organizationId: string;
}
/**
 * CreateProjectResponse is the response for the CreateProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.CreateProjectResponse
 */
export interface CreateProjectResponse {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetProjectRequest is the request for the GetProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectRequest
 */
export interface GetProjectRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetProjectResponse is the response for the GetProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectResponse
 */
export interface GetProjectResponse {
    /**
     * The project.
     *
     * @generated from protobuf field: polarsignals.project.v1alpha1.Project project = 1;
     */
    project?: Project;
}
/**
 * UpdateProjectRequest is the request for the UpdateProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.UpdateProjectRequest
 */
export interface UpdateProjectRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the project.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * UpdateProjectResponse is the response for the UpdateProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.UpdateProjectResponse
 */
export interface UpdateProjectResponse {
}
/**
 * DeleteProjectRequest is the request for the DeleteProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.DeleteProjectRequest
 */
export interface DeleteProjectRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * DeleteProjectResponse is the response for the DeleteProject method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.DeleteProjectResponse
 */
export interface DeleteProjectResponse {
}
/**
 * GetProjectTokensRequest is the request for the GetProjectTokens method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectTokensRequest
 */
export interface GetProjectTokensRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetProjectTokensResponse is the response for the GetProjectTokens method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectTokensResponse
 */
export interface GetProjectTokensResponse {
    /**
     * The tokens.
     *
     * @generated from protobuf field: repeated polarsignals.project.v1alpha1.Token tokens = 1;
     */
    tokens: Token[];
}
/**
 * CreateProjectTokenRequest is the request for the CreateProjectToken method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.CreateProjectTokenRequest
 */
export interface CreateProjectTokenRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the token.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * CreateProjectTokenResponse is the response for the CreateProjectToken method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.CreateProjectTokenResponse
 */
export interface CreateProjectTokenResponse {
    /**
     * The token.
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * DeleteProjectTokenRequest is the request for the DeleteProjectToken method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.DeleteProjectTokenRequest
 */
export interface DeleteProjectTokenRequest {
    /**
     * The ID of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the token.
     *
     * @generated from protobuf field: string token_id = 2;
     */
    tokenId: string;
}
/**
 * DeleteProjectTokenResponse is the response for the DeleteProjectToken method.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.DeleteProjectTokenResponse
 */
export interface DeleteProjectTokenResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class Project$Type extends MessageType<Project> {
    constructor() {
        super("polarsignals.project.v1alpha1.Project", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Project>): Project {
        const message = { id: "", name: "", organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Project>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Project): Project {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string organization_id */ 3:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Project, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string organization_id = 3; */
        if (message.organizationId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.Project
 */
export const Project = new Project$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Organization$Type extends MessageType<Organization> {
    constructor() {
        super("polarsignals.project.v1alpha1.Organization", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "projects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Project }
        ]);
    }
    create(value?: PartialMessage<Organization>): Organization {
        const message = { id: "", name: "", projects: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Organization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Organization): Organization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated polarsignals.project.v1alpha1.Project projects */ 3:
                    message.projects.push(Project.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Organization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated polarsignals.project.v1alpha1.Project projects = 3; */
        for (let i = 0; i < message.projects.length; i++)
            Project.internalBinaryWrite(message.projects[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.Organization
 */
export const Organization = new Organization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("polarsignals.project.v1alpha1.Token", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Token>): Token {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Token>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Token): Token {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 3:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Token, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.Timestamp created_at = 3; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.Token
 */
export const Token = new Token$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectsRequest$Type extends MessageType<GetProjectsRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectsRequest", []);
    }
    create(value?: PartialMessage<GetProjectsRequest>): GetProjectsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectsRequest): GetProjectsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetProjectsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectsRequest
 */
export const GetProjectsRequest = new GetProjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectsResponse$Type extends MessageType<GetProjectsResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectsResponse", [
            { no: 1, name: "organizations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Organization }
        ]);
    }
    create(value?: PartialMessage<GetProjectsResponse>): GetProjectsResponse {
        const message = { organizations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectsResponse): GetProjectsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.project.v1alpha1.Organization organizations */ 1:
                    message.organizations.push(Organization.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.project.v1alpha1.Organization organizations = 1; */
        for (let i = 0; i < message.organizations.length; i++)
            Organization.internalBinaryWrite(message.organizations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectsResponse
 */
export const GetProjectsResponse = new GetProjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProjectRequest$Type extends MessageType<CreateProjectRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.CreateProjectRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateProjectRequest>): CreateProjectRequest {
        const message = { name: "", organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateProjectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProjectRequest): CreateProjectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateProjectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.CreateProjectRequest
 */
export const CreateProjectRequest = new CreateProjectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProjectResponse$Type extends MessageType<CreateProjectResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.CreateProjectResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateProjectResponse>): CreateProjectResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProjectResponse): CreateProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.CreateProjectResponse
 */
export const CreateProjectResponse = new CreateProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectRequest$Type extends MessageType<GetProjectRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetProjectRequest>): GetProjectRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectRequest): GetProjectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectRequest
 */
export const GetProjectRequest = new GetProjectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectResponse$Type extends MessageType<GetProjectResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectResponse", [
            { no: 1, name: "project", kind: "message", T: () => Project }
        ]);
    }
    create(value?: PartialMessage<GetProjectResponse>): GetProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectResponse): GetProjectResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.project.v1alpha1.Project project */ 1:
                    message.project = Project.internalBinaryRead(reader, reader.uint32(), options, message.project);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.project.v1alpha1.Project project = 1; */
        if (message.project)
            Project.internalBinaryWrite(message.project, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectResponse
 */
export const GetProjectResponse = new GetProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProjectRequest$Type extends MessageType<UpdateProjectRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.UpdateProjectRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateProjectRequest>): UpdateProjectRequest {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateProjectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProjectRequest): UpdateProjectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateProjectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.UpdateProjectRequest
 */
export const UpdateProjectRequest = new UpdateProjectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProjectResponse$Type extends MessageType<UpdateProjectResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.UpdateProjectResponse", []);
    }
    create(value?: PartialMessage<UpdateProjectResponse>): UpdateProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProjectResponse): UpdateProjectResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.UpdateProjectResponse
 */
export const UpdateProjectResponse = new UpdateProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProjectRequest$Type extends MessageType<DeleteProjectRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.DeleteProjectRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteProjectRequest>): DeleteProjectRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteProjectRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProjectRequest): DeleteProjectRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteProjectRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.DeleteProjectRequest
 */
export const DeleteProjectRequest = new DeleteProjectRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProjectResponse$Type extends MessageType<DeleteProjectResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.DeleteProjectResponse", []);
    }
    create(value?: PartialMessage<DeleteProjectResponse>): DeleteProjectResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteProjectResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProjectResponse): DeleteProjectResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteProjectResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.DeleteProjectResponse
 */
export const DeleteProjectResponse = new DeleteProjectResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectTokensRequest$Type extends MessageType<GetProjectTokensRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectTokensRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetProjectTokensRequest>): GetProjectTokensRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectTokensRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectTokensRequest): GetProjectTokensRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectTokensRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectTokensRequest
 */
export const GetProjectTokensRequest = new GetProjectTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectTokensResponse$Type extends MessageType<GetProjectTokensResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectTokensResponse", [
            { no: 1, name: "tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token }
        ]);
    }
    create(value?: PartialMessage<GetProjectTokensResponse>): GetProjectTokensResponse {
        const message = { tokens: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectTokensResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectTokensResponse): GetProjectTokensResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.project.v1alpha1.Token tokens */ 1:
                    message.tokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectTokensResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.project.v1alpha1.Token tokens = 1; */
        for (let i = 0; i < message.tokens.length; i++)
            Token.internalBinaryWrite(message.tokens[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectTokensResponse
 */
export const GetProjectTokensResponse = new GetProjectTokensResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProjectTokenRequest$Type extends MessageType<CreateProjectTokenRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.CreateProjectTokenRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateProjectTokenRequest>): CreateProjectTokenRequest {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateProjectTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProjectTokenRequest): CreateProjectTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateProjectTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.CreateProjectTokenRequest
 */
export const CreateProjectTokenRequest = new CreateProjectTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProjectTokenResponse$Type extends MessageType<CreateProjectTokenResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.CreateProjectTokenResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateProjectTokenResponse>): CreateProjectTokenResponse {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateProjectTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProjectTokenResponse): CreateProjectTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateProjectTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.CreateProjectTokenResponse
 */
export const CreateProjectTokenResponse = new CreateProjectTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProjectTokenRequest$Type extends MessageType<DeleteProjectTokenRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.DeleteProjectTokenRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteProjectTokenRequest>): DeleteProjectTokenRequest {
        const message = { id: "", tokenId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteProjectTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProjectTokenRequest): DeleteProjectTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string token_id */ 2:
                    message.tokenId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteProjectTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string token_id = 2; */
        if (message.tokenId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tokenId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.DeleteProjectTokenRequest
 */
export const DeleteProjectTokenRequest = new DeleteProjectTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProjectTokenResponse$Type extends MessageType<DeleteProjectTokenResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.DeleteProjectTokenResponse", []);
    }
    create(value?: PartialMessage<DeleteProjectTokenResponse>): DeleteProjectTokenResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteProjectTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProjectTokenResponse): DeleteProjectTokenResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteProjectTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.DeleteProjectTokenResponse
 */
export const DeleteProjectTokenResponse = new DeleteProjectTokenResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.project.v1alpha1.ProjectService
 */
export const ProjectService = new ServiceType("polarsignals.project.v1alpha1.ProjectService", [
    { name: "GetProjects", options: { "google.api.http": { get: "/v1" } }, I: GetProjectsRequest, O: GetProjectsResponse },
    { name: "CreateProject", options: { "google.api.http": { post: "/v1", body: "*" } }, I: CreateProjectRequest, O: CreateProjectResponse },
    { name: "GetProject", options: { "google.api.http": { get: "/v1/{id}" } }, I: GetProjectRequest, O: GetProjectResponse },
    { name: "UpdateProject", options: { "google.api.http": { patch: "/v1/{id}", body: "*" } }, I: UpdateProjectRequest, O: UpdateProjectResponse },
    { name: "DeleteProject", options: { "google.api.http": { delete: "/v1/{id}" } }, I: DeleteProjectRequest, O: DeleteProjectResponse },
    { name: "GetProjectTokens", options: { "google.api.http": { get: "/v1/{id}/tokens" } }, I: GetProjectTokensRequest, O: GetProjectTokensResponse },
    { name: "CreateProjectToken", options: { "google.api.http": { post: "/v1/{id}/tokens", body: "*" } }, I: CreateProjectTokenRequest, O: CreateProjectTokenResponse },
    { name: "DeleteProjectToken", options: { "google.api.http": { delete: "/v1/{id}/tokens" } }, I: DeleteProjectTokenRequest, O: DeleteProjectTokenResponse }
]);
