import {MutableRefObject, SetStateAction} from 'react';

import {Icon} from '@iconify/react';
import {
  Project,
  Organization,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import cx from 'classnames';

interface Props {
  currentOrg: Organization | undefined;
  isOrganizationSelectorClicked: boolean;
  organizations: Organization[];
  switchProjectsShown: (organization: Organization | undefined) => void;
  switchViewsShown: (project: Project | undefined) => Promise<void>;
  navigateToOrgPage: (organization: Organization) => void;
  setIsAddOrganizationModalOpen: (value: SetStateAction<boolean>) => void;
  close: (
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined
  ) => void;
}

const OrganizationSelector = ({
  organizations,
  isOrganizationSelectorClicked,
  currentOrg,
  switchProjectsShown,
  switchViewsShown,
  navigateToOrgPage,
  setIsAddOrganizationModalOpen,
  close,
}: Props) => {
  return (
    <>
      <div
        className={cx(
          isOrganizationSelectorClicked
            ? 'bg-white dark:bg-gray-700'
            : 'bg-gray-50 dark:bg-gray-800',
          'max-w-64 flex w-64 flex-col'
        )}
      >
        <div className="flex flex-col gap-2 py-4 px-2">
          <p className="px-2 text-xs text-gray-500 dark:text-gray-400">Organizations</p>
          <div className="flex flex-col gap-1 text-gray-800 dark:text-gray-100">
            {organizations?.map((organization, index) => (
              <div
                key={index}
                className={cx(
                  organization.id === currentOrg?.id
                    ? 'bg-indigo-600 text-white'
                    : 'hover:bg-indigo-500 hover:text-white',
                  'flex cursor-pointer items-center justify-between rounded-sm py-2 px-2.5 text-sm '
                )}
                onMouseEnter={() => {
                  switchProjectsShown(organization);
                  switchViewsShown(undefined);
                }}
                onClick={() => {
                  navigateToOrgPage(organization);
                  close();
                }}
              >
                <span>{organization.name}</span>
                {organization.id === currentOrg?.id && (
                  <Icon icon="carbon:checkmark" className="" width={12} height={12} />
                )}
              </div>
            ))}

            <div
              className="flex cursor-pointer items-center gap-2 rounded-sm px-2.5 py-2 text-sm hover:bg-indigo-500 hover:text-white"
              onClick={() => {
                setIsAddOrganizationModalOpen(true);
                close();
              }}
              onMouseEnter={() => {
                switchProjectsShown(undefined);
              }}
            >
              <Icon icon="ei:plus" width={18} height={18} />
              <span>New organization</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationSelector;
