import {useEffect, useMemo, useState} from 'react';

import Image from 'next/image';
import Joyride, {CallBackProps, STATUS, Step} from 'react-joyride';
import {useSetState} from 'react-use';
import {useColorMode} from 'theme-ui';

import ProductTourBanner from './Banner';
import strings from './string.json';

import {Tooltip} from './';

interface Props {
  triggeredFromHelpCenter?: boolean;
  updateTriggeredFromHelpCenter?: (triggeredFromHelpCenter: boolean) => void;
  hasUserSentData: boolean;
  productTour?: string[];
  updateUserProductTour: (tourName: string) => void;
  userIsOnComparingProfilesPage: boolean;
  fromNavbar?: boolean;
}

interface State {
  run: boolean;
  steps: Step[];
}

const IcicleGraphTour = ({
  productTour,
  updateUserProductTour,
  userIsOnComparingProfilesPage,
  hasUserSentData,
  triggeredFromHelpCenter,
  updateTriggeredFromHelpCenter,
  fromNavbar = false,
}: Props) => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const showBanner = useMemo(() => {
    return (
      hasUserSentData &&
      !userIsOnComparingProfilesPage &&
      productTour?.includes('initial_tour') &&
      !productTour?.includes('icicle_graph_tour') &&
      !fromNavbar
    );
  }, [userIsOnComparingProfilesPage, productTour, hasUserSentData]);

  useEffect(() => {
    if (triggeredFromHelpCenter) {
      handleClickStart();
    }
  }, [triggeredFromHelpCenter]);

  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        setIsBannerOpen(true);
      }, 10000);
    } else {
      setIsBannerOpen(false);
    }
  }, [showBanner, isBannerOpen]);

  const [{run, steps}, setState] = useSetState<State>({
    run: false,
    steps: [
      {
        content: (
          <div className="max-w-[464px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['icicleGraphTour.firstStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['icicleGraphTour.firstStep.body']}
            </p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of how to group stacktraces in the Icicle graph."
                  src="/product-tour/dark-group-stacktraces.png"
                  width={161}
                  height={146}
                />
              ) : (
                <Image
                  alt="A screenshot of how to group stacktraces in the Icicle graph."
                  src="/product-tour/group-stacktraces.png"
                  width={161}
                  height={146}
                />
              )}
            </div>
          </div>
        ),
        placement: 'top',
        disableBeacon: true,
        disableScrolling: true,
        target: '#h-group-by-filter',
      },
      {
        content: (
          <div className="max-w-[464px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['icicleGraphTour.secondStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['icicleGraphTour.secondStep.body']}
            </p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of how to sort stacktraces in the Icicle graph."
                  src="/product-tour/dark-sort-stacktraces.png"
                  width={150}
                  height={149}
                />
              ) : (
                <Image
                  alt="A screenshot of how to sort stacktraces in the Icicle graph."
                  src="/product-tour/sort-stacktraces.png"
                  width={180}
                  height={160}
                />
              )}
            </div>
          </div>
        ),
        placement: 'top',
        target: '#h-sort-by-filter',
      },
      {
        content: (
          <div className="max-w-[464px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['icicleGraphTour.thirdStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['icicleGraphTour.thirdStep.body']}
            </p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of how to filter by runtimes in the Icicle graph."
                  src="/product-tour/dark-filter-runtimes.png"
                  width={240}
                  height={143}
                />
              ) : (
                <Image
                  alt="A screenshot of how to filter by runtimes in the Icicle graph."
                  src="/product-tour/filter-runtimes.png"
                  width={240}
                  height={127}
                />
              )}
            </div>
          </div>
        ),
        placement: 'top',
        target: '#h-runtimes-filter',
      },
      {
        content: (
          <div className="max-w-[464px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['icicleGraphTour.fourthStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['icicleGraphTour.fourthStep.body']}
            </p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of how to copy values using the right click context menu"
                  src="/product-tour/dark-copy-values.png"
                  width={160}
                  height={148}
                />
              ) : (
                <Image
                  alt="A screenshot of how to copy values using the right click context menu"
                  src="/product-tour/copy-values.png"
                  width={180}
                  height={160}
                />
              )}
            </div>
          </div>
        ),
        placement: 'top-start',
        target: '#h-icicle-graph',
      },
    ],
  });

  const handleClickStart = () => {
    setState({
      run: true,
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({run: false});

      if (triggeredFromHelpCenter) {
        updateTriggeredFromHelpCenter?.(false);
        return;
      }

      closeBanner();
    }
  };

  const closeBanner = () => {
    updateUserProductTour('icicle_graph_tour');
    setIsBannerOpen(false);
  };

  return (
    <>
      {isBannerOpen && (
        <ProductTourBanner
          closeBanner={() => closeBanner()}
          initiateTour={() => handleClickStart()}
          text="Click here to go through our walkthough guide for the Icicle graph."
        />
      )}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: isDarkMode ? '#1F2937' : '#fff',
          },
          beaconInner: {
            background: '#4C68F6',
          },
          beaconOuter: {
            background: 'rgb(76, 104, 246, 0.2)',
            border: '2px solid #4C68F6',
          },
        }}
        tooltipComponent={Tooltip}
      />
    </>
  );
};

export default IcicleGraphTour;
