import cx from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';

const OrganizationSubMenu = () => {
  const router = useRouter();

  const isOnOrganizationsProjectPage = !router.pathname.includes('settings');
  const isOnOrganizationSettingsPage = router.pathname.includes('settings');

  return (
    <div className="flex gap-4 px-4 text-sm">
      <Link
        href={`/organizations/${router.query.organizationId}`}
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isOnOrganizationsProjectPage && 'border-b-2 border-blue-600'
        )}
      >
        Projects
      </Link>
      <Link
        href={`/organizations/${router.query.organizationId}/settings`}
        className={cx(
          'rounded-sm px-3 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-800',
          isOnOrganizationSettingsPage && 'border-b-2 border-blue-600'
        )}
      >
        Settings
      </Link>
    </div>
  );
};

export default OrganizationSubMenu;
