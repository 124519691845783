// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/view/v1alpha1/view.proto" (package "polarsignals.view.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ViewService } from "./view";
import type { DeleteViewResponse } from "./view";
import type { DeleteViewRequest } from "./view";
import type { UpdateViewResponse } from "./view";
import type { UpdateViewRequest } from "./view";
import type { GetViewResponse } from "./view";
import type { GetViewRequest } from "./view";
import type { CreateViewResponse } from "./view";
import type { CreateViewRequest } from "./view";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetViewsResponse } from "./view";
import type { GetViewsRequest } from "./view";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * ViewService provides the ability to manage views.
 *
 * @generated from protobuf service polarsignals.view.v1alpha1.ViewService
 */
export interface IViewServiceClient {
    /**
     * GetViews returns all views for a given project's id.
     *
     * @generated from protobuf rpc: GetViews(polarsignals.view.v1alpha1.GetViewsRequest) returns (polarsignals.view.v1alpha1.GetViewsResponse);
     */
    getViews(input: GetViewsRequest, options?: RpcOptions): UnaryCall<GetViewsRequest, GetViewsResponse>;
    /**
     * CreateView creates a new view.
     *
     * @generated from protobuf rpc: CreateView(polarsignals.view.v1alpha1.CreateViewRequest) returns (polarsignals.view.v1alpha1.CreateViewResponse);
     */
    createView(input: CreateViewRequest, options?: RpcOptions): UnaryCall<CreateViewRequest, CreateViewResponse>;
    /**
     * GetView returns the view with the given slug.
     *
     * @generated from protobuf rpc: GetView(polarsignals.view.v1alpha1.GetViewRequest) returns (polarsignals.view.v1alpha1.GetViewResponse);
     */
    getView(input: GetViewRequest, options?: RpcOptions): UnaryCall<GetViewRequest, GetViewResponse>;
    /**
     * UpdateView updates the view with the given slug.
     *
     * @generated from protobuf rpc: UpdateView(polarsignals.view.v1alpha1.UpdateViewRequest) returns (polarsignals.view.v1alpha1.UpdateViewResponse);
     */
    updateView(input: UpdateViewRequest, options?: RpcOptions): UnaryCall<UpdateViewRequest, UpdateViewResponse>;
    /**
     * DeleteView deletes the view with the given slug.
     *
     * @generated from protobuf rpc: DeleteView(polarsignals.view.v1alpha1.DeleteViewRequest) returns (polarsignals.view.v1alpha1.DeleteViewResponse);
     */
    deleteView(input: DeleteViewRequest, options?: RpcOptions): UnaryCall<DeleteViewRequest, DeleteViewResponse>;
}
/**
 * ViewService provides the ability to manage views.
 *
 * @generated from protobuf service polarsignals.view.v1alpha1.ViewService
 */
export class ViewServiceClient implements IViewServiceClient, ServiceInfo {
    typeName = ViewService.typeName;
    methods = ViewService.methods;
    options = ViewService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetViews returns all views for a given project's id.
     *
     * @generated from protobuf rpc: GetViews(polarsignals.view.v1alpha1.GetViewsRequest) returns (polarsignals.view.v1alpha1.GetViewsResponse);
     */
    getViews(input: GetViewsRequest, options?: RpcOptions): UnaryCall<GetViewsRequest, GetViewsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetViewsRequest, GetViewsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateView creates a new view.
     *
     * @generated from protobuf rpc: CreateView(polarsignals.view.v1alpha1.CreateViewRequest) returns (polarsignals.view.v1alpha1.CreateViewResponse);
     */
    createView(input: CreateViewRequest, options?: RpcOptions): UnaryCall<CreateViewRequest, CreateViewResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateViewRequest, CreateViewResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetView returns the view with the given slug.
     *
     * @generated from protobuf rpc: GetView(polarsignals.view.v1alpha1.GetViewRequest) returns (polarsignals.view.v1alpha1.GetViewResponse);
     */
    getView(input: GetViewRequest, options?: RpcOptions): UnaryCall<GetViewRequest, GetViewResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetViewRequest, GetViewResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateView updates the view with the given slug.
     *
     * @generated from protobuf rpc: UpdateView(polarsignals.view.v1alpha1.UpdateViewRequest) returns (polarsignals.view.v1alpha1.UpdateViewResponse);
     */
    updateView(input: UpdateViewRequest, options?: RpcOptions): UnaryCall<UpdateViewRequest, UpdateViewResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateViewRequest, UpdateViewResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteView deletes the view with the given slug.
     *
     * @generated from protobuf rpc: DeleteView(polarsignals.view.v1alpha1.DeleteViewRequest) returns (polarsignals.view.v1alpha1.DeleteViewResponse);
     */
    deleteView(input: DeleteViewRequest, options?: RpcOptions): UnaryCall<DeleteViewRequest, DeleteViewResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteViewRequest, DeleteViewResponse>("unary", this._transport, method, opt, input);
    }
}
