import useProjects from '../useProjects';

import useCurrentUser from '.';

export const useCurrentUserProjectAccess = (projectId: string) => {
  const {data, loading, error} = useCurrentUser();
  const {data: projectsData, loading: projectsLoading, error: projectsError} = useProjects();
  if (loading || projectsLoading) {
    return {loading};
  }

  if (error || projectsError) {
    return {error};
  }

  const org = projectsData?.organizations?.find(org => {
    return org.projects.find(project => project.id === projectId) !== undefined;
  });

  if (!org) {
    return {error: new Error('Project not found')};
  }

  const role = data?.user?.roles?.find(role => role.organizationId === org?.id)?.role;

  return {
    data: {
      role,
      isOwner: role === 'owner',
      isViewer: role === 'viewer',
      hasReadAccess: role !== undefined,
      hasWriteAccess: role === 'owner',
    },
  };
};

export const useCurrentUserOrganizationAccess = (organizationId: string) => {
  const {data, loading, error} = useCurrentUser();
  const {data: projectsData, loading: projectsLoading, error: projectsError} = useProjects();
  if (loading || projectsLoading) {
    return {loading};
  }

  if (error || projectsError) {
    return {error};
  }

  const org = projectsData?.organizations?.find(org => org.id === organizationId);

  if (!org) {
    return {error: new Error('Organization not found')};
  }

  const role = data?.user?.roles?.find(role => role.organizationId === org?.id)?.role;

  return {
    data: {
      role,
      isOwner: role === 'owner',
      isViewer: role === 'viewer',
      hasReadAccess: role !== undefined,
      hasWriteAccess: role === 'owner',
    },
  };
};
