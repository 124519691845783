import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {RootState} from '../index';

// Define a type for the slice state
export interface OnboardingState {
  setupCollectionStepCompleted: boolean;
  inviteUsersStepCompleted: boolean;
  onboardingAPIToken: string | undefined;
}

// Define the initial state using that type
export const initialState: OnboardingState = {
  setupCollectionStepCompleted: false,
  inviteUsersStepCompleted: false,
  onboardingAPIToken: undefined,
};

export const onboardingSlice = createSlice({
  name: 'onboardingState',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSetupCollectionStepCompleted: (state, action: PayloadAction<boolean>) => {
      state.setupCollectionStepCompleted = action.payload;
    },
    setInviteUsersStepCompleted: (state, action: PayloadAction<boolean>) => {
      state.inviteUsersStepCompleted = action.payload;
    },
    setOnboardingAPIToken: (state, action: PayloadAction<string>) => {
      state.onboardingAPIToken = action.payload;
    },
  },
});

export const {setSetupCollectionStepCompleted, setInviteUsersStepCompleted, setOnboardingAPIToken} =
  onboardingSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectSetupCollectionStepCompleted = (state: RootState) =>
  state.onboarding.setupCollectionStepCompleted;
export const selectInviteUsersStepCompleted = (state: RootState) =>
  state.onboarding.inviteUsersStepCompleted;
export const selectOnboardingAPIToken = (state: RootState) => state.onboarding.onboardingAPIToken;

export default onboardingSlice.reducer;
