import {useQuery, UseQueryResult} from '@tanstack/react-query';

interface Props<IRes> {
  key: unknown[];
  queryFn: () => Promise<IRes>;
  options?: {
    enabled?: boolean | undefined;
    retry?: boolean | undefined;
  };
}

const useGrpcQuery = <IRes>({
  key,
  queryFn,
  options: {enabled = true, retry} = {},
}: Props<IRes>): UseQueryResult<IRes> => {
  return useQuery<IRes>(
    key,
    async () => {
      return await queryFn();
    },
    {
      enabled,
      retry,
    }
  );
};

export default useGrpcQuery;
