// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/billing/v1alpha1/billing.proto" (package "polarsignals.billing.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * GetConfigRequest is the request for the GetConfig method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetConfigRequest
 */
export interface GetConfigRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * GetConfigResponse is the response for the GetConfig method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetConfigResponse
 */
export interface GetConfigResponse {
    /**
     * Public key of the Stripe API.
     *
     * @generated from protobuf field: string publishable_key = 1;
     */
    publishableKey: string;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.Card
 */
export interface Card {
    /**
     * Card brand.
     *
     * @generated from protobuf field: string brand = 1;
     */
    brand: string;
    /**
     * Last 4 digits of the card.
     *
     * @generated from protobuf field: string last4 = 2;
     */
    last4: string;
    /**
     * Expiration month of the card.
     *
     * @generated from protobuf field: int32 exp_month = 3;
     */
    expMonth: number;
    /**
     * Expiration year of the card.
     *
     * @generated from protobuf field: int32 exp_year = 4;
     */
    expYear: number;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.PoBilling
 */
export interface PoBilling {
}
/**
 * PaymentMethod represents a payment method of a subscription.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.PaymentMethod
 */
export interface PaymentMethod {
    /**
     * @generated from protobuf field: polarsignals.billing.v1alpha1.Card card = 1;
     */
    card?: Card;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetSubscriptionRequest
 */
export interface GetSubscriptionRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetSubscriptionResponse
 */
export interface GetSubscriptionResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string status = 2;
     */
    status: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created = 3;
     */
    created?: Timestamp;
    /**
     * @generated from protobuf oneof: payment_method
     */
    paymentMethod: {
        oneofKind: "card";
        /**
         * @generated from protobuf field: polarsignals.billing.v1alpha1.Card card = 4;
         */
        card: Card;
    } | {
        oneofKind: "poBilling";
        /**
         * @generated from protobuf field: polarsignals.billing.v1alpha1.PoBilling po_billing = 5;
         */
        poBilling: PoBilling;
    } | {
        oneofKind: undefined;
    };
}
/**
 * CreateSessionRequest is the request for the CreateSession method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.CreateSessionRequest
 */
export interface CreateSessionRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
    /**
     * Plan ID.
     *
     * @generated from protobuf field: string price_id = 2;
     */
    priceId: string;
    /**
     * redirect_to helps to determine where to redirect to after a successful subscription.
     *
     * @generated from protobuf field: string redirect_to = 3;
     */
    redirectTo: string;
}
/**
 * CreateSessionResponse is the response for the CreateSession method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.CreateSessionResponse
 */
export interface CreateSessionResponse {
    /**
     * Session ID.
     *
     * @generated from protobuf field: string session_id = 1;
     */
    sessionId: string;
}
/**
 * GetInvoicesUpcomingRequest is the request for the GetInvoicesUpcoming method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetInvoicesUpcomingRequest
 */
export interface GetInvoicesUpcomingRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * GetInvoicesUpcomingResponse is the response for the GetInvoicesUpcoming method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetInvoicesUpcomingResponse
 */
export interface GetInvoicesUpcomingResponse {
    /**
     * Amount due of the upcoming invoice.
     *
     * @generated from protobuf field: int32 amount_due = 1;
     */
    amountDue: number;
    /**
     * Currency of the upcoming invoice.
     *
     * @generated from protobuf field: string currency = 2;
     */
    currency: string;
    /**
     * The start of the period of the upcoming invoice.
     *
     * @generated from protobuf field: int32 period_start = 3;
     */
    periodStart: number;
    /**
     * The end of the period of the upcoming invoice.
     *
     * @generated from protobuf field: int32 period_end = 4;
     */
    periodEnd: number;
    /**
     * The quantity of the subscription item being paid for by this upcoming invoice.
     *
     * @generated from protobuf field: int32 quantity = 5;
     */
    quantity: number;
    /**
     * The unit price of the subscription item being paid for by this upcoming invoice.
     *
     * @generated from protobuf field: int32 unit_amount = 6;
     */
    unitAmount: number;
}
/**
 * GetInvoicesRequest is the request for the GetInvoices method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetInvoicesRequest
 */
export interface GetInvoicesRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * Invoice represents an invoice.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.Invoice
 */
export interface Invoice {
    /**
     * Amount due of the upcoming invoice.
     *
     * @generated from protobuf field: int32 amount_due = 1;
     */
    amountDue: number;
    /**
     * Currency of the upcoming invoice.
     *
     * @generated from protobuf field: string currency = 2;
     */
    currency: string;
    /**
     * The start of the period of the upcoming invoice.
     *
     * @generated from protobuf field: int32 period_start = 3;
     */
    periodStart: number;
    /**
     * The end of the period of the upcoming invoice.
     *
     * @generated from protobuf field: int32 period_end = 4;
     */
    periodEnd: number;
    /**
     * The quantity of the subscription item being paid for by this upcoming invoice.
     *
     * @generated from protobuf field: int32 quantity = 5;
     */
    quantity: number;
    /**
     * The unit price of the subscription item being paid for by this upcoming invoice.
     *
     * @generated from protobuf field: int32 unit_amount = 6;
     */
    unitAmount: number;
    /**
     * Number of the invoice.
     *
     * @generated from protobuf field: string number = 7;
     */
    number: string;
    /**
     * Status of the invoice.
     *
     * @generated from protobuf field: string status = 8;
     */
    status: string;
    /**
     * PDF of the invoice.
     *
     * @generated from protobuf field: string invoice_pdf = 9;
     */
    invoicePdf: string;
}
/**
 * GetInvoicesResponse is the response for the GetInvoices method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetInvoicesResponse
 */
export interface GetInvoicesResponse {
    /**
     * Invoices.
     *
     * @generated from protobuf field: repeated polarsignals.billing.v1alpha1.Invoice invoices = 1;
     */
    invoices: Invoice[];
}
/**
 * CancelSubscriptionRequest is the request for the CancelSubscription method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.CancelSubscriptionRequest
 */
export interface CancelSubscriptionRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * CancelSubscriptionResponse is the response for the CancelSubscription method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.CancelSubscriptionResponse
 */
export interface CancelSubscriptionResponse {
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetCustomerRequest
 */
export interface GetCustomerRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetCustomerResponse
 */
export interface GetCustomerResponse {
    /**
     * Customer name.
     *
     * @generated from protobuf field: string customer_name = 1;
     */
    customerName: string;
    /**
     * Customer email.
     *
     * @generated from protobuf field: string customer_email = 2;
     */
    customerEmail: string;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
    /**
     * Customer name.
     *
     * @generated from protobuf field: string customer_name = 2;
     */
    customerName: string;
    /**
     * Customer email.
     *
     * @generated from protobuf field: string customer_email = 3;
     */
    customerEmail: string;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.UpdateCustomerResponse
 */
export interface UpdateCustomerResponse {
}
/**
 * GetCurrentUsageRequest is the request for the GetCurrentUsage method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetCurrentUsageRequest
 */
export interface GetCurrentUsageRequest {
    /**
     * Organization ID.
     *
     * @generated from protobuf field: string organization_id = 1;
     */
    organizationId: string;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.ProjectUsage
 */
export interface ProjectUsage {
    /**
     * Project ID.
     *
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * Project name.
     *
     * @generated from protobuf field: string project_name = 2;
     */
    projectName: string;
    /**
     * Project usage.
     *
     * @generated from protobuf field: int64 bytes = 3;
     */
    bytes: bigint;
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.MetricsItem
 */
export interface MetricsItem {
    /**
     * timestamp of the metrics.
     *
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: repeated polarsignals.billing.v1alpha1.ProjectUsage project_usage = 2;
     */
    projectUsage: ProjectUsage[];
}
/**
 * @generated from protobuf message polarsignals.billing.v1alpha1.CurrentUsage
 */
export interface CurrentUsage {
    /**
     * total usage.
     *
     * @generated from protobuf field: int64 total_usage = 1;
     */
    totalUsage: bigint;
    /**
     * usage metrics.
     *
     * @generated from protobuf field: repeated polarsignals.billing.v1alpha1.MetricsItem metrics = 2;
     */
    metrics: MetricsItem[];
}
/**
 * GetCurrentUsageResponse is the response for the GetCurrentUsage method.
 *
 * @generated from protobuf message polarsignals.billing.v1alpha1.GetCurrentUsageResponse
 */
export interface GetCurrentUsageResponse {
    /**
     * Current usage.
     *
     * @generated from protobuf field: polarsignals.billing.v1alpha1.CurrentUsage current_usage = 1;
     */
    currentUsage?: CurrentUsage;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetConfigRequest$Type extends MessageType<GetConfigRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetConfigRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetConfigRequest>): GetConfigRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConfigRequest): GetConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetConfigRequest
 */
export const GetConfigRequest = new GetConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConfigResponse$Type extends MessageType<GetConfigResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetConfigResponse", [
            { no: 1, name: "publishable_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetConfigResponse>): GetConfigResponse {
        const message = { publishableKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetConfigResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConfigResponse): GetConfigResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string publishable_key */ 1:
                    message.publishableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConfigResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string publishable_key = 1; */
        if (message.publishableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publishableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetConfigResponse
 */
export const GetConfigResponse = new GetConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Card$Type extends MessageType<Card> {
    constructor() {
        super("polarsignals.billing.v1alpha1.Card", [
            { no: 1, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "last4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "exp_month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "exp_year", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Card>): Card {
        const message = { brand: "", last4: "", expMonth: 0, expYear: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Card>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Card): Card {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string brand */ 1:
                    message.brand = reader.string();
                    break;
                case /* string last4 */ 2:
                    message.last4 = reader.string();
                    break;
                case /* int32 exp_month */ 3:
                    message.expMonth = reader.int32();
                    break;
                case /* int32 exp_year */ 4:
                    message.expYear = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Card, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string brand = 1; */
        if (message.brand !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.brand);
        /* string last4 = 2; */
        if (message.last4 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.last4);
        /* int32 exp_month = 3; */
        if (message.expMonth !== 0)
            writer.tag(3, WireType.Varint).int32(message.expMonth);
        /* int32 exp_year = 4; */
        if (message.expYear !== 0)
            writer.tag(4, WireType.Varint).int32(message.expYear);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.Card
 */
export const Card = new Card$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PoBilling$Type extends MessageType<PoBilling> {
    constructor() {
        super("polarsignals.billing.v1alpha1.PoBilling", []);
    }
    create(value?: PartialMessage<PoBilling>): PoBilling {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PoBilling>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PoBilling): PoBilling {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PoBilling, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.PoBilling
 */
export const PoBilling = new PoBilling$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentMethod$Type extends MessageType<PaymentMethod> {
    constructor() {
        super("polarsignals.billing.v1alpha1.PaymentMethod", [
            { no: 1, name: "card", kind: "message", T: () => Card }
        ]);
    }
    create(value?: PartialMessage<PaymentMethod>): PaymentMethod {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PaymentMethod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentMethod): PaymentMethod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.billing.v1alpha1.Card card */ 1:
                    message.card = Card.internalBinaryRead(reader, reader.uint32(), options, message.card);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentMethod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.billing.v1alpha1.Card card = 1; */
        if (message.card)
            Card.internalBinaryWrite(message.card, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.PaymentMethod
 */
export const PaymentMethod = new PaymentMethod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSubscriptionRequest$Type extends MessageType<GetSubscriptionRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetSubscriptionRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSubscriptionRequest>): GetSubscriptionRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSubscriptionRequest): GetSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetSubscriptionRequest
 */
export const GetSubscriptionRequest = new GetSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSubscriptionResponse$Type extends MessageType<GetSubscriptionResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetSubscriptionResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created", kind: "message", T: () => Timestamp },
            { no: 4, name: "card", kind: "message", oneof: "paymentMethod", T: () => Card },
            { no: 5, name: "po_billing", kind: "message", oneof: "paymentMethod", T: () => PoBilling }
        ]);
    }
    create(value?: PartialMessage<GetSubscriptionResponse>): GetSubscriptionResponse {
        const message = { id: "", status: "", paymentMethod: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSubscriptionResponse): GetSubscriptionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string status */ 2:
                    message.status = reader.string();
                    break;
                case /* google.protobuf.Timestamp created */ 3:
                    message.created = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* polarsignals.billing.v1alpha1.Card card */ 4:
                    message.paymentMethod = {
                        oneofKind: "card",
                        card: Card.internalBinaryRead(reader, reader.uint32(), options, (message.paymentMethod as any).card)
                    };
                    break;
                case /* polarsignals.billing.v1alpha1.PoBilling po_billing */ 5:
                    message.paymentMethod = {
                        oneofKind: "poBilling",
                        poBilling: PoBilling.internalBinaryRead(reader, reader.uint32(), options, (message.paymentMethod as any).poBilling)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string status = 2; */
        if (message.status !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.status);
        /* google.protobuf.Timestamp created = 3; */
        if (message.created)
            Timestamp.internalBinaryWrite(message.created, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* polarsignals.billing.v1alpha1.Card card = 4; */
        if (message.paymentMethod.oneofKind === "card")
            Card.internalBinaryWrite(message.paymentMethod.card, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* polarsignals.billing.v1alpha1.PoBilling po_billing = 5; */
        if (message.paymentMethod.oneofKind === "poBilling")
            PoBilling.internalBinaryWrite(message.paymentMethod.poBilling, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetSubscriptionResponse
 */
export const GetSubscriptionResponse = new GetSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSessionRequest$Type extends MessageType<CreateSessionRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.CreateSessionRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "price_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "redirect_to", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateSessionRequest>): CreateSessionRequest {
        const message = { organizationId: "", priceId: "", redirectTo: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSessionRequest): CreateSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                case /* string price_id */ 2:
                    message.priceId = reader.string();
                    break;
                case /* string redirect_to */ 3:
                    message.redirectTo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        /* string price_id = 2; */
        if (message.priceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.priceId);
        /* string redirect_to = 3; */
        if (message.redirectTo !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.redirectTo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.CreateSessionRequest
 */
export const CreateSessionRequest = new CreateSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSessionResponse$Type extends MessageType<CreateSessionResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.CreateSessionResponse", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateSessionResponse>): CreateSessionResponse {
        const message = { sessionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSessionResponse): CreateSessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.CreateSessionResponse
 */
export const CreateSessionResponse = new CreateSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvoicesUpcomingRequest$Type extends MessageType<GetInvoicesUpcomingRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetInvoicesUpcomingRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInvoicesUpcomingRequest>): GetInvoicesUpcomingRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInvoicesUpcomingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvoicesUpcomingRequest): GetInvoicesUpcomingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvoicesUpcomingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetInvoicesUpcomingRequest
 */
export const GetInvoicesUpcomingRequest = new GetInvoicesUpcomingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvoicesUpcomingResponse$Type extends MessageType<GetInvoicesUpcomingResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetInvoicesUpcomingResponse", [
            { no: 1, name: "amount_due", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "period_start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "period_end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "unit_amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetInvoicesUpcomingResponse>): GetInvoicesUpcomingResponse {
        const message = { amountDue: 0, currency: "", periodStart: 0, periodEnd: 0, quantity: 0, unitAmount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInvoicesUpcomingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvoicesUpcomingResponse): GetInvoicesUpcomingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 amount_due */ 1:
                    message.amountDue = reader.int32();
                    break;
                case /* string currency */ 2:
                    message.currency = reader.string();
                    break;
                case /* int32 period_start */ 3:
                    message.periodStart = reader.int32();
                    break;
                case /* int32 period_end */ 4:
                    message.periodEnd = reader.int32();
                    break;
                case /* int32 quantity */ 5:
                    message.quantity = reader.int32();
                    break;
                case /* int32 unit_amount */ 6:
                    message.unitAmount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvoicesUpcomingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 amount_due = 1; */
        if (message.amountDue !== 0)
            writer.tag(1, WireType.Varint).int32(message.amountDue);
        /* string currency = 2; */
        if (message.currency !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.currency);
        /* int32 period_start = 3; */
        if (message.periodStart !== 0)
            writer.tag(3, WireType.Varint).int32(message.periodStart);
        /* int32 period_end = 4; */
        if (message.periodEnd !== 0)
            writer.tag(4, WireType.Varint).int32(message.periodEnd);
        /* int32 quantity = 5; */
        if (message.quantity !== 0)
            writer.tag(5, WireType.Varint).int32(message.quantity);
        /* int32 unit_amount = 6; */
        if (message.unitAmount !== 0)
            writer.tag(6, WireType.Varint).int32(message.unitAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetInvoicesUpcomingResponse
 */
export const GetInvoicesUpcomingResponse = new GetInvoicesUpcomingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvoicesRequest$Type extends MessageType<GetInvoicesRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetInvoicesRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInvoicesRequest>): GetInvoicesRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInvoicesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvoicesRequest): GetInvoicesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvoicesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetInvoicesRequest
 */
export const GetInvoicesRequest = new GetInvoicesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Invoice$Type extends MessageType<Invoice> {
    constructor() {
        super("polarsignals.billing.v1alpha1.Invoice", [
            { no: 1, name: "amount_due", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "period_start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "period_end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "unit_amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "invoice_pdf", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Invoice>): Invoice {
        const message = { amountDue: 0, currency: "", periodStart: 0, periodEnd: 0, quantity: 0, unitAmount: 0, number: "", status: "", invoicePdf: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Invoice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Invoice): Invoice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 amount_due */ 1:
                    message.amountDue = reader.int32();
                    break;
                case /* string currency */ 2:
                    message.currency = reader.string();
                    break;
                case /* int32 period_start */ 3:
                    message.periodStart = reader.int32();
                    break;
                case /* int32 period_end */ 4:
                    message.periodEnd = reader.int32();
                    break;
                case /* int32 quantity */ 5:
                    message.quantity = reader.int32();
                    break;
                case /* int32 unit_amount */ 6:
                    message.unitAmount = reader.int32();
                    break;
                case /* string number */ 7:
                    message.number = reader.string();
                    break;
                case /* string status */ 8:
                    message.status = reader.string();
                    break;
                case /* string invoice_pdf */ 9:
                    message.invoicePdf = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Invoice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 amount_due = 1; */
        if (message.amountDue !== 0)
            writer.tag(1, WireType.Varint).int32(message.amountDue);
        /* string currency = 2; */
        if (message.currency !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.currency);
        /* int32 period_start = 3; */
        if (message.periodStart !== 0)
            writer.tag(3, WireType.Varint).int32(message.periodStart);
        /* int32 period_end = 4; */
        if (message.periodEnd !== 0)
            writer.tag(4, WireType.Varint).int32(message.periodEnd);
        /* int32 quantity = 5; */
        if (message.quantity !== 0)
            writer.tag(5, WireType.Varint).int32(message.quantity);
        /* int32 unit_amount = 6; */
        if (message.unitAmount !== 0)
            writer.tag(6, WireType.Varint).int32(message.unitAmount);
        /* string number = 7; */
        if (message.number !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.number);
        /* string status = 8; */
        if (message.status !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.status);
        /* string invoice_pdf = 9; */
        if (message.invoicePdf !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.invoicePdf);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.Invoice
 */
export const Invoice = new Invoice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvoicesResponse$Type extends MessageType<GetInvoicesResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetInvoicesResponse", [
            { no: 1, name: "invoices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Invoice }
        ]);
    }
    create(value?: PartialMessage<GetInvoicesResponse>): GetInvoicesResponse {
        const message = { invoices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInvoicesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvoicesResponse): GetInvoicesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.billing.v1alpha1.Invoice invoices */ 1:
                    message.invoices.push(Invoice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvoicesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.billing.v1alpha1.Invoice invoices = 1; */
        for (let i = 0; i < message.invoices.length; i++)
            Invoice.internalBinaryWrite(message.invoices[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetInvoicesResponse
 */
export const GetInvoicesResponse = new GetInvoicesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelSubscriptionRequest$Type extends MessageType<CancelSubscriptionRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.CancelSubscriptionRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelSubscriptionRequest>): CancelSubscriptionRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CancelSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelSubscriptionRequest): CancelSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.CancelSubscriptionRequest
 */
export const CancelSubscriptionRequest = new CancelSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelSubscriptionResponse$Type extends MessageType<CancelSubscriptionResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.CancelSubscriptionResponse", []);
    }
    create(value?: PartialMessage<CancelSubscriptionResponse>): CancelSubscriptionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CancelSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelSubscriptionResponse): CancelSubscriptionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CancelSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.CancelSubscriptionResponse
 */
export const CancelSubscriptionResponse = new CancelSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerRequest$Type extends MessageType<GetCustomerRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetCustomerRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomerRequest>): GetCustomerRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerRequest): GetCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetCustomerRequest
 */
export const GetCustomerRequest = new GetCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerResponse$Type extends MessageType<GetCustomerResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetCustomerResponse", [
            { no: 1, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomerResponse>): GetCustomerResponse {
        const message = { customerName: "", customerEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomerResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerResponse): GetCustomerResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_name */ 1:
                    message.customerName = reader.string();
                    break;
                case /* string customer_email */ 2:
                    message.customerEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_name = 1; */
        if (message.customerName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerName);
        /* string customer_email = 2; */
        if (message.customerEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetCustomerResponse
 */
export const GetCustomerResponse = new GetCustomerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomerRequest$Type extends MessageType<UpdateCustomerRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.UpdateCustomerRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCustomerRequest>): UpdateCustomerRequest {
        const message = { organizationId: "", customerName: "", customerEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomerRequest): UpdateCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                case /* string customer_name */ 2:
                    message.customerName = reader.string();
                    break;
                case /* string customer_email */ 3:
                    message.customerEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        /* string customer_name = 2; */
        if (message.customerName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerName);
        /* string customer_email = 3; */
        if (message.customerEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.UpdateCustomerRequest
 */
export const UpdateCustomerRequest = new UpdateCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomerResponse$Type extends MessageType<UpdateCustomerResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.UpdateCustomerResponse", []);
    }
    create(value?: PartialMessage<UpdateCustomerResponse>): UpdateCustomerResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomerResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomerResponse): UpdateCustomerResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateCustomerResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.UpdateCustomerResponse
 */
export const UpdateCustomerResponse = new UpdateCustomerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUsageRequest$Type extends MessageType<GetCurrentUsageRequest> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetCurrentUsageRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCurrentUsageRequest>): GetCurrentUsageRequest {
        const message = { organizationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCurrentUsageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCurrentUsageRequest): GetCurrentUsageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCurrentUsageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetCurrentUsageRequest
 */
export const GetCurrentUsageRequest = new GetCurrentUsageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectUsage$Type extends MessageType<ProjectUsage> {
    constructor() {
        super("polarsignals.billing.v1alpha1.ProjectUsage", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bytes", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<ProjectUsage>): ProjectUsage {
        const message = { projectId: "", projectName: "", bytes: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProjectUsage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProjectUsage): ProjectUsage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string project_name */ 2:
                    message.projectName = reader.string();
                    break;
                case /* int64 bytes */ 3:
                    message.bytes = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProjectUsage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string project_name = 2; */
        if (message.projectName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectName);
        /* int64 bytes = 3; */
        if (message.bytes !== 0n)
            writer.tag(3, WireType.Varint).int64(message.bytes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.ProjectUsage
 */
export const ProjectUsage = new ProjectUsage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricsItem$Type extends MessageType<MetricsItem> {
    constructor() {
        super("polarsignals.billing.v1alpha1.MetricsItem", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "project_usage", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectUsage }
        ]);
    }
    create(value?: PartialMessage<MetricsItem>): MetricsItem {
        const message = { projectUsage: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MetricsItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MetricsItem): MetricsItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* repeated polarsignals.billing.v1alpha1.ProjectUsage project_usage */ 2:
                    message.projectUsage.push(ProjectUsage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MetricsItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated polarsignals.billing.v1alpha1.ProjectUsage project_usage = 2; */
        for (let i = 0; i < message.projectUsage.length; i++)
            ProjectUsage.internalBinaryWrite(message.projectUsage[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.MetricsItem
 */
export const MetricsItem = new MetricsItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurrentUsage$Type extends MessageType<CurrentUsage> {
    constructor() {
        super("polarsignals.billing.v1alpha1.CurrentUsage", [
            { no: 1, name: "total_usage", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "metrics", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MetricsItem }
        ]);
    }
    create(value?: PartialMessage<CurrentUsage>): CurrentUsage {
        const message = { totalUsage: 0n, metrics: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CurrentUsage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CurrentUsage): CurrentUsage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total_usage */ 1:
                    message.totalUsage = reader.int64().toBigInt();
                    break;
                case /* repeated polarsignals.billing.v1alpha1.MetricsItem metrics */ 2:
                    message.metrics.push(MetricsItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CurrentUsage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total_usage = 1; */
        if (message.totalUsage !== 0n)
            writer.tag(1, WireType.Varint).int64(message.totalUsage);
        /* repeated polarsignals.billing.v1alpha1.MetricsItem metrics = 2; */
        for (let i = 0; i < message.metrics.length; i++)
            MetricsItem.internalBinaryWrite(message.metrics[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.CurrentUsage
 */
export const CurrentUsage = new CurrentUsage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUsageResponse$Type extends MessageType<GetCurrentUsageResponse> {
    constructor() {
        super("polarsignals.billing.v1alpha1.GetCurrentUsageResponse", [
            { no: 1, name: "current_usage", kind: "message", T: () => CurrentUsage }
        ]);
    }
    create(value?: PartialMessage<GetCurrentUsageResponse>): GetCurrentUsageResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCurrentUsageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCurrentUsageResponse): GetCurrentUsageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.billing.v1alpha1.CurrentUsage current_usage */ 1:
                    message.currentUsage = CurrentUsage.internalBinaryRead(reader, reader.uint32(), options, message.currentUsage);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCurrentUsageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.billing.v1alpha1.CurrentUsage current_usage = 1; */
        if (message.currentUsage)
            CurrentUsage.internalBinaryWrite(message.currentUsage, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.billing.v1alpha1.GetCurrentUsageResponse
 */
export const GetCurrentUsageResponse = new GetCurrentUsageResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.billing.v1alpha1.BillingService
 */
export const BillingService = new ServiceType("polarsignals.billing.v1alpha1.BillingService", [
    { name: "GetConfig", options: { "google.api.http": { get: "/v1/config" } }, I: GetConfigRequest, O: GetConfigResponse },
    { name: "GetSubscription", options: { "google.api.http": { get: "/v1/subscriptions/{organization_id}" } }, I: GetSubscriptionRequest, O: GetSubscriptionResponse },
    { name: "CreateSession", options: { "google.api.http": { post: "/v1/sessions", body: "*" } }, I: CreateSessionRequest, O: CreateSessionResponse },
    { name: "GetInvoicesUpcoming", options: { "google.api.http": { get: "/v1/invoices/upcoming/{organization_id}" } }, I: GetInvoicesUpcomingRequest, O: GetInvoicesUpcomingResponse },
    { name: "GetInvoices", options: { "google.api.http": { get: "/v1/invoices/{organization_id}" } }, I: GetInvoicesRequest, O: GetInvoicesResponse },
    { name: "CancelSubscription", options: { "google.api.http": { delete: "/v1/subscriptions/{organization_id}" } }, I: CancelSubscriptionRequest, O: CancelSubscriptionResponse },
    { name: "GetCustomer", options: { "google.api.http": { get: "/v1/customers/{organization_id}" } }, I: GetCustomerRequest, O: GetCustomerResponse },
    { name: "UpdateCustomer", options: { "google.api.http": { patch: "/v1/customers/{organization_id}", body: "*" } }, I: UpdateCustomerRequest, O: UpdateCustomerResponse },
    { name: "GetCurrentUsage", options: { "google.api.http": { get: "/v1/usage/current/{organization_id}" } }, I: GetCurrentUsageRequest, O: GetCurrentUsageResponse }
]);
