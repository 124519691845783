import Modal from 'components/Modal';

import AddOrganization from '../AddOrganization';

const AddOrganizationModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      closeModal={() => closeModal()}
      title="Add a New Organization"
      className="w-[480px]"
    >
      <AddOrganization closeModal={closeModal} />
    </Modal>
  );
};

export default AddOrganizationModal;
