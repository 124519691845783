// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "glacier/query/v1alpha1/query.proto" (package "glacier.query.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { QueryService } from "./query";
import type { SeriesResponse } from "./query";
import type { SeriesRequest } from "./query";
import type { ValuesResponse } from "./query";
import type { ValuesRequest } from "./query";
import type { LabelsResponse } from "./query";
import type { LabelsRequest } from "./query";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { QueryRangeResponse } from "./query";
import type { QueryRangeRequest } from "./query";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * QueryService is the service that provides APIs to retrieve and inspect logs
 *
 * @generated from protobuf service glacier.query.v1alpha1.QueryService
 */
export interface IQueryServiceClient {
    /**
     * QueryRange performs a logs query over a time range
     *
     * @generated from protobuf rpc: QueryRange(glacier.query.v1alpha1.QueryRangeRequest) returns (glacier.query.v1alpha1.QueryRangeResponse);
     */
    queryRange(input: QueryRangeRequest, options?: RpcOptions): UnaryCall<QueryRangeRequest, QueryRangeResponse>;
    /**
     * Labels returns the set of label names against a given matching string and time frame
     *
     * @generated from protobuf rpc: Labels(glacier.query.v1alpha1.LabelsRequest) returns (glacier.query.v1alpha1.LabelsResponse);
     */
    labels(input: LabelsRequest, options?: RpcOptions): UnaryCall<LabelsRequest, LabelsResponse>;
    /**
     * Values returns the set of values that match a given label and time frame
     *
     * @generated from protobuf rpc: Values(glacier.query.v1alpha1.ValuesRequest) returns (glacier.query.v1alpha1.ValuesResponse);
     */
    values(input: ValuesRequest, options?: RpcOptions): UnaryCall<ValuesRequest, ValuesResponse>;
    /**
     * @generated from protobuf rpc: Series(glacier.query.v1alpha1.SeriesRequest) returns (glacier.query.v1alpha1.SeriesResponse);
     */
    series(input: SeriesRequest, options?: RpcOptions): UnaryCall<SeriesRequest, SeriesResponse>;
}
/**
 * QueryService is the service that provides APIs to retrieve and inspect logs
 *
 * @generated from protobuf service glacier.query.v1alpha1.QueryService
 */
export class QueryServiceClient implements IQueryServiceClient, ServiceInfo {
    typeName = QueryService.typeName;
    methods = QueryService.methods;
    options = QueryService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * QueryRange performs a logs query over a time range
     *
     * @generated from protobuf rpc: QueryRange(glacier.query.v1alpha1.QueryRangeRequest) returns (glacier.query.v1alpha1.QueryRangeResponse);
     */
    queryRange(input: QueryRangeRequest, options?: RpcOptions): UnaryCall<QueryRangeRequest, QueryRangeResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<QueryRangeRequest, QueryRangeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Labels returns the set of label names against a given matching string and time frame
     *
     * @generated from protobuf rpc: Labels(glacier.query.v1alpha1.LabelsRequest) returns (glacier.query.v1alpha1.LabelsResponse);
     */
    labels(input: LabelsRequest, options?: RpcOptions): UnaryCall<LabelsRequest, LabelsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<LabelsRequest, LabelsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Values returns the set of values that match a given label and time frame
     *
     * @generated from protobuf rpc: Values(glacier.query.v1alpha1.ValuesRequest) returns (glacier.query.v1alpha1.ValuesResponse);
     */
    values(input: ValuesRequest, options?: RpcOptions): UnaryCall<ValuesRequest, ValuesResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValuesRequest, ValuesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Series(glacier.query.v1alpha1.SeriesRequest) returns (glacier.query.v1alpha1.SeriesResponse);
     */
    series(input: SeriesRequest, options?: RpcOptions): UnaryCall<SeriesRequest, SeriesResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SeriesRequest, SeriesResponse>("unary", this._transport, method, opt, input);
    }
}
