import {Flex, Heading} from 'theme-ui';
interface Props {
  children: React.ReactNode;
  sx?: {[key: string]: string | number};
  title?: string | React.ReactNode;
  variant: string;
}
const Section = ({children, variant, sx, title = ''}: Props) => {
  const customProps = sx ?? {};
  return (
    <Flex
      padding={3}
      variant={variant}
      sx={{
        flexDirection: 'column',
        position: 'relative',
        ...customProps,
      }}
    >
      {title ? (
        <Heading as="h3" marginBottom={3}>
          {title}
        </Heading>
      ) : null}
      {children}
    </Flex>
  );
};

export default Section;
