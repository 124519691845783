import {useEffect, useMemo, useState} from 'react';

import Image from 'next/image';
import Joyride, {STATUS} from 'react-joyride';
import type {CallBackProps, Step} from 'react-joyride';
import {useSetState} from 'react-use';
import {useColorMode} from 'theme-ui';

import ProductTourBanner from './Banner';
import strings from './string.json';

import {Tooltip} from './';

interface Props {
  triggeredFromHelpCenter?: boolean;
  updateTriggeredFromHelpCenter?: (triggeredFromHelpCenter: boolean) => void;
  hasUserSentData: boolean;
  productTour?: string[];
  updateUserProductTour: (tourName: string) => void;
  userIsOnComparingProfilesPage: boolean;
  fromNavbar?: boolean;
}

interface State {
  run: boolean;
  steps: Step[];
}

const InitialTour = ({
  productTour,
  updateUserProductTour,
  userIsOnComparingProfilesPage,
  hasUserSentData,
  triggeredFromHelpCenter,
  updateTriggeredFromHelpCenter,
  fromNavbar = false,
}: Props) => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const showBanner = useMemo(() => {
    return (
      hasUserSentData &&
      !userIsOnComparingProfilesPage &&
      !productTour?.includes('initial_tour') &&
      !fromNavbar
    );
  }, [userIsOnComparingProfilesPage, productTour, hasUserSentData, fromNavbar]);

  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        setIsBannerOpen(true);
      }, 3000);
    } else {
      setIsBannerOpen(false);
    }
  }, [showBanner, isBannerOpen]);

  useEffect(() => {
    if (triggeredFromHelpCenter) {
      handleClickStart();
    }
  }, [triggeredFromHelpCenter]);

  const [{run, steps}, setState] = useSetState<State>({
    run: false,
    steps: [
      {
        content: (
          <div className="flex flex-col items-center justify-center gap-4 px-10 py-12">
            <h2 className="text-brand-dark-blue text-3xl font-semibold dark:text-gray-50">
              {strings['initialTour.firstStep.headerTitle']}
            </h2>

            <div className="text-center">
              <p className="font-medium text-gray-800 dark:text-gray-300">
                {strings['initialTour.firstStep.headerSubtitle']}
              </p>
              <p className="text-sm italic text-gray-500 dark:text-gray-100">
                {strings['intitialTour.firstStep.footerText']}
              </p>
            </div>
          </div>
        ),
        placement: 'center',
        target: 'body',
      },
      {
        content: (
          <div className="max-w-[432px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['initialTour.secondStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['initialTour.secondStep.body']}
            </p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of the profile type dropdown"
                  src="/product-tour/dark-profile-type.png"
                  width={245}
                  height={207}
                />
              ) : (
                <Image
                  alt="A screenshot of the profile type dropdown"
                  src="/product-tour/profile-type.png"
                  width={245}
                  height={207}
                />
              )}
            </div>
          </div>
        ),
        spotlightPadding: 10,
        target: '.h-profile-type-dropdown',
      },
      {
        content: (
          <div className="max-w-[432px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['initialTour.thirdStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['initialTour.thirdStep.body']}
            </p>
          </div>
        ),
        placement: 'bottom',
        spotlightPadding: 10,
        target: '#matchers-input',
      },
      {
        content: (
          <div className="max-w-[481px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['initialTour.fourthStep.headerTitle']}
            </p>
            <p
              dangerouslySetInnerHTML={{__html: strings['initialTour.fourthStep.body']}}
              className="mt-2 text-sm text-gray-700 dark:text-gray-100"
            ></p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of how to another visualization"
                  src="/product-tour/dark-multiple-viz.png"
                  width={124}
                  height={187}
                />
              ) : (
                <Image
                  alt="A screenshot of how to another visualization"
                  src="/product-tour/multiple-viz.png"
                  width={124}
                  height={187}
                />
              )}
            </div>
          </div>
        ),
        placement: 'bottom',
        target: '#h-switch-viz',
      },
      {
        content: (
          <div className="max-w-[449px]">
            <p className="font-semibold text-gray-800 dark:text-gray-300">
              {strings['initialTour.fifthStep.headerTitle']}
            </p>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-100">
              {strings['initialTour.fifthStep.body']}
            </p>
            <div className="mt-3">
              {isDarkMode ? (
                <Image
                  alt="A screenshot of the preferences modal"
                  src="/product-tour/dark-preferences.png"
                  width={224}
                  height={256}
                />
              ) : (
                <Image
                  alt="A screenshot of the preferences modal"
                  src="/product-tour/preferences.png"
                  width={224}
                  height={256}
                />
              )}
            </div>
          </div>
        ),
        placement: 'left',
        target: '#h-viz-preferences',
      },
      {
        content: (
          <div className="flex flex-col items-center justify-center gap-4 px-10 py-12">
            <h2 className="text-brand-dark-blue text-3xl font-semibold dark:text-gray-300">
              {strings['initialTour.sixthStep.headerTitle']}
            </h2>

            <div className="text-center">
              <p className="text-gray-800 dark:text-gray-100">
                {strings['initialTour.sixthStep.body']}
              </p>
            </div>
          </div>
        ),
        placement: 'center',
        target: 'body',
      },
    ],
  });

  const handleClickStart = () => {
    setState({
      run: true,
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({run: false});

      if (triggeredFromHelpCenter) {
        updateTriggeredFromHelpCenter?.(false);
        return;
      }

      closeBanner();
    }
  };

  const closeBanner = () => {
    setIsBannerOpen(false);
    updateUserProductTour('initial_tour');
  };

  return (
    <>
      {isBannerOpen && (
        <ProductTourBanner
          closeBanner={() => closeBanner()}
          initiateTour={() => handleClickStart()}
          text="🎉 Congratulations on sending data to Polar Signals! Explore our product tour to discover
          all the features we offer."
        />
      )}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: isDarkMode ? '#1F2937' : '#fff',
          },
          beaconInner: {
            background: '#4C68F6',
          },
          beaconOuter: {
            background: 'rgb(76, 104, 246, 0.2)',
            border: '2px solid #4C68F6',
          },
        }}
        tooltipComponent={Tooltip}
      />
    </>
  );
};

export default InitialTour;
